#block-lermontov-hotel-booking-form-block {
  width: 100%;
  max-width: 100%;
  background: rgba(#068e60, 0.75);
  display: flex;
  justify-content: center;
  padding: 30px 0;

  body.front & {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;

    @include mq(1220px, max) {
      position: static;
    }
  }

  .form-additional-text {
    margin-right: 1em;

    @include mq(1220px, max) {
      margin-right: 0;
      flex: 1 1 100%;
      margin-bottom: 1.5em;
    }
  }

  .text-main {
    font-style: $open-sans;
    font-weight: 600;
    color: $white;
    font-size: 26px;
    margin: 0 0 10px;

    @include mq(1220px, max) {
      text-align: center;
    }
  }

  .text-bottom {
    font-style: $open-sans;
    font-weight: 500;
    color: $white;
    font-size: 12px;
    display: block;

    @include mq(1220px, max) {
      text-align: center;
    }
  }

  form {
    > div {
      display: flex;
      align-items: flex-end;

      .form-item {
        display: flex;
        flex-flow: row wrap;

        > label {
          font-family: $open-sans;
          font-size: 15px;
          line-height: 23px;
          color: $white;
          flex: 1 1 100%;

          @include mq(1220px, max) {
            text-align: center;
          }
        }

        @include mq(1220px, max) {
          flex: 1 1 100%;
          margin-bottom: 1em;
          justify-content: center;
        }
      }

      .form-text {
        flex: 0 1 274px;
        width: 274px;
        height: 48px;
        padding-left: 30px;
      }

      .form-submit {
        height: 60px;
        width: 172px;
        font-size: $open-sans;
        font-weight: 600;
        background: #ffcc00;
        color: #252525;
        box-shadow: none;
        border: 0;

        &:active {
          background: #515151;
          color: $white;
        }

        @include mq(1220px, max) {
          flex: 0 1 auto;
        }
      }

      @include mq(1220px, max) {
        display: flex;
        justify-content: center;
        flex-flow: row wrap;
      }
    }

    @include mq(1220px, max) {
      flex: 1 1 100%;
    }
  }

  @include mq(1220px, max) {
    flex-flow: row wrap;
  }
}

