.section-booking-check {
  .block-lermontov-hotel {
    max-width: 100%;
    width: 100%;

    .view-content {

    }
  }

  .booking-heading-text h1 {
    text-align: center;
    text-transform: uppercase;
    font-family: $plumb;
    font-size: 50px;
    font-weight: 400;
  }
}

.view-display-id-shelter_booking_check {
  @include wrapper(1800px);

  .view-content {
    background: #fafafa;
    padding: 20px 20px 45px;
  }

  .views-row {
    display: flex;
    margin-bottom: 21px;
    background: #fff;
    padding: 35px 72px 40px 42px;

    &:last-child {
      margin-bottom: 58px;
    }

    li {
      list-style: none;
    }

    ul {
      padding: 0;
      margin: 0;
    }

    @include mq(1190px, max) {
      flex-flow: row wrap;
      justify-content: center;
      padding: 35px;
    }

    @include mq(640px, max) {
      padding: 15px;
    }
  }

  .views-field-field-photo {
    flex: 1 1 390px;

    .field-content {
      width: 390px;
    }

    .owl-carousel {
      position: relative;
    }

    .owl-nav {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: flex-end;

      .owl-prev, .owl-next {
        float: left;
        position: relative;
        display: block;
        width: 45px;
        height: 45px;
        background-color: $white;
        font-size: 0;

        &:hover, &:active {
          background-color: $green;

          &::before {
            color: $white;
          }
        }

        &::before {
          @include absolute-center;
          content: '';
          font-family: $fa;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          color: $middle-gray;
        }
      }

      .owl-next::before {
        content: '';
      }
    }

    @include mq(1190px, max) {
      flex: 0 1 390px;
      margin-bottom: 25px;
    }

    @include mq(640px, max) {
      flex: 1 1 260px;

      .field-content {
        width: 260px;
      }
    }

    @include mq(360px, max) {
      flex: 1 1 220px;

      .field-content {
        width: 220px;
      }
    }
  }

  .views-field-body {
    flex: 1 1 calc(100% - 390px);
    padding-left: 60px;

    .field-content {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      height: 100%;

      .book {
        float: none
      }
    }

    @include mq(1190px, max) {
      flex: 1 1 100%;
      padding: 0;
    }

    @include mq(820px, max) {
      .field-content {
        flex-flow: row wrap;
      }
    }
  }

  .mid-col {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding-right: 15px;
    .title {
      flex: 1 1 100%;
      font-family: $plumb;
      font-weight: 400;
      font-size: 32px;
      color: #515151;
      text-transform: uppercase;
    }
    .body {
      flex: 1 1 100%;
      font-family: $open-sans;
      font-size: 16px;
      font-weight: 400;
      color: #000;
    }

    .options {
      ul {
        display: flex;
        flex-flow: row wrap;
      }

      h2 {
        display: none;
      }
    }

    @include mq(820px, max) {
      flex: 1 1 100%;
      margin-bottom: 20px;
      padding-right: 0;
    }

    @include mq(640px, max) {
      .title {
        margin-bottom: 20px;
      }

      .body {
        margin-bottom: 15px;
      }
    }
  }

  .end-col {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;

    .custom-prefix-wrapper {
      color: #515151;
      font-family: $open-sans;
      font-size: 31px;
    }

    .custom-value-wrapper {
      color: #515151;
      font-family: $open-sans;
      font-size: 42px;
    }

    @include mq(820px, max) {
      flex: 1 1 100%;
    }

    @include mq(640px, max) {
      .price-wrapper {
        justify-content: center;
      }
    }
  }
}

.pager.pager--infinite-scroll {
  background: #fafafa;
  margin: 0;
  padding-bottom: 45px;
  text-align: center;

  a {
    position: relative;
    color: #2e2e2e;
    font-family: $open-sans;
    font-size: 17px;
    font-weight: 400;
    padding-right: 30px;
    text-decoration: none;
    text-transform: lowercase;

    &:after {
      @include absolute-vertical-center;
      transform: translateY(-10%);
      content: '';
      border: 5px solid transparent;
      border-top: 5px solid #2e2e2e;
      right: 0;
    }
  }
}