 //Font faces
 //
 //Instead of relying on the fonts that are available on a user's computer, you
 //can use web fonts which, like images, are resources downloaded to the user's
 //browser. Because of the bandwidth and rendering resources required, web fonts
 //should be used with care.
 //
 //Numerous resources for web fonts can be found on Google. Here are a few
 //websites where you can find Open Source fonts to download:
 //- http://www.fontsquirrel.com/fontface
 //- http://www.theleagueofmoveabletype.com

 //In order to use these fonts, you will need to convert them into formats
 //suitable for web fonts. We recommend the free-to-use Font Squirrel's
 //Font-Face Generator:
 //  http://www.fontsquirrel.com/fontface/generator
 //
 //The following is an example @font-face declaration. This font can then be
 //used in any ruleset using a property like this:  font-family: Example, serif;
 //
 //Since we're using Sass, you'll need to declare your font faces here, then you
 //can add them to the font variables in the _init.scss partial.
 //
 //@font-face {
 //  font-family: 'Example';
 //  src: url('../fonts/example.eot');
 //  src: url('../fonts/example.eot?iefix') format('eot'),
 //    url('../fonts/example.woff') format('woff'),
 //    url('../fonts/example.ttf') format('truetype'),
 //    url('../fonts/example.svg#webfontOkOndcij') format('svg');
 //  font-weight: normal;
 //  font-style: normal;
 //}


@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|PT+Serif&subset=cyrillic');

@font-face {
  font-family: 'ALSRubl';
  src: url('../fonts/ALS-Rubl/ALSRubl.eot');
  src: url('../fonts/ALS-Rubl/ALSRubl.eot?iefix') format('eot'),
    url('../fonts/ALS-Rubl/ALSRubl.woff') format('woff'),
    url('../fonts/ALS-Rubl/ALSRubl.ttf') format('truetype'),
    url('../fonts/ALS-Rubl/ALSRubl.svg#webfontOkOndcij') format('svg');
  font-weight: 400;
  font-style: normal;
}

 @font-face {
   font-family: 'Font-Awesome';
   src: url('../fonts/Font-Awesome/Font-Awesome.eot');
   src: url('../fonts/Font-Awesome/Font-Awesome.eot?iefix') format('eot'),
   url('../fonts/Font-Awesome/Font-Awesome.woff') format('woff'),
   url('../fonts/Font-Awesome/Font-Awesome.ttf') format('truetype'),
   url('../fonts/Font-Awesome/Font-Awesome.svg#webfontOkOndcij') format('svg');
   font-weight: 400;
   font-style: normal;
 }

 @font-face {
   font-family: 'PlumbCondensed';
   src: url('../fonts/PlumbCondensed/PlumbCondensed-Regular.eot');
   src: url('../fonts/PlumbCondensed/PlumbCondensed-Regular.eot?iefix') format('eot'),
   url('../fonts/PlumbCondensed/PlumbCondensed-Regular.woff') format('woff'),
   url('../fonts/PlumbCondensed/PlumbCondensed-Regular.ttf') format('truetype'),
   url('../fonts/PlumbCondensed/PlumbCondensed-Regular.svg#webfontOkOndcij') format('svg');
   font-weight: 400;
   font-style: normal;
 }

 @font-face {
   font-family: 'PlumbCondensed';
   src: url('../fonts/PlumbCondensed-Black/PlumbCondensed-Black.eot');
   src: url('../fonts/PlumbCondensed-Black/PlumbCondensed-Black.eot?iefix') format('eot'),
   url('../fonts/PlumbCondensed-Black/PlumbCondensed-Black.woff') format('woff'),
   url('../fonts/PlumbCondensed-Black/PlumbCondensed-Black.ttf') format('truetype'),
   url('../fonts/PlumbCondensed-Black/PlumbCondensed-Black.svg#webfontOkOndcij') format('svg');
   font-weight: 900;
   font-style: normal;
 }

 @font-face {
   font-family: 'PlumbCondensed';
   src: url('../fonts/PlumbCondensed-Bold/PlumbCondensed-Bold.eot');
   src: url('../fonts/PlumbCondensed-Bold/PlumbCondensed-Bold.eot?iefix') format('eot'),
   url('../fonts/PlumbCondensed-Bold/PlumbCondensed-Bold.woff') format('woff'),
   url('../fonts/PlumbCondensed-Bold/PlumbCondensed-Bold.ttf') format('truetype'),
   url('../fonts/PlumbCondensed-Bold/PlumbCondensed-Bold.svg#webfontOkOndcij') format('svg');
   font-weight: 700;
   font-style: normal;
 }

 @font-face {
   font-family: 'PlumbCondensed';
   src: url('../fonts/PlumbCondensed-Light/PlumbCondensed-Light.eot');
   src: url('../fonts/PlumbCondensed-Light/PlumbCondensed-Light.eot?iefix') format('eot'),
   url('../fonts/PlumbCondensed-Light/PlumbCondensed-Light.woff') format('woff'),
   url('../fonts/PlumbCondensed-Light/PlumbCondensed-Light.ttf') format('truetype'),
   url('../fonts/PlumbCondensed-Light/PlumbCondensed-Light.svg#webfontOkOndcij') format('svg');
   font-weight: 300;
   font-style: normal;
 }

 @font-face {
   font-family: 'Myriad-Pro';
   src: url('../fonts/Myriad-Pro/Myriad-ProRegular.eot');
   src: url('../fonts/Myriad-Pro/Myriad-ProRegular.eot?iefix') format('eot'),
   url('../fonts/Myriad-Pro/Myriad-ProRegular.woff') format('woff'),
   url('../fonts/Myriad-Pro/Myriad-ProRegular.ttf') format('truetype'),
   url('../fonts/Myriad-Pro/Myriad-ProRegular.svg#webfontOkOndcij') format('svg');
   font-weight: 400;
   font-style: normal;
 }
