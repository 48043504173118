.ui-datepicker {
  padding: 0;
  z-index: 10 !important;

  .ui-datepicker-header {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    border-radius: 0;
    border: 0;
    background: $low-gray;
  }

  .ui-datepicker-prev, .ui-datepicker-next {
    @include absolute-vertical-center;
    z-index: 10;
    top: 50%;
    color: $black;
  }

  .ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
    border: 0;
    background: none;
    color: $black;
    padding-right: 5px;
  }

  .ui-datepicker-next-hover {
    right: 2px;
  }

  .ui-datepicker-prev-hover {
    left: 2px;
  }

  .ui-datepicker-title {
    font-family: $open-sans;
    font-size: 15px;
    color: $very-gray;
  }

  .ui-datepicker-calendar {
    font-family: $open-sans;
    text-align: center;

    th {
      font-size: 13px;
      font-weight: 400;
    }

    td {
      font-size: 12px;
      color: $very-gray;

      span, a {
        background: none;
        border: 0;
        text-align: center;
      }
    }

    .ui-datepicker-unselectable {
      color: $low-gray;
    }

    .ui-datepicker-days-cell-over {
      a {
        color: $white;
        background: $green;
        border: 0;
      }
    }
  }
}
