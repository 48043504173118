#bottom-region {
  padding: 5.3% 0 4% 0;
  text-align: center;
  background: $mega-gray;

  .block-block {
    display: inline-block;
    font-family: $open-sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    color: $white;
    text-decoration: none;

    a {
      display: block;
      margin-bottom: 20px;
      color: $very-white;
      text-decoration: none;

      &:hover {
        color: $green;
      }

      @include mq(600px, max) {
        font-size: 14px;
      }
    }

    b {
      display: block;
      margin-bottom: 25px;
      font-size: 17px;

      @include mq(600px, max) {
        font-size: 16px;
      }
    }

    p {
      text-align: center;
    }
  }

  .menu {
    margin: 0 0 52px;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;

    @include mq(810px, max) {
      flex-flow: row wrap;
      margin-bottom: 22px;
    }
  }

  .menu__item {
    list-style: none;
    padding: 0 30px;

    @include mq(810px, max) {
      flex: 1 1 100%;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .menu__link {
    color: #aaaaaa;
    font-family: $open-sans;
    font-size: 15px;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
  }
}
