// Dependencies.
@import 'components/visually-hidden/visually-hidden';

// Breadcrumb navigation
//
// The path to the current page in the form of a list of links.
//
// Markup: breadcrumb.twig
//
// Style guide: navigation.breadcrumb

//.breadcrumb,
//%breadcrumb {
//  @media print {
//    display: none;
//  }
//
//  &__title {
//    @extend %visually-hidden;
//  }
//
//  &__list {
//    margin: 0;
//    padding: 0;
//  }
//
//  &__item {
//    display: inline;
//    list-style-type: none;
//    margin: 0;
//    padding: 0;
//  }
//}

.breadcrumb {
  padding-left: 10.5%;
  background: $white;
}

.breadcrumb__separator {
  padding: 0 40px 0 13px;
}

.breadcrumb__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.breadcrumb__title {
  display: none;
}

.breadcrumb__item {
  display: inline;
  font-family: $open-sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 3em;
  color:  $middle-gray;

  &:last-child {
    color: $green;
  }

  &:hover {
    color: $mega-gray;
  }

  a {
    text-decoration: none;
  }
}
