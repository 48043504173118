.section-booking .some-content-class {
  background-color:  #fafafa;
}

.region-highlighted {
  @include wrapper(1062px);
  background-color:  #fafafa;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-flow: row wrap;

  .field-slideshow-controls {
    display: none;
  }
}

#block-webform-client-block-20 {
  background-color: $very-white;
  flex: 0 1 61.14%;
  margin-bottom: 49px;

  @include mq(1032px, max) {
    flex: 1 1 100%;
    padding: 0 15px;
  }

  .webform-client-form {
    max-width: 790px;
    margin: 0 auto;
    padding: 44px 41px 65px 44px;
    background-color: $very-white;

    @include mq(1032px, max) {
      padding: 10px 0;
    }

    div {
      @include clearfix;
    }
  }

  .webform-component {
    margin-bottom: 14px;
  }

  label {
    font-family: $open-sans;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    color: $very-gray;

    span {
      font-size: 17px;
      color: $very-gray;
    }
  }

  input, textarea {
    width: 100%;
    padding-left: 22px;
    height: 49px;
    border: 2px solid $low-gray;

    @include mq(569px, max) {
      height: 40px;
    }

    &::placeholder {
      font-family: $open-sans;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      color: $middle-gray;

      @include mq(569px, max) {
        font-size: 14px;
      }
    }
  }

  .phone input {
    padding-left: 68px;
  }

  textarea {
    height: auto;
  }

  .type-room {
    width: 100% !important;
    min-width: 0;
    height: 56px;
    padding-left: 22px;
    background: $very-white;
    border: 2px solid $low-gray;

    .selectBox-label {
      width: 100% !important;
      padding: 1% 0 0 0;
      font-family: $open-sans;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 2.7em;
      color: $middle-gray;
      background: $very-white;
      border: none;
    }

    .selectBox-arrow {
      width: 10%;
      top: 0;
      right: 0;
      background: url("../img/down_2.png") no-repeat 50% 50% $very-white;
      border-left: none;
    }
  }

  .webform-component-email, .phone, .fullname, .webform-component--notes  {
    float: left;
    width: 100%;
    font-family: $open-sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    color: $middle-gray;

    @include mq(569px, max) {
      font-size: 14px;
    }
  }

  .phone {
    .intl-tel-input {
      display: block;
    }
  }

  .webform-component--arrival-time, .webform-component--time-of-departure {
    float: left;
    width: 48%;
    font-family: $open-sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    color: $middle-gray;

    @include mq(569px, max) {
      font-size: 14px;
      width: 100%;
    }
  }

  .webform-component--arrival-time {
    margin-right: 4%;


    @include mq(569px, max) {
      margin-right: 0;
    }

    .webform-calendar {
      width: 100%;
    }
  }

  .arraival-time, .departure-time {

    .webform-container-inline {
      display: flex;
      align-items: center;
      width: auto !important;
      height: 56px;
      padding-left: 22px;
      font-family: $open-sans;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      color: $middle-gray;
      background: $very-white;
      border: 2px solid $low-gray;

      @include mq(569px, max) {
        height: 50px;
        font-size: 14px;
      }
    }

    .selectBox-arrow {
      display: none;
    }
  }

  .hour, .minute {
    width: 100% !important;
    min-width: 0;
    padding: 0;
    line-height: 4.2em;
    border: none;
    background: transparent;
    box-shadow: none;

    .selectBox-label {
      width: auto !important;
      display: block;
    }
  }

  .form-actions {
    float: left;
    width: 100%;
    text-align: right;
  }

  .webform-submit {
    width: 100%;
    max-width: 255px;
    padding: 1em 4em;
    font-family: $open-sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    color: $white;
    background: $green;
    border: none;
    outline: none;

    @include mq(840px, max) {
      max-width: 50%;
    }

    @include mq(600px, max) {
      max-width: none;
    }

    @include mq(569px, max) {
      font-size: 14px;
      height: auto;
    }

    &:hover {
      background-color: $very-gray;
      color: $white;
    }
  }

  .form-item-submitted-arrival-time-hour, .form-item-submitted-arrival-time-minute,
  .form-item-submitted-time-of-departure-hour, .form-item-submitted-time-of-departure-minute {
    display: inline-block;
  }

  .form-item-submitted-arrival-date-month, .form-item-submitted-arrival-date-day, .form-item-submitted-arrival-date-year,
  .form-item-submitted-date-of-departure-month, .form-item-submitted-date-of-departure-day,
  .form-item-submitted-date-of-departure-year {
    display: none;
  }
}

.section-booking {
    .block-title-wrapper {
    padding: 46px 0 22px;
    text-align: center;
    background: $very-white;
  }

  .block-views {
    .block-title-wrapper {
      margin: 0;
    }

  }

  .block-webform {
    .block-title-wrapper {
      text-align: center;
      padding: 2.2% 0 1.65%;
      margin: 0;
      display: none;

      @include mq(840px, max) {
        padding: 2.2% 0 1.65%;
      }

      @include mq(400px, max) {
        padding: 3.2% 0 3.65%;
      }

      h2 {
        margin-bottom: 1.44%;

        @include mq(600px, max) {
          margin-bottom: 3.44%;
        }
      }
    }

    h4 {
      font-family: $open-sans;
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 0;
      color: $very-gray;
      margin: 0;

      @include mq(1240px, max) {
        font-size: 15px;
      }

      @include mq(640px, max) {
        font-size: 14px;
      }
    }
  }

  .price-wrapper {
    margin: 0;
    padding-top: 25px;
    margin-right: 60px;

    @include mq(1700px, max) {
      margin-bottom: 30px;
    }

    @include mq(1367px, max) {
      padding-top: 15px;
      margin-right: 15px;
      margin-bottom: 10px;
    }

    .custom-value-wrapper {
      font-size: 40px;

      @include mq(1600px, max) {
        font-size: 35px;
      }

      @include mq(1440px, max) {
        font-size: 30px;
      }

      @include mq(1200px, max) {
        font-size: 25px;
      }

      @include mq(900px, max) {
        font-size: 20px;
      }
    }

    .custom-prefix-wrapper {
      margin-right: 8.75%;
      font-family: $open-sans;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      color: $very-gray;
    }

    .custom-suffix-wrapper {
      sub {
        top: 1em;
        white-space: nowrap;

        @include mq(1440px, max) {
          top: 0.45em;
        }

        @include mq(1200px, max) {
          top: 0.2em;
        }

        @include mq(900px, max) {
          top: 0;
        }
      }

      sup {
        font-size: 20px;

        @include mq(1200px, max) {
          font-size: 16px;
        }

        @include mq(900px, max) {
          font-size: 14px;
        }
      }
    }

    @include mq(500px, max) {
      float: none;
      margin-bottom: 40px;
    }
  }
}

.section-booking {
  .booking-details {
    padding: 0 40px 44px;

    @include mq(1032px, max) {
      padding: 10px 0;
    }

    .views-row {
      display: flex;
      justify-content: space-between;
      flex-flow: row wrap;
    }

    .views-field-field-photo {
      flex: 1 1 100%;
      width: 100%;
      max-width: 100%;

      @include mq(660px, max) {
        flex: 1 1 100%;
        margin-bottom: 30px;
      }

      ul {
        padding: 0;
        margin: 0;
        list-style: none;
      }

      .field-slideshow {
        width: 100% !important;
      }
    }

    .views-field-body {
      flex: 0 1 100%;

      @include mq(660px, max) {
        flex: 0 1 100%;
      }

      .title {
        display: none;
        margin-bottom: 6.207%;
        font-family: $plumb;
        font-size: 60px;
        font-style: normal;
        font-weight: 400;
        text-transform: uppercase;
        color: $mega-gray;

        @include mq(1610px, max) {
          font-size: 56px;
        }

        @include mq(1440px, max) {
          font-size: 45px;
        }

        @include mq(1200px, max) {
          font-size: 40px;
        }

        @include mq(900px, max) {
          font-size: 36px;
        }

        @include mq(600px, max) {
          font-size: 30px;
        }

        @include mq(400px, max) {
          font-size: 25px;
        }
      }

      ul {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        margin: 0;
        padding: 0;
        list-style: none;
      }

      li {
        flex: 0 1 46%;
        margin-right: 3.01%;
        margin-bottom: 2.257%;

        &:nth-last-child(2) {
          margin-bottom: 0;

          @include mq(600px, max) {
            margin-bottom: 2.257%;
          }
        }

        &.last {
          margin-bottom: 0;
        }

        @include mq(1600px, max) {
          &:nth-child(odd) {
            padding-right: 15px;
          }
        }

        @include mq(1030px, max) {
          flex: 0 1 33.3%;
          margin-right: 0;
          margin-bottom: 2.095%;
        }

        @include mq(925px, max) {
          flex: 0 1 50%;
        }

        @include mq(600px, max) {
          flex: 0 1 100%;
        }

        p:last-of-type {
          display: none;
          opacity: 0;
        }
      }
    }
  }
}

.section-booking .block-lermontov-hotel {
  background: #fff;
  flex: 0 1 38.85%;
  width: 38.85%;
  max-width: 38.85%;
  margin-bottom: 49px;

  @include mq(1032px, max) {
    flex: 1 1 100%;
    max-width: 100%;
    padding: 0 15px;
  }

  .order-date {
    padding: 44px 40px 6px;
    color: #515151;
    font-size: 17px;
    font-weight: 400;

    @include mq(1032px, max) {
      padding: 40px 0 6px;
    }
  }
}

#block-block-11 {
  flex: 1 1 100%;
  margin-bottom: 36px;

  .block-title-wrapper {
    text-align: center;
    padding: 50px 0 28px;
    margin: 0;

    @include mq(840px, max) {
      padding: 50px 0 28px;
    }

    @include mq(400px, max) {
      padding: 50px 0 28px;
    }

    h2 {
      margin-bottom: 1.44%;

      @include mq(600px, max) {
        margin-bottom: 3.44%;
      }
    }
  }

  h4 {
    font-family: $open-sans;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
    color: $very-gray;
    margin: 0;

    @include mq(1240px, max) {
      font-size: 15px;
    }

    @include mq(640px, max) {
      font-size: 14px;
    }
  }
}

.booking-room-carousel {
  position: relative;

  .owl-stage-outer {
    width: 100%;
    max-width: 100%;
  }

  .owl-nav {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .owl-prev, .owl-next {
    float: left;
    position: relative;
    display: block;
    width: 45px;
    height: 45px;
    background-color: $white;
    font-size: 0;

    &::before {
      @include absolute-center;
      font-size: 26px;
      content: '';
      font-family: $fa;
      font-style: normal;
      font-weight: 400;
      color: $middle-gray;

      @include mq(1660px, max) {
        font-size: 22px;
      }

      @include mq(1440px, max) {
        font-size: 18px;
      }

      @include mq(1200px, max) {
        font-size: 14px;
      }
    }

    &:hover, &:active {
      background-color: $green;

      &::before {
        color: $white;
      }
    }

    @include mq(1440px, max) {
      width: 50px;
      height: 50px;
    }

    @include mq(1200px, max) {
      width: 30px;
      height: 30px;
    }
  }

  .owl-next::before {
    content: '';
  }

}

