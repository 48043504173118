@charset "UTF-8";
/*! typey | GPLv2 License | https://github.com/jptaranto/typey */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700|PT+Serif&subset=cyrillic");
@font-face {
  font-family: 'ALSRubl';
  src: url("../fonts/ALS-Rubl/ALSRubl.eot");
  src: url("../fonts/ALS-Rubl/ALSRubl.eot?iefix") format("eot"), url("../fonts/ALS-Rubl/ALSRubl.woff") format("woff"), url("../fonts/ALS-Rubl/ALSRubl.ttf") format("truetype"), url("../fonts/ALS-Rubl/ALSRubl.svg#webfontOkOndcij") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Font-Awesome';
  src: url("../fonts/Font-Awesome/Font-Awesome.eot");
  src: url("../fonts/Font-Awesome/Font-Awesome.eot?iefix") format("eot"), url("../fonts/Font-Awesome/Font-Awesome.woff") format("woff"), url("../fonts/Font-Awesome/Font-Awesome.ttf") format("truetype"), url("../fonts/Font-Awesome/Font-Awesome.svg#webfontOkOndcij") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'PlumbCondensed';
  src: url("../fonts/PlumbCondensed/PlumbCondensed-Regular.eot");
  src: url("../fonts/PlumbCondensed/PlumbCondensed-Regular.eot?iefix") format("eot"), url("../fonts/PlumbCondensed/PlumbCondensed-Regular.woff") format("woff"), url("../fonts/PlumbCondensed/PlumbCondensed-Regular.ttf") format("truetype"), url("../fonts/PlumbCondensed/PlumbCondensed-Regular.svg#webfontOkOndcij") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'PlumbCondensed';
  src: url("../fonts/PlumbCondensed-Black/PlumbCondensed-Black.eot");
  src: url("../fonts/PlumbCondensed-Black/PlumbCondensed-Black.eot?iefix") format("eot"), url("../fonts/PlumbCondensed-Black/PlumbCondensed-Black.woff") format("woff"), url("../fonts/PlumbCondensed-Black/PlumbCondensed-Black.ttf") format("truetype"), url("../fonts/PlumbCondensed-Black/PlumbCondensed-Black.svg#webfontOkOndcij") format("svg");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'PlumbCondensed';
  src: url("../fonts/PlumbCondensed-Bold/PlumbCondensed-Bold.eot");
  src: url("../fonts/PlumbCondensed-Bold/PlumbCondensed-Bold.eot?iefix") format("eot"), url("../fonts/PlumbCondensed-Bold/PlumbCondensed-Bold.woff") format("woff"), url("../fonts/PlumbCondensed-Bold/PlumbCondensed-Bold.ttf") format("truetype"), url("../fonts/PlumbCondensed-Bold/PlumbCondensed-Bold.svg#webfontOkOndcij") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'PlumbCondensed';
  src: url("../fonts/PlumbCondensed-Light/PlumbCondensed-Light.eot");
  src: url("../fonts/PlumbCondensed-Light/PlumbCondensed-Light.eot?iefix") format("eot"), url("../fonts/PlumbCondensed-Light/PlumbCondensed-Light.woff") format("woff"), url("../fonts/PlumbCondensed-Light/PlumbCondensed-Light.ttf") format("truetype"), url("../fonts/PlumbCondensed-Light/PlumbCondensed-Light.svg#webfontOkOndcij") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Myriad-Pro';
  src: url("../fonts/Myriad-Pro/Myriad-ProRegular.eot");
  src: url("../fonts/Myriad-Pro/Myriad-ProRegular.eot?iefix") format("eot"), url("../fonts/Myriad-Pro/Myriad-ProRegular.woff") format("woff"), url("../fonts/Myriad-Pro/Myriad-ProRegular.ttf") format("truetype"), url("../fonts/Myriad-Pro/Myriad-ProRegular.svg#webfontOkOndcij") format("svg");
  font-weight: 400;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  line-height: 1.5em;
  font-family: Verdana, Tahoma, "DejaVu Sans", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
}

@media print {
  html {
    font-size: 12pt;
  }
}

body {
  margin: 0;
  padding: 0;
  color: #000;
  background-color: #fff;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

a {
  color: #0072b9;
  -webkit-text-decoration-skip: objects;
}

:visited {
  color: #003353;
}

a:active {
  color: #c00;
}

a:active:not(.button) {
  background-color: transparent;
}

a:active,
a:hover {
  outline-width: 0;
}

@media print {
  :link,
  :visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
    font-weight: normal;
    font-size: 16px;
    text-decoration: none;
  }
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
}

h1 {
  font-size: 2rem;
  line-height: 3rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h2 {
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h3 {
  font-size: 1.25rem;
  line-height: 2.25rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h4 {
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h5 {
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h6 {
  font-size: 0.625rem;
  line-height: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted;
}

@media print {
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
}

b,
strong {
  font-weight: inherit;
}

strong,
b {
  font-weight: bolder;
}

pre,
code,
kbd,
samp,
var {
  font-family: Menlo, "DejaVu Sans Mono", "Ubuntu Mono", Courier, "Courier New", monospace, sans-serif;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #fd0;
  color: #000;
}

small {
  font-size: 0.875rem;
}

sub,
sup {
  font-size: 0.625rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

.divider,
hr {
  margin: 1.5rem 0;
  border: 0;
  border-top: 1px solid #cccccc;
}

.divider > :first-child, hr > :first-child {
  margin-top: 1.5rem;
}

blockquote {
  margin: 1.5rem 2rem;
}

dl,
menu,
ol,
ul {
  margin: 1.5rem 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

dd {
  margin: 0 0 0 32px;
}

[dir="rtl"] dd {
  margin: 0 32px 0 0;
}

menu,
ol,
ul {
  padding: 0 0 0 32px;
}

[dir="rtl"] menu, [dir="rtl"]
ol, [dir="rtl"]
ul {
  padding: 0 32px 0 0;
}

figure {
  margin: 1.5rem 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

p,
pre {
  margin: 1.5rem 0;
}

img {
  border-style: none;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  box-sizing: border-box;
  max-width: 100%;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

input {
  overflow: visible;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button, [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.button,
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -moz-appearance: button;
  -webkit-appearance: button;
}

.button,
button,
[type='button'],
[type='reset'],
[type='submit'] {
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #000;
}

.button::-moz-focus-inner,
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.button:-moz-focusring,
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.button:hover, .button:focus, .button:active,
button:hover,
button:focus,
button:active,
[type='button']:hover,
[type='button']:focus,
[type='button']:active,
[type='reset']:hover,
[type='reset']:focus,
[type='reset']:active,
[type='submit']:hover,
[type='submit']:focus,
[type='submit']:active {
  text-decoration: none;
  color: #000;
}

[disabled].button,
button[disabled],
[disabled][type='button'],
[disabled][type='reset'],
[disabled][type='submit'] {
  cursor: default;
  color: #999999;
}

[disabled].button:hover,
button[disabled]:hover,
[disabled][type='button']:hover,
[disabled][type='reset']:hover,
[disabled][type='submit']:hover, [disabled].button:focus,
button[disabled]:focus,
[disabled][type='button']:focus,
[disabled][type='reset']:focus,
[disabled][type='submit']:focus, [disabled].button:active,
button[disabled]:active,
[disabled][type='button']:active,
[disabled][type='reset']:active,
[disabled][type='submit']:active {
  color: #999999;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

fieldset {
  padding: 0.525rem 0.9375rem 0.975rem;
  border: 1px solid #cccccc;
  margin: 0 2px;
}

legend {
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  white-space: normal;
  color: inherit;
  margin-left: -5px;
  padding: 0 5px;
}

label {
  display: block;
  font-weight: bold;
}

optgroup {
  font-weight: bold;
}

textarea {
  overflow: auto;
}

table {
  margin: 1.5rem 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td,
th {
  padding: 0;
}

.layout-3col {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 0;
  padding-right: 0;
}

.layout-3col:before {
  content: "";
  display: table;
}

.layout-3col:after {
  content: "";
  display: table;
  clear: both;
}

.layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar, .layout-3col__col-1, .layout-3col__col-2, .layout-3col__col-3, .layout-3col__col-4, .layout-3col__col-x {
  clear: both;
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  width: 100%;
  margin-left: 0%;
  margin-right: -100%;
}

[dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x {
  float: right;
  margin-right: 0%;
  margin-left: -100%;
}

@media (min-width: 777px) {
  .layout-3col {
    margin-left: -12px;
    margin-right: -13px;
    padding-left: 0;
    padding-right: 0;
  }
  .layout-3col:before {
    content: "";
    display: table;
  }
  .layout-3col:after {
    content: "";
    display: table;
    clear: both;
  }
  [dir="rtl"] .layout-3col {
    margin-left: -13px;
    margin-right: -12px;
  }
  .layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__first-left-sidebar, .layout-3col__col-1, .layout-3col__col-3, .layout-3col__col-x:nth-child(2n + 1) {
    float: left;
    width: 50%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__second-left-sidebar, .layout-3col__col-2, .layout-3col__col-4, .layout-3col__col-x:nth-child(2n) {
    clear: none;
    float: left;
    width: 50%;
    margin-left: 50%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    float: right;
    margin-right: 50%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    padding-left: 13px;
    padding-right: 12px;
  }
}

@media (min-width: 999px) {
  .layout-3col__full {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__full {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__left-content {
    float: left;
    width: 66.66667%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__left-content {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__right-content {
    float: left;
    width: 66.66667%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__right-content {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__left-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    float: left;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  .layout-3col__right-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    float: left;
    margin-left: 0%;
    margin-right: -100%;
  }
  .layout-3col__col-1, .layout-3col__col-x:nth-child(3n+1) {
    clear: both;
    float: left;
    width: 33.33333%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-x:nth-child(3n+1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__col-2, .layout-3col__col-x:nth-child(3n+2) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-x:nth-child(3n+2) {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__col-3, .layout-3col__col-x:nth-child(3n) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(3n) {
    float: right;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  .layout-3col__col-4 {
    display: none;
  }
}

.layout-3col__grid-item-container {
  padding-left: 0;
  padding-right: 0;
}

.layout-3col__grid-item-container:before {
  content: "";
  display: table;
}

.layout-3col__grid-item-container:after {
  content: "";
  display: table;
  clear: both;
}

.layout-center {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  max-width: 1165px;
}

@media (min-width: 777px) {
  .layout-center {
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-center {
    padding-left: 13px;
    padding-right: 12px;
  }
}

.layout-center--shared-grid,
.layout-center.layout-3col {
  padding-left: 0;
  padding-right: 0;
}

.layout-swap {
  position: relative;
}

@media (min-width: 555px) {
  .layout-swap {
    padding-top: 48px;
  }
}

@media (min-width: 555px) {
  .layout-swap__top {
    position: absolute;
    top: 0;
    height: 48px;
    width: 100%;
  }
}

.layout-main {
  width: 100%;
}

.box {
  margin-bottom: 1.5rem;
  border: 5px solid #cccccc;
  padding: 1em;
}

.box__title {
  margin: 0;
}

.box:focus, .box:hover, .box.is-focus, .box--is-focus {
  border-color: #000;
}

.box--highlight {
  border-color: #0072b9;
}

.clearfix:after,
.tabs:after {
  content: "";
  display: table;
  clear: both;
}

.comment__section,
.comments {
  margin: 1.5rem 0;
}

.comment__title {
  margin: 0;
}

.comment__permalink {
  text-transform: uppercase;
  font-size: 75%;
}

.comment--preview,
.comment-preview {
  background-color: #fffadb;
}

.comment--nested,
.indented {
  margin-left: 32px;
}

[dir="rtl"] .comment--nested,
[dir="rtl"] .indented {
  margin-left: 0;
  margin-right: 32px;
}

#headline-region {
  display: flex;
  padding: 28px 10.365% 6px;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: center;
  background: #2e2e2e;
}

@media only screen and (max-width: 440px) {
  #headline-region {
    flex-flow: column wrap;
    align-items: center;
  }
}

#headline-region #block-block-3 {
  margin: 0.59% 0 0 1.7%;
}

@media only screen and (max-width: 440px) {
  #headline-region #block-block-3 {
    align-self: stretch;
  }
}

#headline-region .address, #headline-region .phone {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  color: #fafafa;
}

@media only screen and (max-width: 840px) {
  #headline-region .address, #headline-region .phone {
    display: block;
    font-size: 16px;
    margin-right: 0;
  }
}

@media only screen and (max-width: 600px) {
  #headline-region .address, #headline-region .phone {
    font-size: 16px;
  }
}

#headline-region .address {
  position: relative;
  margin-right: 38px;
}

@media only screen and (max-width: 840px) {
  #headline-region .address {
    margin-right: 0;
  }
}

#headline-region .address::before {
  position: absolute;
  left: -2em;
  top: 0.5em;
  content: '';
  font-family: "Font-Awesome", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  color: #019966;
}

#headline-region .phone {
  position: relative;
}

#headline-region .phone::before {
  position: absolute;
  left: -1.5em;
  top: 0.5em;
  content: '';
  transform: rotate(-20deg);
  font-family: "Font-Awesome", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  color: #019966;
}

@media only screen and (max-width: 440px) {
  #headline-region .phone::before {
    left: -1.88em;
  }
}

#headline-region p {
  margin: 0;
}

#headline-region p br {
  display: none;
}

#headline-region select {
  width: 100% !important;
}

#headline-region .selectBox-dropdown {
  position: relative;
  display: block !important;
  width: 90px !important;
  min-width: auto;
  padding: 0;
  height: auto;
  line-height: normal;
  background: #2e2e2e;
  border: none;
  border-radius: 0;
  outline: none;
  box-shadow: none;
}

#headline-region .selectBox-dropdown .selectBox-label {
  width: 100%;
  padding: 1% 0 0 3.25%;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 2.7em;
  color: #aaaaaa;
  background: #2e2e2e;
  border: none;
}

@media only screen and (max-width: 600px) {
  #headline-region .selectBox-dropdown .selectBox-label {
    font-size: 14px;
  }
}

#headline-region .selectBox-dropdown .selectBox-arrow {
  width: 10%;
  top: 2px;
  right: 4px;
  background: url("../img/down_2.png") no-repeat 50% 50% #2e2e2e;
  border-left: none;
}

@media only screen and (max-width: 440px) {
  #headline-region .selectBox-dropdown .selectBox-arrow {
    top: 0;
  }
}

.header__region {
  padding-top: 1.55%;
}

.header__region .block-block {
  max-width: 342px;
  margin: 11px auto 0;
}

.header__region .block-block img {
  width: 100%;
}

.header__region .menu {
  margin: 2em 0 1.2em;
  padding: 0;
  list-style: none;
  text-align: center;
}

.header__region .menu__item {
  display: inline-block;
  margin-right: 3em;
}

.header__region .menu__item:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 900px) {
  .header__region .menu__item {
    margin-right: 1.8em;
  }
}

@media only screen and (max-width: 740px) {
  .header__region .menu__item {
    display: block;
    margin-right: 0;
  }
}

.header__region .menu__link {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  color: #515151;
  text-decoration: none;
}

.header__region .menu__link:hover {
  color: #019966;
}

@media only screen and (max-width: 900px) {
  .header__region .menu__link {
    font-size: 16px;
  }
}

.header__region .selectBox-dropdown-menu {
  width: 92px;
  top: 76px !important;
  left: 1631px;
  right: 10.9%;
  border: none !important;
}

.header__region .selectBox-dropdown-menu .selectBox-options li a {
  color: #fafafa;
  background: #019966;
}

input:focus {
  border: none;
  outline: none;
}

@media only screen and (max-width: 740px) {
  .block-menu {
    margin: 15px 0 10px;
    text-align: center;
  }
}

.burger {
  display: none;
  margin: 0;
}

@media only screen and (max-width: 740px) {
  .burger {
    display: inline-block;
    font-size: 0;
    width: 30px;
    height: 25px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
  }
}

.burger span {
  display: block;
  position: absolute;
  height: 5px;
  width: 50%;
  background: #2e2e2e;
  opacity: 1;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.burger span:nth-child(even) {
  left: 50%;
  border-radius: 0 5px 5px 0;
}

.burger span:nth-child(odd) {
  left: 0;
  border-radius: 5px 0 0 5px;
}

.burger span:nth-child(1), .burger span:nth-child(2) {
  top: 0;
}

.burger span:nth-child(3), .burger span:nth-child(4) {
  top: 10px;
}

.burger span:nth-child(5), .burger span:nth-child(6) {
  top: 20px;
}

.burger.open span:nth-child(1), .burger.open span:nth-child(6) {
  transform: rotate(45deg);
}

.burger.open span:nth-child(2), .burger.open span:nth-child(5) {
  transform: rotate(-45deg);
}

.burger.open span:nth-child(1) {
  left: 3px;
  top: 6px;
}

.burger.open span:nth-child(2) {
  left: calc(50% - 3px);
  top: 6px;
}

.burger.open span:nth-child(3) {
  left: 0;
  opacity: 0;
}

.burger.open span:nth-child(4) {
  left: 50%;
  opacity: 0;
}

.burger.open span:nth-child(5) {
  left: 3px;
  top: 15px;
}

.burger.open span:nth-child(6) {
  left: calc(50% - 3px);
  top: 15px;
}

@media only screen and (max-width: 740px) {
  .menu {
    display: none;
    opacity: 0;
  }
  .menu.show {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    opacity: 1;
    transition: 600ms all;
  }
}

@media only screen and (max-width: 740px) and (max-width: 400px) {
  .menu.show {
    border-radius: 0;
  }
}

.selectBox-options li a {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #019966;
  background: #fafafa;
}

.selectBox-options li.selectBox-hover a {
  color: #fafafa;
  background-color: #019966;
}

.lang-dropdown-select-element-selectBox-dropdown-menu {
  top: 76px !important;
  width: 100px !important;
  height: 31px !important;
}

@media only screen and (max-width: 840px) {
  .lang-dropdown-select-element-selectBox-dropdown-menu {
    top: 81px !important;
  }
}

@media only screen and (max-width: 440px) {
  .lang-dropdown-select-element-selectBox-dropdown-menu {
    top: 120px !important;
  }
}

.lang-dropdown-select-element-selectBox-dropdown-menu li a {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #fafafa;
  background: #019966;
  padding: 0.383em 1em;
}

.lang-dropdown-select-element-selectBox-dropdown-menu li.selectBox-hover a {
  color: #fafafa;
  background-color: #019966;
  padding: 0.383em 1em;
}

.selectBox-options-bottom {
  box-shadow: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.selectBox-options-bottom li.selectBox-selected {
  display: none;
}

.hidden,
html.js .js-hidden,
html.js .element-hidden,
html.js .js-hide {
  display: none;
}

.highlight-mark,
.new,
.update {
  color: #c00;
  background-color: transparent;
}

.inline-links,
.inline.links {
  padding: 0;
}

.inline-links__item,
.inline.links li {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0;
}

[dir="rtl"] .inline-links__item,
[dir="rtl"] .inline.links li,
.inline.links [dir="rtl"] li {
  display: inline-block;
  padding: 0 0 0 1em;
}

.inline-sibling,
.field-label-inline .field-label,
span.field-label {
  display: inline;
  margin-right: 10px;
}

[dir="rtl"] .inline-sibling,
[dir="rtl"] .field-label-inline .field-label,
.field-label-inline [dir="rtl"] .field-label,
[dir="rtl"] span.field-label {
  margin-right: 0;
  margin-left: 10px;
}

.inline-sibling__child,
.inline-sibling *, .field-label-inline .field-label *, span.field-label * {
  display: inline;
}

.inline-sibling__adjacent,
.inline-sibling + *,
.inline-sibling + * > :first-child,
.inline-sibling + * > :first-child > :first-child, .field-label-inline .field-label + *, span.field-label + *, .field-label-inline .field-label + * > :first-child, span.field-label + * > :first-child, .field-label-inline .field-label + * > :first-child > :first-child, span.field-label + * > :first-child > :first-child {
  display: inline;
}

.field-label-inline .field-label,
.field-label-inline .field-items {
  float: none;
}

.messages,
.messages--status {
  margin: 1.5rem 0;
  position: relative;
  padding: 40px 10% 35px;
  border: 1px solid #0072b9;
  margin-left: 44px;
  margin-right: 44px;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 22px;
}

[dir="rtl"] .messages,
[dir="rtl"] .messages--status {
  padding: 40px 10% 35px;
  background-position: 99% 8px;
}

.messages__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: calc(5% - 37px);
  height: 74px;
  width: 74px;
  line-height: 1;
}

[dir="rtl"] .messages__icon {
  left: auto;
  right: 0;
}

.messages__icon path {
  fill: #0072b9;
}

.messages__icon--error {
  background: url("../img/warning.png") no-repeat;
}

.messages__icon--error svg {
  display: none;
}

.messages__highlight,
.messages--error .error,
.messages.error .error {
  color: #000;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

.messages--ok-color,
.messages,
.messages--status,
.ok,
.revision-current {
  background-color: #f6fcff;
  color: #0072b9;
}

.messages--warning-color,
.messages--warning,
.messages.warning,
.warning {
  background-color: #fffce6;
  color: #000;
}

.messages--error-color,
.messages--error,
.messages.error,
.error {
  background-color: #fe8383;
  color: #fff;
}

.messages--warning,
.messages.warning {
  border-color: #fd0;
}

.messages--warning path, .messages.warning path {
  fill: #fd0;
}

.messages--error,
.messages.error {
  border-color: #c00;
}

.messages--error path, .messages.error path {
  fill: #c00;
}

@media only screen and (max-width: 800px) {
  .messages,
  .messages--status {
    margin-right: 0;
    margin-left: 0;
  }
}

@media print {
  .print-none,
  .toolbar,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .feed-icons {
    display: none;
  }
}

.responsive-video,
.media-youtube-video,
.media-vimeo-preview-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.responsive-video__embed,
.responsive-video iframe,
.media-youtube-video iframe,
.media-vimeo-preview-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-video--4-3 {
  padding-bottom: 75%;
}

.visually-hidden,
.element-invisible,
.element-focusable,
.main-navigation .block-menu .block__title,
.main-navigation .block-menu-block .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.visually-hidden--off,
.visually-hidden--focusable:active,
.visually-hidden--focusable:focus,
.element-focusable:active,
.element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: visible;
}

.watermark {
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  display: block;
  height: 0;
  overflow: visible;
  background-color: transparent;
  color: #eeeeee;
  font-size: 75px;
  line-height: 1;
  text-align: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
}

.ui-datepicker {
  padding: 0;
  z-index: 10 !important;
}

.ui-datepicker .ui-datepicker-header {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  border-radius: 0;
  border: 0;
  background: #e6e2e3;
}

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  top: 50%;
  color: black;
}

.ui-datepicker .ui-state-hover, .ui-datepicker .ui-widget-content .ui-state-hover, .ui-datepicker .ui-widget-header .ui-state-hover, .ui-datepicker .ui-state-focus, .ui-datepicker .ui-widget-content .ui-state-focus, .ui-datepicker .ui-widget-header .ui-state-focus {
  border: 0;
  background: none;
  color: black;
  padding-right: 5px;
}

.ui-datepicker .ui-datepicker-next-hover {
  right: 2px;
}

.ui-datepicker .ui-datepicker-prev-hover {
  left: 2px;
}

.ui-datepicker .ui-datepicker-title {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  color: #515151;
}

.ui-datepicker .ui-datepicker-calendar {
  font-family: "Open Sans", Arial, sans-serif;
  text-align: center;
}

.ui-datepicker .ui-datepicker-calendar th {
  font-size: 13px;
  font-weight: 400;
}

.ui-datepicker .ui-datepicker-calendar td {
  font-size: 12px;
  color: #515151;
}

.ui-datepicker .ui-datepicker-calendar td span, .ui-datepicker .ui-datepicker-calendar td a {
  background: none;
  border: 0;
  text-align: center;
}

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-unselectable {
  color: #e6e2e3;
}

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-days-cell-over a {
  color: #fafafa;
  background: #019966;
  border: 0;
}

#block-lermontov-hotel-booking-form-block {
  width: 100%;
  max-width: 100%;
  background: rgba(6, 142, 96, 0.75);
  display: flex;
  justify-content: center;
  padding: 30px 0;
}

body.front #block-lermontov-hotel-booking-form-block {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
}

@media only screen and (max-width: 1220px) {
  body.front #block-lermontov-hotel-booking-form-block {
    position: static;
  }
}

#block-lermontov-hotel-booking-form-block .form-additional-text {
  margin-right: 1em;
}

@media only screen and (max-width: 1220px) {
  #block-lermontov-hotel-booking-form-block .form-additional-text {
    margin-right: 0;
    flex: 1 1 100%;
    margin-bottom: 1.5em;
  }
}

#block-lermontov-hotel-booking-form-block .text-main {
  font-style: "Open Sans", Arial, sans-serif;
  font-weight: 600;
  color: #fafafa;
  font-size: 26px;
  margin: 0 0 10px;
}

@media only screen and (max-width: 1220px) {
  #block-lermontov-hotel-booking-form-block .text-main {
    text-align: center;
  }
}

#block-lermontov-hotel-booking-form-block .text-bottom {
  font-style: "Open Sans", Arial, sans-serif;
  font-weight: 500;
  color: #fafafa;
  font-size: 12px;
  display: block;
}

@media only screen and (max-width: 1220px) {
  #block-lermontov-hotel-booking-form-block .text-bottom {
    text-align: center;
  }
}

#block-lermontov-hotel-booking-form-block form > div {
  display: flex;
  align-items: flex-end;
}

#block-lermontov-hotel-booking-form-block form > div .form-item {
  display: flex;
  flex-flow: row wrap;
}

#block-lermontov-hotel-booking-form-block form > div .form-item > label {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  line-height: 23px;
  color: #fafafa;
  flex: 1 1 100%;
}

@media only screen and (max-width: 1220px) {
  #block-lermontov-hotel-booking-form-block form > div .form-item > label {
    text-align: center;
  }
}

@media only screen and (max-width: 1220px) {
  #block-lermontov-hotel-booking-form-block form > div .form-item {
    flex: 1 1 100%;
    margin-bottom: 1em;
    justify-content: center;
  }
}

#block-lermontov-hotel-booking-form-block form > div .form-text {
  flex: 0 1 274px;
  width: 274px;
  height: 48px;
  padding-left: 30px;
}

#block-lermontov-hotel-booking-form-block form > div .form-submit {
  height: 60px;
  width: 172px;
  font-size: "Open Sans", Arial, sans-serif;
  font-weight: 600;
  background: #ffcc00;
  color: #252525;
  box-shadow: none;
  border: 0;
}

#block-lermontov-hotel-booking-form-block form > div .form-submit:active {
  background: #515151;
  color: #fafafa;
}

@media only screen and (max-width: 1220px) {
  #block-lermontov-hotel-booking-form-block form > div .form-submit {
    flex: 0 1 auto;
  }
}

@media only screen and (max-width: 1220px) {
  #block-lermontov-hotel-booking-form-block form > div {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
  }
}

@media only screen and (max-width: 1220px) {
  #block-lermontov-hotel-booking-form-block form {
    flex: 1 1 100%;
  }
}

@media only screen and (max-width: 1220px) {
  #block-lermontov-hotel-booking-form-block {
    flex-flow: row wrap;
  }
}

.ui-datepicker .ui-datepicker-month {
  font-weight: 500;
  font-family: "Open Sans", Arial, sans-serif;
}

.ui-datepicker .ui-datepicker-year {
  font-weight: 500;
  font-family: "Open Sans", Arial, sans-serif;
}

.section-booking-check .block-lermontov-hotel {
  max-width: 100%;
  width: 100%;
}

.section-booking-check .booking-heading-text h1 {
  text-align: center;
  text-transform: uppercase;
  font-family: "PlumbCondensed", Arial, sans-serif;
  font-size: 50px;
  font-weight: 400;
}

.view-display-id-shelter_booking_check {
  max-width: 1770px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: content-box;
}

@media only screen and (max-width: 1800px) {
  .view-display-id-shelter_booking_check {
    box-sizing: border-box;
  }
}

.view-display-id-shelter_booking_check .view-content {
  background: #fafafa;
  padding: 20px 20px 45px;
}

.view-display-id-shelter_booking_check .views-row {
  display: flex;
  margin-bottom: 21px;
  background: #fff;
  padding: 35px 72px 40px 42px;
}

.view-display-id-shelter_booking_check .views-row:last-child {
  margin-bottom: 58px;
}

.view-display-id-shelter_booking_check .views-row li {
  list-style: none;
}

.view-display-id-shelter_booking_check .views-row ul {
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 1190px) {
  .view-display-id-shelter_booking_check .views-row {
    flex-flow: row wrap;
    justify-content: center;
    padding: 35px;
  }
}

@media only screen and (max-width: 640px) {
  .view-display-id-shelter_booking_check .views-row {
    padding: 15px;
  }
}

.view-display-id-shelter_booking_check .views-field-field-photo {
  flex: 1 1 390px;
}

.view-display-id-shelter_booking_check .views-field-field-photo .field-content {
  width: 390px;
}

.view-display-id-shelter_booking_check .views-field-field-photo .owl-carousel {
  position: relative;
}

.view-display-id-shelter_booking_check .views-field-field-photo .owl-nav {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
}

.view-display-id-shelter_booking_check .views-field-field-photo .owl-nav .owl-prev, .view-display-id-shelter_booking_check .views-field-field-photo .owl-nav .owl-next {
  float: left;
  position: relative;
  display: block;
  width: 45px;
  height: 45px;
  background-color: #fafafa;
  font-size: 0;
}

.view-display-id-shelter_booking_check .views-field-field-photo .owl-nav .owl-prev:hover, .view-display-id-shelter_booking_check .views-field-field-photo .owl-nav .owl-prev:active, .view-display-id-shelter_booking_check .views-field-field-photo .owl-nav .owl-next:hover, .view-display-id-shelter_booking_check .views-field-field-photo .owl-nav .owl-next:active {
  background-color: #019966;
}

.view-display-id-shelter_booking_check .views-field-field-photo .owl-nav .owl-prev:hover::before, .view-display-id-shelter_booking_check .views-field-field-photo .owl-nav .owl-prev:active::before, .view-display-id-shelter_booking_check .views-field-field-photo .owl-nav .owl-next:hover::before, .view-display-id-shelter_booking_check .views-field-field-photo .owl-nav .owl-next:active::before {
  color: #fafafa;
}

.view-display-id-shelter_booking_check .views-field-field-photo .owl-nav .owl-prev::before, .view-display-id-shelter_booking_check .views-field-field-photo .owl-nav .owl-next::before {
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  transform: translate3d(-50%, -50%, 0);
  content: '';
  font-family: "Font-Awesome", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: #aaaaaa;
}

.view-display-id-shelter_booking_check .views-field-field-photo .owl-nav .owl-next::before {
  content: '';
}

@media only screen and (max-width: 1190px) {
  .view-display-id-shelter_booking_check .views-field-field-photo {
    flex: 0 1 390px;
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 640px) {
  .view-display-id-shelter_booking_check .views-field-field-photo {
    flex: 1 1 260px;
  }
  .view-display-id-shelter_booking_check .views-field-field-photo .field-content {
    width: 260px;
  }
}

@media only screen and (max-width: 360px) {
  .view-display-id-shelter_booking_check .views-field-field-photo {
    flex: 1 1 220px;
  }
  .view-display-id-shelter_booking_check .views-field-field-photo .field-content {
    width: 220px;
  }
}

.view-display-id-shelter_booking_check .views-field-body {
  flex: 1 1 calc(100% - 390px);
  padding-left: 60px;
}

.view-display-id-shelter_booking_check .views-field-body .field-content {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  height: 100%;
}

.view-display-id-shelter_booking_check .views-field-body .field-content .book {
  float: none;
}

@media only screen and (max-width: 1190px) {
  .view-display-id-shelter_booking_check .views-field-body {
    flex: 1 1 100%;
    padding: 0;
  }
}

@media only screen and (max-width: 820px) {
  .view-display-id-shelter_booking_check .views-field-body .field-content {
    flex-flow: row wrap;
  }
}

.view-display-id-shelter_booking_check .mid-col {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding-right: 15px;
}

.view-display-id-shelter_booking_check .mid-col .title {
  flex: 1 1 100%;
  font-family: "PlumbCondensed", Arial, sans-serif;
  font-weight: 400;
  font-size: 32px;
  color: #515151;
  text-transform: uppercase;
}

.view-display-id-shelter_booking_check .mid-col .body {
  flex: 1 1 100%;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #000;
}

.view-display-id-shelter_booking_check .mid-col .options ul {
  display: flex;
  flex-flow: row wrap;
}

.view-display-id-shelter_booking_check .mid-col .options h2 {
  display: none;
}

@media only screen and (max-width: 820px) {
  .view-display-id-shelter_booking_check .mid-col {
    flex: 1 1 100%;
    margin-bottom: 20px;
    padding-right: 0;
  }
}

@media only screen and (max-width: 640px) {
  .view-display-id-shelter_booking_check .mid-col .title {
    margin-bottom: 20px;
  }
  .view-display-id-shelter_booking_check .mid-col .body {
    margin-bottom: 15px;
  }
}

.view-display-id-shelter_booking_check .end-col {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
}

.view-display-id-shelter_booking_check .end-col .custom-prefix-wrapper {
  color: #515151;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 31px;
}

.view-display-id-shelter_booking_check .end-col .custom-value-wrapper {
  color: #515151;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 42px;
}

@media only screen and (max-width: 820px) {
  .view-display-id-shelter_booking_check .end-col {
    flex: 1 1 100%;
  }
}

@media only screen and (max-width: 640px) {
  .view-display-id-shelter_booking_check .end-col .price-wrapper {
    justify-content: center;
  }
}

.pager.pager--infinite-scroll {
  background: #fafafa;
  margin: 0;
  padding-bottom: 45px;
  text-align: center;
}

.pager.pager--infinite-scroll a {
  position: relative;
  color: #2e2e2e;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 17px;
  font-weight: 400;
  padding-right: 30px;
  text-decoration: none;
  text-transform: lowercase;
}

.pager.pager--infinite-scroll a:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transform: translateY(-10%);
  content: '';
  border: 5px solid transparent;
  border-top: 5px solid #2e2e2e;
  right: 0;
}

.breadcrumb {
  padding-left: 10.5%;
  background: #fafafa;
}

.breadcrumb__separator {
  padding: 0 40px 0 13px;
}

.breadcrumb__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.breadcrumb__title {
  display: none;
}

.breadcrumb__item {
  display: inline;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 3em;
  color: #aaaaaa;
}

.breadcrumb__item:last-child {
  color: #019966;
}

.breadcrumb__item:hover {
  color: #2e2e2e;
}

.breadcrumb__item a {
  text-decoration: none;
}

.more-link,
.more-help-link {
  text-align: right;
}

[dir="rtl"] .more-link,
[dir="rtl"] .more-help-link {
  text-align: left;
}

.more-link__help-icon,
.more-help-link a {
  padding: 1px 0 1px 20px;
  background-image: url(../sass/navigation/more-link/more-link__help-icon.svg);
  background-position: 0 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .more-link__help-icon,
[dir="rtl"] .more-help-link a,
.more-help-link [dir="rtl"] a {
  padding: 1px 20px 1px 0;
  background-position: 100% 50%;
}

.nav-menu__item,
.menu__item {
  list-style-image: url(../sass/navigation/nav-menu/leaf.svg);
  list-style-type: square;
}

.nav-menu__item.is-expanded, .nav-menu__item--is-expanded,
.is-expanded.menu__item,
.menu__item.is-expanded {
  list-style-image: url(../sass/navigation/nav-menu/expanded.svg);
  list-style-type: circle;
}

.nav-menu__item.is-collapsed, .nav-menu__item--is-collapsed,
.is-collapsed.menu__item,
.menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed.svg);
  list-style-type: disc;
}

[dir="rtl"] .nav-menu__item.is-collapsed, [dir="rtl"] .nav-menu__item--is-collapsed,
[dir="rtl"] .is-collapsed.menu__item,
[dir="rtl"] .menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed-rtl.svg);
}

.nav-menu__link.is-active, .nav-menu__link--is-active,
.menu a.active {
  color: #000;
}

.navbar,
.main-navigation .links,
.main-navigation .menu {
  margin: 0;
  padding: 0;
  text-align: left;
}

[dir="rtl"] .navbar,
[dir="rtl"] .main-navigation .links,
.main-navigation [dir="rtl"] .links,
[dir="rtl"]
.main-navigation .menu,
.main-navigation [dir="rtl"] .menu {
  text-align: right;
}

.navbar__item,
.navbar li, .main-navigation .links li,
.main-navigation .menu li {
  float: left;
  padding: 0 10px 0 0;
  list-style-type: none;
  list-style-image: none;
}

[dir="rtl"] .navbar__item, [dir="rtl"]
.navbar li, [dir="rtl"] .main-navigation .links li, .main-navigation [dir="rtl"] .links li, [dir="rtl"]
.main-navigation .menu li,
.main-navigation [dir="rtl"] .menu li {
  float: right;
  padding: 0 0 0 10px;
}

.pager {
  clear: both;
  padding: 0;
  text-align: center;
}

@media print {
  .pager {
    display: none;
  }
}

.pager__item,
.pager__current-item,
.pager-current,
.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
}

.pager__current-item,
.pager-current {
  font-weight: bold;
}

.skip-link {
  display: block;
  padding: 2px 0 3px;
  text-align: center;
}

.skip-link:link, .skip-link:visited {
  background-color: #666666;
  color: #fff;
}

.skip-link__wrapper {
  margin: 0;
}

@media print {
  .skip-link__wrapper {
    display: none;
  }
}

.tabs {
  margin: 1.125rem 0 0;
  line-height: 1.875rem;
  border-bottom: 1px solid #cccccc \0/ie;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
}

@media print {
  .tabs {
    display: none;
  }
}

.tabs__tab {
  float: left;
  margin: 0 3px;
  border: 1px solid #cccccc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-color: transparent;
  border-bottom: 0 \0/ie;
  overflow: hidden;
  background: #dddddd;
}

[dir="rtl"] .tabs__tab {
  float: right;
}

.tabs__tab.is-active {
  border-bottom-color: #fff;
}

.tabs__tab-link {
  padding: 0 1.5rem;
  display: block;
  text-decoration: none;
  transition: color .3s, background .3s, border .3s;
  text-shadow: #fff 0 1px 0;
  color: #000;
  background: #dddddd;
  letter-spacing: 1px;
}

.tabs__tab-link:focus, .tabs__tab-link:hover {
  background: #eaeaea;
}

.tabs__tab-link:active, .tabs__tab-link.is-active, .tabs__tab-link--is-active {
  background: #fff;
  text-shadow: none;
}

.tabs--off,
.views-displays .secondary {
  margin: 0;
  border-bottom: 0;
  padding: 0;
  background-image: none;
}

.tabs--secondary {
  margin-top: 1.5rem;
  font-size: 0.875rem;
  background-image: none;
}

.tabs + .tabs--secondary {
  margin-top: 0;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
}

.tabs--secondary .tabs__tab {
  margin: 0.5625rem 0.1875rem;
  border: 0;
  background: transparent;
}

[dir="rtl"] .tabs--secondary .tabs__tab {
  float: right;
}

.tabs--secondary .tabs__tab.is-active {
  border-bottom-color: transparent;
}

.tabs--secondary .tabs__tab-link {
  border: 1px solid #cccccc;
  border-radius: 24px;
  color: #666666;
  background: #f1f1f1;
  letter-spacing: normal;
}

.tabs--secondary .tabs__tab-link:focus, .tabs--secondary .tabs__tab-link:hover {
  color: #333333;
  background: #dddddd;
  border-color: #999999;
}

.tabs--secondary .tabs__tab-link:active, .tabs--secondary .tabs__tab-link.is-active, .tabs--secondary .tabs__tab-link--is-active {
  color: white;
  text-shadow: #333333 0 1px 0;
  background: #666666;
  border-color: black;
}

.autocomplete,
.form-autocomplete {
  background-image: url(../sass/forms/autocomplete/throbber-inactive.png);
  background-position: 100% center;
  background-repeat: no-repeat;
}

[dir="rtl"] .autocomplete,
[dir="rtl"] .form-autocomplete {
  background-position: 0% center;
}

.autocomplete__list-wrapper,
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

.autocomplete__list,
#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.autocomplete__list-item,
#autocomplete li {
  background: #fff;
  color: #000;
  cursor: default;
  white-space: pre;
}

.autocomplete__list-item.is-selected, .autocomplete__list-item--is-selected,
#autocomplete li.is-selected,
#autocomplete .selected {
  background: #0072b9;
  color: #fff;
}

.autocomplete.is-throbbing, .autocomplete--is-throbbing,
.is-throbbing.form-autocomplete,
.form-autocomplete.throbbing {
  background-image: url(../sass/forms/autocomplete/throbber-active.gif);
}

.collapsible-fieldset,
.collapsible {
  position: relative;
}

.collapsible-fieldset__legend,
.collapsible .fieldset-legend {
  display: block;
  padding-left: 15px;
  background-image: url(../sass/forms/collapsible-fieldset/expanded.svg);
  background-position: 4px 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .collapsible-fieldset__legend,
[dir="rtl"] .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .fieldset-legend {
  padding-left: 0;
  padding-right: 15px;
  background-position: right 4px top 50%;
}

.collapsible-fieldset__summary,
.collapsible .fieldset-legend .summary {
  color: #999999;
  font-size: .9em;
  margin-left: .5em;
}

.collapsible-fieldset.is-collapsed, .collapsible-fieldset--is-collapsed,
.is-collapsed.collapsible,
.collapsible.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__wrapper, .collapsible-fieldset--is-collapsed__wrapper, .is-collapsed.collapsible .collapsible-fieldset__wrapper,
.collapsible.collapsed .fieldset-wrapper {
  display: none;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, .collapsible-fieldset--is-collapsed__legend, .is-collapsed.collapsible .collapsible-fieldset__legend,
.collapsible.collapsed .fieldset-legend,
.collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible .collapsible-fieldset.is-collapsed .fieldset-legend,
.is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed.svg);
  background-position: 4px 50%;
}

[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible-fieldset__legend, [dir="rtl"] .collapsible-fieldset--is-collapsed__legend, [dir="rtl"] .is-collapsed.collapsible .collapsible-fieldset__legend,
[dir="rtl"] .collapsible.collapsed .fieldset-legend,
.collapsible.collapsed [dir="rtl"] .fieldset-legend,
[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible .fieldset-legend,
.collapsible [dir="rtl"] .collapsible-fieldset.is-collapsed .fieldset-legend,
[dir="rtl"] .is-collapsed.collapsible .fieldset-legend {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed-rtl.svg);
  background-position: right 4px top 50%;
}

.form-item {
  margin: 1.5rem 0;
}

.form-item__required,
.form-required {
  color: #c00;
}

.form-item__description,
.form-item .description {
  font-size: 0.875rem;
}

.form-item--inline div,
.form-item--inline label, .container-inline .form-item div, .container-inline .form-item label {
  display: inline;
}

.form-item--inline__exception,
.container-inline .fieldset-wrapper {
  display: block;
}

.form-item--tight,
.form-item--radio,
.form-type-radio,
.form-type-checkbox,
.password-parent,
.confirm-parent,
table .form-item {
  margin: 0;
}

.form-item--radio .form-item__label, .form-item--radio__label, .form-type-radio .form-item__label,
.form-type-checkbox .form-item__label,
label.option {
  display: inline;
  font-weight: normal;
}

.form-item--radio .form-item__description, .form-item--radio__description, .form-type-radio .form-item__description,
.form-type-checkbox .form-item__description,
.form-type-radio .description,
.form-type-checkbox .description,
.form-item--radio .form-item .description,
.form-item .form-item--radio .description,
.form-type-radio .form-item .description,
.form-item .form-type-radio .description,
.form-type-checkbox .form-item .description,
.form-item
.form-type-checkbox .description {
  margin-left: 1.4em;
}

.form-item.is-error .form-item__widget, .form-item--is-error__widget,
.form-item.is-error input,
.form-item.is-error textarea,
.form-item.is-error select,
.form-item--is-error input,
.form-item--is-error textarea,
.form-item--is-error select,
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #c00;
}

.form-table__sticky-header,
.sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: #fff;
}

.form-table__sticky-header.is-sticky, .form-table__sticky-header--is-sticky,
.is-sticky.sticky-header {
  visibility: visible;
}

.form-table__header,
.form-table th,
form table th {
  border-bottom: 3px solid #cccccc;
  padding-right: 1em;
  text-align: left;
}

[dir="rtl"] .form-table__header,
[dir="rtl"] .form-table th,
.form-table [dir="rtl"] th,
[dir="rtl"] form table th,
form table [dir="rtl"] th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0;
}

.form-table__body,
.form-table tbody,
form table tbody {
  border-top: 1px solid #cccccc;
}

.form-table__row,
.form-table tbody tr,
form table tbody tr {
  padding: .1em .6em;
  border-bottom: 1px solid #cccccc;
  background-color: #eeeeee;
}

.form-table__row:nth-child(even),
.form-table tbody tr:nth-child(even),
form table tbody tr:nth-child(even) {
  background-color: #fff;
}

.form-table__row.is-active, .form-table__row--is-active,
.form-table tbody tr.is-active,
form table tbody tr.is-active,
td.active {
  background-color: #dddddd;
}

.form-table__row.is-disabled, .form-table__row--is-disabled,
.form-table tbody tr.is-disabled,
form table tbody tr.is-disabled,
td.menu-disabled {
  background: #cccccc;
}

.form-table__row.is-selected, .form-table__row--is-selected,
.form-table tbody tr.is-selected,
form table tbody tr.is-selected,
tr.selected td {
  background: #fffdf0;
}

.form-table__list,
.form-table ul,
form table ul {
  margin: 0;
}

.form-table__narrow-column,
.form-table th.form-table__narrow-column,
form table th.form-table__narrow-column,
td .checkbox,
th .checkbox {
  width: -moz-min-content;
  width: -webkit-min-content;
  text-align: center;
}

.progress-bar,
.progress {
  font-weight: bold;
}

.progress-bar__bar,
.progress .bar {
  border-radius: 3px;
  margin: 0 .2em;
  border: 1px solid #666666;
  background-color: #cccccc;
}

.progress-bar__fill,
.progress .filled {
  height: 1.5em;
  width: 5px;
  background: #0072b9 url(../sass/forms/progress-bar/progress-bar.gif) repeat 0 0;
}

.progress-bar__percentage,
.progress .percentage {
  float: right;
}

[dir="rtl"] .progress-bar__percentage,
[dir="rtl"] .progress .percentage,
.progress [dir="rtl"] .percentage {
  float: left;
}

.progress-bar--inline,
.ajax-progress-bar {
  width: 16em;
  display: inline-block;
}

[dir="rtl"] .progress-bar--inline,
[dir="rtl"] .ajax-progress-bar {
  float: right;
}

.progress-throbber,
.ajax-progress {
  display: inline-block;
}

[dir="rtl"] .progress-throbber,
[dir="rtl"] .ajax-progress {
  float: right;
}

.progress-throbber__widget,
.ajax-progress .throbber {
  background: url(../sass/forms/progress-throbber/progress-throbber.gif) no-repeat 0 -18px transparent;
  float: left;
  height: 15px;
  margin: 2px;
  width: 15px;
}

[dir="rtl"] .progress-throbber__widget,
[dir="rtl"] .ajax-progress .throbber,
.ajax-progress [dir="rtl"] .throbber {
  float: right;
}

.progress-throbber__widget-in-tr,
tr .ajax-progress .throbber {
  margin: 0 2px;
}

.progress-throbber__message,
.ajax-progress .message {
  padding-left: 20px;
}

.resizable-textarea,
.resizable-textarea textarea {
  width: 100%;
  vertical-align: bottom;
}

.resizable-textarea__grippie,
.resizable-textarea .grippie {
  background: url(../sass/forms/resizable-textarea/grippie.png) no-repeat center 2px #eeeeee;
  border: 1px solid #cccccc;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}

.table-drag__wrapper,
body.drag {
  cursor: move;
}

.table-drag__item,
tr.drag {
  background-color: #fffadb;
}

.table-drag__item-previous,
tr.drag-previous {
  background-color: #fff7c2;
}

.table-drag__handle,
.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
  font-size: 12px;
}

[dir="rtl"] .table-drag__handle,
[dir="rtl"] .tabledrag-handle {
  float: right;
  margin-right: -1em;
  margin-left: 0;
}

.table-drag__handle:focus, .table-drag__handle:hover,
.tabledrag-handle:focus,
.tabledrag-handle:hover {
  text-decoration: none;
}

.table-drag__handle-icon,
.tabledrag-handle .handle {
  box-sizing: content-box;
  background: url(../sass/forms/table-drag/handle-icon.png) no-repeat 6px 9px;
  height: 13px;
  margin: -.4em .5em;
  padding: .42em .5em;
  width: 13px;
}

.table-drag__handle.is-hover .table-drag__handle-icon, .table-drag__handle-icon--is-hover, .is-hover.tabledrag-handle .table-drag__handle-icon,
.tabledrag-handle-hover .handle,
.table-drag__handle.is-hover .tabledrag-handle .handle,
.tabledrag-handle .table-drag__handle.is-hover .handle,
.is-hover.tabledrag-handle .handle {
  background-position: 6px -11px;
}

.table-drag__toggle-weight-wrapper,
.tabledrag-toggle-weight-wrapper {
  text-align: right;
}

[dir="rtl"] .table-drag__toggle-weight-wrapper,
[dir="rtl"] .tabledrag-toggle-weight-wrapper {
  text-align: left;
}

.table-drag__toggle-weight,
.tabledrag-toggle-weight {
  font-size: .9em;
}

.table-drag__indentation,
.indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px;
}

[dir="rtl"] .table-drag__indentation,
[dir="rtl"] .indentation {
  float: right;
  margin: -.4em -.4em -.4em .2em;
  padding: .42em .6em .42em 0;
}

.table-drag__tree-child,
.table-drag__tree-child-last,
.tree-child-last,
.table-drag__tree-child-horizontal,
.tree-child-horizontal,
.tree-child {
  background: url(../sass/forms/table-drag/tree-child.png) no-repeat 11px center;
}

[dir="rtl"] .table-drag__tree-child,
[dir="rtl"] .table-drag__tree-child-last,
[dir="rtl"] .tree-child-last,
[dir="rtl"] .table-drag__tree-child-horizontal,
[dir="rtl"] .tree-child-horizontal,
[dir="rtl"] .tree-child {
  background-position: -65px center;
}

.table-drag__tree-child-last,
.tree-child-last {
  background-image: url(../sass/forms/table-drag/tree-child-last.png);
}

.table-drag__tree-child-horizontal,
.tree-child-horizontal {
  background-position: -11px center;
}

* {
  line-height: normal;
}

p {
  margin: 0;
  font-family: "Open Sans", Arial, sans-serif;
  font-style: normal;
  font-size: 15px;
  font-weight: 600;
  color: #515151;
}

@media only screen and (max-width: 1440px) {
  p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1200px) {
  p {
    font-size: 13px;
  }
}

@media only screen and (max-width: 600px) {
  p {
    font-size: 12px;
  }
}

h2 {
  line-height: normal;
}

.front #block-views-contacts-block .block-title-wrapper {
  display: none;
}

.front #flexslider-1 .views-field-field-slder-text-1 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  top: 28%;
  font-family: "PT Serif", Arial, serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  color: #fafafa;
}

.front #flexslider-1 .views-field-field-slder-text-1::before {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  content: '';
  left: 50%;
  bottom: -1.6vh;
  width: 3.9em;
  height: 2px;
  background-color: #fafafa;
}

@media only screen and (max-width: 1089px) {
  .front #flexslider-1 .views-field-field-slder-text-1::before {
    display: none;
  }
}

@media only screen and (max-width: 1610px) {
  .front #flexslider-1 .views-field-field-slder-text-1 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 1400px) {
  .front #flexslider-1 .views-field-field-slder-text-1 {
    top: 39%;
    font-size: 20px;
  }
}

@media only screen and (max-width: 924px) {
  .front #flexslider-1 .views-field-field-slder-text-1 {
    top: 32%;
  }
}

@media only screen and (max-width: 761px) {
  .front #flexslider-1 .views-field-field-slder-text-1 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 635px) {
  .front #flexslider-1 .views-field-field-slder-text-1 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 538px) {
  .front #flexslider-1 .views-field-field-slder-text-1 {
    font-size: 14px;
  }
}

@media only screen and (max-width: 398px) {
  .front #flexslider-1 .views-field-field-slder-text-1 {
    font-size: 12px;
  }
}

@media only screen and (max-width: 600px) {
  .front #flexslider-1 .views-field-field-slder-text-1 {
    display: none;
  }
}

.front #flexslider-1 .views-field-field-slider-text-2 {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  left: 49.8%;
  top: 36%;
  z-index: 10;
  font-family: "PlumbCondensed", Arial, sans-serif;
  font-size: 90px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  color: #fafafa;
}

@media only screen and (max-width: 1610px) {
  .front #flexslider-1 .views-field-field-slider-text-2 {
    font-size: 88px;
  }
}

@media only screen and (max-width: 1510px) {
  .front #flexslider-1 .views-field-field-slider-text-2 {
    top: 37.5%;
  }
}

@media only screen and (max-width: 1400px) {
  .front #flexslider-1 .views-field-field-slider-text-2 {
    top: 48.5%;
    font-size: 84px;
  }
}

@media only screen and (max-width: 1179px) {
  .front #flexslider-1 .views-field-field-slider-text-2 {
    font-size: 70px;
  }
}

@media only screen and (max-width: 984px) {
  .front #flexslider-1 .views-field-field-slider-text-2 {
    font-size: 64px;
  }
}

@media only screen and (max-width: 901px) {
  .front #flexslider-1 .views-field-field-slider-text-2 {
    font-size: 58px;
  }
}

@media only screen and (max-width: 817px) {
  .front #flexslider-1 .views-field-field-slider-text-2 {
    font-size: 54px;
  }
}

@media only screen and (max-width: 761px) {
  .front #flexslider-1 .views-field-field-slider-text-2 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 635px) {
  .front #flexslider-1 .views-field-field-slider-text-2 {
    font-size: 38px;
  }
}

@media only screen and (max-width: 538px) {
  .front #flexslider-1 .views-field-field-slider-text-2 {
    font-size: 32px;
  }
}

@media only screen and (max-width: 454px) {
  .front #flexslider-1 .views-field-field-slider-text-2 {
    font-size: 28px;
  }
}

@media only screen and (max-width: 398px) {
  .front #flexslider-1 .views-field-field-slider-text-2 {
    font-size: 24px;
  }
}

@media only screen and (max-width: 342px) {
  .front #flexslider-1 .views-field-field-slider-text-2 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 600px) {
  .front #flexslider-1 .views-field-field-slider-text-2 {
    display: none;
  }
}

.front #flexslider-1 .flex-prev, .front #flexslider-1 .flex-next {
  top: 42.4%;
}

@media only screen and (max-width: 1400px) {
  .front #flexslider-1 .flex-prev, .front #flexslider-1 .flex-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media only screen and (max-width: 500px) {
  .front #flexslider-1 .flex-prev, .front #flexslider-1 .flex-next {
    top: 60%;
    transform: translateY(-60%);
  }
}

.block__title {
  font-family: "PlumbCondensed", Arial, sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  color: #2e2e2e;
  margin: 0;
}

@media only screen and (max-width: 1610px) {
  .block__title {
    font-size: 56px;
  }
}

@media only screen and (max-width: 1440px) {
  .block__title {
    font-size: 45px;
  }
}

@media only screen and (max-width: 1200px) {
  .block__title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 900px) {
  .block__title {
    font-size: 36px;
  }
}

@media only screen and (max-width: 600px) {
  .block__title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 400px) {
  .block__title {
    font-size: 25px;
  }
}

.region-slider {
  position: relative;
}

.region-slider .flex-control-nav {
  display: none;
}

.region-slider .block-webform {
  position: absolute;
  bottom: 0;
  z-index: 10;
  width: 100%;
  padding: 1.8% 0;
  background: rgba(1, 153, 102, 0.75);
}

@media only screen and (max-width: 1400px) {
  .region-slider .block-webform {
    position: static;
    background: #019966;
  }
}

@media only screen and (max-width: 900px) {
  .region-slider .block-webform {
    padding: 1.8% 0 4%;
  }
}

.region-slider .block-webform #webform-client-form-8 > div {
  max-width: 1146px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;
}

@media only screen and (max-width: 1200px) {
  .region-slider .block-webform #webform-client-form-8 > div {
    max-width: 640px;
    flex-flow: column wrap;
    align-items: center;
  }
}

.region-slider .block-webform .webform-component {
  white-space: nowrap;
}

@media only screen and (max-width: 1200px) {
  .region-slider .block-webform .webform-component {
    flex: 0 1 100%;
    margin-bottom: 10px;
  }
  .region-slider .block-webform .webform-component:last-child {
    margin-bottom: 0;
  }
}

.region-slider .block-webform label {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: #e6e2e3;
  margin-bottom: 4px;
}

.region-slider .block-webform input {
  height: 47px;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: #515151;
  border: 1px solid #e6e2e3;
  background: #fafafa;
}

@media only screen and (max-width: 1200px) {
  .region-slider .block-webform input {
    width: 100%;
  }
}

.region-slider .block-webform .selectBox-dropdown {
  display: block !important;
  min-width: auto;
  width: 181px !important;
  height: 47px;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

@media only screen and (max-width: 1200px) {
  .region-slider .block-webform .selectBox-dropdown {
    width: 100% !important;
  }
}

.region-slider .block-webform .selectBox-dropdown .selectBox-label {
  width: 100% !important;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 2.7em;
  color: #515151;
  background: #fafafa;
  border: 1px solid #e6e2e3;
  box-shadow: none;
}

.region-slider .block-webform .selectBox-dropdown .selectBox-arrow {
  min-width: auto;
  background: url("../img/down.png") no-repeat 50% 50% #fafafa;
  border: 1px solid #e6e2e3;
  border-left: none;
}

.region-slider .block-webform .form-required {
  color: #fafafa;
}

@media only screen and (max-width: 1200px) {
  .region-slider .block-webform .webform-datepicker {
    max-width: none;
    width: 100%;
    margin: 0 auto;
  }
}

.region-slider .block-webform .form-item-submitted-arrival-date-day, .region-slider .block-webform .form-item-submitted-arrival-date-month,
.region-slider .block-webform .form-item-submitted-arrival-date-year, .region-slider .block-webform .form-item-submitted-date-of-departure-day,
.region-slider .block-webform .form-item-submitted-date-of-departure-month, .region-slider .block-webform .form-item-submitted-date-of-departure-year {
  display: none;
}

.region-slider .block-webform .webform-component {
  margin-left: 0;
  margin-right: 7px;
}

.region-slider .block-webform .webform-component:last-child {
  margin-right: 20px;
}

@media only screen and (max-width: 1200px) {
  .region-slider .block-webform .webform-component {
    margin-right: 0;
  }
}

.region-slider .block-webform .webform-component-date, .region-slider .block-webform .webform-calendar {
  position: relative;
}

.region-slider .block-webform .webform-component-date input, .region-slider .block-webform .webform-calendar input {
  padding-left: 40px;
  padding-right: 5px;
}

@media only screen and (max-width: 1200px) {
  .region-slider .block-webform .webform-component-date, .region-slider .block-webform .webform-calendar {
    width: 80%;
  }
}

.region-slider .block-webform .webform-component-date::before, .region-slider .block-webform .webform-calendar::before {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  top: 66%;
  left: 13%;
  z-index: 2;
  font-family: "Font-Awesome", sans-serif;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  color: #2e2e2e;
}

@media only screen and (max-width: 1200px) {
  .region-slider .block-webform .webform-component-date::before, .region-slider .block-webform .webform-calendar::before {
    left: 3%;
  }
}

.region-slider .block-webform .webform-component-date {
  width: 125px;
}

@media only screen and (max-width: 1200px) {
  .region-slider .block-webform .webform-component-date {
    width: 80%;
  }
}

.region-slider .block-webform .webform-calendar {
  width: 127px;
}

@media only screen and (max-width: 1200px) {
  .region-slider .block-webform .webform-calendar {
    width: 100%;
  }
}

.region-slider .block-webform #edit-submitted-your-name, .region-slider .block-webform #edit-submitted-e-mail, .region-slider .block-webform #edit-submitted-phone {
  padding-left: 10px;
}

.region-slider .block-webform .room-wrapper {
  width: 181px;
}

.region-slider .block-webform .room-wrapper select {
  height: 47px;
}

@media only screen and (max-width: 1200px) {
  .region-slider .block-webform .room-wrapper select {
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .region-slider .block-webform .room-wrapper {
    width: 100%;
  }
}

.region-slider .block-webform .room-wrapper .selectBox-label {
  display: flex;
  align-items: center;
  height: 100% !important;
}

@media only screen and (max-width: 1200px) {
  .region-slider .block-webform .room-wrapper {
    width: 80%;
  }
}

.region-slider .block-webform .room-wrapper .selectBox-dropdown-menu:nth-of-type(2) .selectBox-options li a {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #019966;
  background: #fafafa;
}

.region-slider .block-webform .room-wrapper .selectBox-dropdown-menu:nth-of-type(2) .selectBox-options li.selectBox-hover a {
  color: #fafafa;
  background-color: #019966;
}

.region-slider .block-webform .name-wrapper, .region-slider .block-webform .email-wrapper {
  width: 172px;
}

@media only screen and (max-width: 1200px) {
  .region-slider .block-webform .name-wrapper, .region-slider .block-webform .email-wrapper {
    width: 80%;
  }
}

.region-slider .block-webform .webform-component--phone {
  width: 139px;
  margin-right: 20px;
}

@media only screen and (max-width: 1200px) {
  .region-slider .block-webform .webform-component--phone {
    width: 80%;
    margin-right: 0;
  }
}

.region-slider .block-webform .webform-submit {
  height: auto;
  padding: 1.466em 1.4em;
  margin-top: 0.82em;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  color: #2e2e2e;
  background-color: #ffcc00;
  border: none;
  box-shadow: none;
}

.region-slider .block-webform .webform-submit:hover {
  color: #fafafa;
  background-color: #515151;
}

.view-id-history .view-content .views-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 1% 10.365%;
}

@media only screen and (max-width: 900px) {
  .view-id-history .view-content .views-row {
    flex-flow: column nowrap;
    padding: 8% 10.365% 1%;
  }
}

@media only screen and (max-width: 640px) {
  .view-id-history .view-content .views-row {
    max-width: 100%;
    margin: 0 auto;
  }
}

.view-id-history .view-content .views-row .views-field-field-image {
  flex: 0 1 41.8%;
}

@media only screen and (max-width: 740px) {
  .view-id-history .view-content .views-row .views-field-field-image {
    flex: 0 1 100%;
  }
}

.view-id-history .view-content .views-row .views-field-body {
  flex: 0 1 50%;
}

@media only screen and (max-width: 740px) {
  .view-id-history .view-content .views-row .views-field-body {
    flex: 0 1 100%;
  }
}

.view-id-history .view-content .views-row .views-field-field-image img {
  width: 100%;
}

.view-id-history .view-content .views-row .views-field-body .field-content {
  position: relative;
  margin-top: 13.69%;
  font-family: "PlumbCondensed", Arial, sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  color: black;
}

@media only screen and (max-width: 1610px) {
  .view-id-history .view-content .views-row .views-field-body .field-content {
    font-size: 56px;
  }
}

@media only screen and (max-width: 1440px) {
  .view-id-history .view-content .views-row .views-field-body .field-content {
    font-size: 50px;
  }
}

@media only screen and (max-width: 1200px) {
  .view-id-history .view-content .views-row .views-field-body .field-content {
    font-size: 45px;
  }
}

@media only screen and (max-width: 900px) {
  .view-id-history .view-content .views-row .views-field-body .field-content {
    font-size: 40px;
  }
}

@media only screen and (max-width: 600px) {
  .view-id-history .view-content .views-row .views-field-body .field-content {
    font-size: 36px;
  }
}

@media only screen and (max-width: 550px) {
  .view-id-history .view-content .views-row .views-field-body .field-content {
    margin-top: 20.69%;
  }
}

@media only screen and (max-width: 400px) {
  .view-id-history .view-content .views-row .views-field-body .field-content {
    font-size: 30px;
  }
}

.view-id-history .view-content .views-row .views-field-body .field-content::before {
  content: "  ";
  position: absolute;
  top: -36px;
  left: 0;
  font-family: "Font-Awesome", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.2em;
  color: #aaaaaa;
}

@media only screen and (max-width: 550px) {
  .view-id-history .view-content .views-row .views-field-body .field-content::before {
    font-size: 13px;
  }
}

.view-id-history .view-content .views-row .views-field-body .field-content p {
  margin: 8% 0 0 0;
  text-transform: none;
}

.view-id-history .view-content .views-row .views-field-body .field-content p::before {
  content: '';
  position: absolute;
  left: 0;
  top: 90px;
  width: 8.2em;
  height: 2px;
  background-color: #aaaaaa;
}

@media only screen and (max-width: 1600) {
  .view-id-history .view-content .views-row .views-field-body .field-content p::before {
    top: 85px;
  }
}

@media only screen and (max-width: 1440px) {
  .view-id-history .view-content .views-row .views-field-body .field-content p::before {
    top: 75px;
  }
}

@media only screen and (max-width: 1200px) {
  .view-id-history .view-content .views-row .views-field-body .field-content p::before {
    top: 60px;
  }
}

@media only screen and (max-width: 900px) {
  .view-id-history .view-content .views-row .views-field-body .field-content p::before {
    top: 65px;
  }
}

@media only screen and (max-width: 600px) {
  .view-id-history .view-content .views-row .views-field-body .field-content p::before {
    top: 60px;
    width: 6em;
  }
}

@media only screen and (max-width: 520px) {
  .view-id-history .view-content .views-row .views-field-body .field-content p::before {
    top: 55px;
  }
}

@media only screen and (max-width: 420px) {
  .view-id-history .view-content .views-row .views-field-body .field-content p::before {
    top: 50px;
  }
}

@media only screen and (max-width: 400px) {
  .view-id-history .view-content .views-row .views-field-body .field-content p::before {
    top: 43px;
  }
}

#block-views-rooms-block {
  padding: 0 10.365% 19px;
}

@media only screen and (max-width: 640px) {
  #block-views-rooms-block {
    max-width: 100%;
    padding: 1.8% 15px;
    margin: 0 auto;
  }
}

#block-views-rooms-block .block-title-wrapper {
  margin: 73px 0 11px;
  padding: 0;
}

@media only screen and (max-width: 640px) {
  #block-views-rooms-block .block-title-wrapper {
    margin: 0 0 11px;
  }
}

#block-views-rooms-block .block__title {
  text-align: left;
}

#block-views-rooms-block .field-content {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: flex-start;
}

@media only screen and (max-width: 950px) {
  #block-views-rooms-block .field-content {
    align-items: center;
  }
}

#block-views-rooms-block .view-header {
  margin-bottom: 30px;
}

#block-views-rooms-block .view-header .views-field-body {
  max-width: 740px;
}

#block-views-rooms-block .view-header .view-content .views-row {
  margin-bottom: 0;
}

#block-views-rooms-block .view-content:after {
  content: "";
  display: table;
  clear: both;
}

#block-views-rooms-block .view-content .views-row {
  float: left;
  width: 49%;
  margin-bottom: 63px;
}

#block-views-rooms-block .view-content .views-row:after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (max-width: 1300px) {
  #block-views-rooms-block .view-content .views-row {
    width: 100%;
  }
}

#block-views-rooms-block .view-content .views-row:nth-child(odd) {
  margin-right: 1.446%;
}

#block-views-rooms-block .view-content .views-row .field-slideshow-slide {
  position: relative;
}

#block-views-rooms-block .view-content .views-row .colorbox::before {
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  transform: translate3d(-50%, -50%, 0);
  z-index: 3;
  content: '';
  width: 100%;
  height: 100%;
  font-family: "Font-Awesome", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  background: url("../img/search.png") no-repeat 50% 50% rgba(1, 1, 7, 0.5);
  opacity: 0;
  transition: 0.5s all;
}

#block-views-rooms-block .view-content .views-row .colorbox:hover::before {
  opacity: 1;
  transition: 0.3s opacity;
}

#block-views-rooms-block .view-content .views-row .views-field-field-photo {
  float: left;
  width: 487px;
  margin-right: 5.965%;
}

@media only screen and (max-width: 1885px) {
  #block-views-rooms-block .view-content .views-row .views-field-field-photo {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 1300px) {
  #block-views-rooms-block .view-content .views-row .views-field-field-photo {
    width: 487px;
  }
}

@media only screen and (max-width: 950px) {
  #block-views-rooms-block .view-content .views-row .views-field-field-photo {
    width: 490px;
    float: none;
    margin: 0 auto 30px;
  }
}

@media only screen and (max-width: 540px) {
  #block-views-rooms-block .view-content .views-row .views-field-field-photo {
    max-width: 100%;
  }
}

#block-views-rooms-block .view-content .views-row .views-field-field-photo img {
  vertical-align: bottom;
}

#block-views-rooms-block .view-content .views-row .views-field-body {
  float: left;
  width: calc(100% - 487px - 5.965%);
  padding: 6px 0;
}

@media only screen and (max-width: 1885px) {
  #block-views-rooms-block .view-content .views-row .views-field-body {
    width: 487px;
  }
}

@media only screen and (max-width: 1300px) {
  #block-views-rooms-block .view-content .views-row .views-field-body {
    width: calc(100% - 550px);
  }
}

@media only screen and (max-width: 950px) {
  #block-views-rooms-block .view-content .views-row .views-field-body {
    width: 100%;
  }
}

@media only screen and (max-width: 1162px) {
  #block-views-rooms-block .view-content {
    flex-flow: column wrap;
    justify-content: center;
  }
}

#block-views-rooms-block .title {
  display: block;
  margin-bottom: 21px;
  font-family: "PlumbCondensed", Arial, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  color: #515151;
}

#block-views-rooms-block .body {
  max-width: 205px;
  margin-bottom: 20px;
  margin-left: 2.5%;
}

@media only screen and (max-width: 1885px) {
  #block-views-rooms-block .body {
    max-width: 470px;
    margin-left: 0;
  }
}

#block-views-rooms-block .link a {
  display: block;
  padding: 1.2em 4em;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  text-transform: lowercase;
  text-decoration: none;
  color: black;
  border: 2px solid #2e2e2e;
  text-align: center;
}

#block-views-rooms-block .link a:hover {
  color: #019966;
  border-color: #019966;
}

#block-views-rooms-block .item-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

#block-views-rooms-block .field-slideshow-wrapper {
  position: relative;
}

#block-views-rooms-block .field-slideshow-controls {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
}

#block-views-rooms-block .field-slideshow-controls:after {
  content: "";
  display: table;
  clear: both;
}

#block-views-rooms-block .field-slideshow-controls .prev, #block-views-rooms-block .field-slideshow-controls .next {
  float: left;
  position: relative;
  display: block;
  width: 45px;
  height: 45px;
  background-color: #fafafa;
  font-size: 0;
}

#block-views-rooms-block .field-slideshow-controls .prev:hover, #block-views-rooms-block .field-slideshow-controls .prev:active, #block-views-rooms-block .field-slideshow-controls .next:hover, #block-views-rooms-block .field-slideshow-controls .next:active {
  background-color: #019966;
}

#block-views-rooms-block .field-slideshow-controls .prev:hover::before, #block-views-rooms-block .field-slideshow-controls .prev:active::before, #block-views-rooms-block .field-slideshow-controls .next:hover::before, #block-views-rooms-block .field-slideshow-controls .next:active::before {
  color: #fafafa;
}

#block-views-rooms-block .field-slideshow-controls .prev::before, #block-views-rooms-block .field-slideshow-controls .next::before {
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  transform: translate3d(-50%, -50%, 0);
  content: '';
  font-family: "Font-Awesome", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: #aaaaaa;
}

#block-views-rooms-block .field-slideshow-controls .next::before {
  content: '';
}

#block-views-rooms-block .field-slideshow {
  padding: 0;
}

@media only screen and (max-width: 640px) {
  #block-views-rooms-block .field-slideshow {
    width: 490px !important;
    height: 335px !important;
  }
}

@media only screen and (max-width: 540px) {
  #block-views-rooms-block .field-slideshow {
    width: 490px !important;
    height: 335px !important;
  }
}

@media only screen and (max-width: 520px) {
  #block-views-rooms-block .field-slideshow {
    width: 470px !important;
    height: 321px !important;
  }
}

@media only screen and (max-width: 500px) {
  #block-views-rooms-block .field-slideshow {
    width: 450px !important;
    height: 308px !important;
  }
}

@media only screen and (max-width: 460px) {
  #block-views-rooms-block .field-slideshow {
    width: 400px !important;
    height: 273px !important;
  }
}

@media only screen and (max-width: 430px) {
  #block-views-rooms-block .field-slideshow {
    width: 350px !important;
    height: 239px !important;
  }
}

@media only screen and (max-width: 380px) {
  #block-views-rooms-block .field-slideshow {
    width: 320px !important;
    height: 219px !important;
  }
}

@media only screen and (max-width: 350px) {
  #block-views-rooms-block .field-slideshow {
    width: 280px !important;
    height: 191px !important;
  }
}

#block-views-additional-services-block {
  padding: 1.8% 10.365% 2%;
  text-align: center;
  background-color: #019966;
}

@media only screen and (max-width: 640px) {
  #block-views-additional-services-block {
    padding: 5% 10.365%;
  }
}

#block-views-additional-services-block .block-title-wrapper {
  margin: 0 0 1.7% 0;
}

@media only screen and (max-width: 640px) {
  #block-views-additional-services-block .block-title-wrapper {
    margin-bottom: 5.7%;
  }
}

#block-views-additional-services-block .block__title {
  margin: 0;
  color: #fafafa;
}

#block-views-additional-services-block .view-additional-services .view-content {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: baseline;
}

#block-views-additional-services-block .view-additional-services .view-content .views-row {
  flex: 0 1 15%;
}

@media only screen and (max-width: 1240px) {
  #block-views-additional-services-block .view-additional-services .view-content .views-row {
    flex: 0 1 20%;
  }
}

@media only screen and (max-width: 900px) {
  #block-views-additional-services-block .view-additional-services .view-content .views-row {
    flex: 0 1 25%;
  }
}

@media only screen and (max-width: 640px) {
  #block-views-additional-services-block .view-additional-services .view-content .views-row {
    flex: 0 1 33.3%;
  }
  #block-views-additional-services-block .view-additional-services .view-content .views-row:last-child {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 540px) {
  #block-views-additional-services-block .view-additional-services .view-content .views-row {
    flex: 0 1 50%;
    padding: 0 10px;
  }
  #block-views-additional-services-block .view-additional-services .view-content .views-row:nth-child(3n) {
    margin-top: 20px;
  }
}

#block-views-additional-services-block .view-additional-services .view-content .views-field-title {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  color: #fafafa;
}

@media only screen and (max-width: 1240px) {
  #block-views-additional-services-block .view-additional-services .view-content .views-field-title {
    font-size: 15px;
  }
}

@media only screen and (max-width: 640px) {
  #block-views-additional-services-block .view-additional-services .view-content .views-field-title {
    font-size: 14px;
  }
}

#block-views-additional-services-block .views-field-field-icon {
  margin-bottom: 3%;
}

#block-views-testimonials-block {
  padding: 3.8% 9.065%;
  text-align: center;
}

@media only screen and (max-width: 640px) {
  #block-views-testimonials-block {
    max-width: 100%;
    padding: 10% 15px;
    margin: 0 auto;
  }
}

#block-views-testimonials-block .block__title {
  color: black;
}

#block-views-testimonials-block .block-title-wrapper {
  margin: 0 0 59px 0;
}

@media only screen and (max-width: 640px) {
  #block-views-testimonials-block .block-title-wrapper {
    margin: 0 0 39px 0;
  }
}

#block-views-testimonials-block .view-testimonials .views-field-body {
  margin-bottom: 38px;
}

@media only screen and (max-width: 900px) {
  #block-views-testimonials-block .view-testimonials .views-field-body {
    margin-bottom: 28px;
  }
}

#block-views-testimonials-block .view-testimonials .view-content {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

@media only screen and (max-width: 820px) {
  #block-views-testimonials-block .view-testimonials .view-content {
    flex-flow: column nowrap;
  }
}

#block-views-testimonials-block .view-testimonials .view-content .views-row {
  flex: 0 1 33.3%;
}

#block-views-testimonials-block .view-testimonials .view-content .views-row:after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (max-width: 820px) {
  #block-views-testimonials-block .view-testimonials .view-content .views-row {
    flex: 0 1 100%;
  }
  #block-views-testimonials-block .view-testimonials .view-content .views-row:nth-child(even) {
    margin: 50px 0 50px;
  }
}

#block-views-testimonials-block .view-testimonials .views-field-field-who .field-content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

#block-views-testimonials-block .view-testimonials .field-content {
  padding: 0 18%;
}

@media only screen and (max-width: 900px) {
  #block-views-testimonials-block .view-testimonials .field-content {
    padding: 0 5%;
  }
}

#block-views-testimonials-block .view-testimonials .image-wrapper {
  margin-right: 7%;
}

#block-views-testimonials-block .view-testimonials .text-wrapper {
  font-family: "PlumbCondensed", Arial, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  text-align: left;
  color: #000000;
}

#block-views-testimonials-block .view-testimonials .text-wrapper span {
  display: block;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-weight: 600;
  text-transform: none;
  color: #515151;
}

#block-views-contacts-block {
  padding: 1.8% 10.065%;
}

@media only screen and (max-width: 640px) {
  #block-views-contacts-block {
    max-width: 100%;
    padding: 1.8% 15px;
    margin: 0 auto;
  }
}

#block-views-contacts-block .block__title {
  color: black;
}

#block-views-contacts-block .view-contacts {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  #block-views-contacts-block .view-contacts {
    flex-flow: row wrap;
  }
}

#block-views-contacts-block .view-header {
  flex: 0 1 60%;
  height: 382px !important;
}

@media only screen and (max-width: 1200px) {
  #block-views-contacts-block .view-header {
    flex: 0 1 100%;
  }
}

#block-views-contacts-block .view-content {
  flex: 0 1 32%;
}

@media only screen and (max-width: 1195px) {
  #block-views-contacts-block .view-content {
    padding-top: 25px;
  }
}

@media only screen and (max-width: 1200px) {
  #block-views-contacts-block .view-content {
    flex: 0 1 100%;
    text-align: center;
  }
}

#block-views-contacts-block .view-content .views-field-title {
  font-family: "PlumbCondensed", Arial, sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  color: black;
  text-transform: uppercase;
  margin-bottom: 13px;
}

@media only screen and (max-width: 1610px) {
  #block-views-contacts-block .view-content .views-field-title {
    font-size: 56px;
  }
}

@media only screen and (max-width: 1440px) {
  #block-views-contacts-block .view-content .views-field-title {
    font-size: 50px;
  }
}

@media only screen and (max-width: 1195px) {
  #block-views-contacts-block .view-content .views-field-title {
    display: none;
  }
}

#block-views-contacts-block .view-content .views-field-body {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  color: #515151;
}

#block-views-contacts-block .view-content .views-field-body p:first-child {
  margin-bottom: 13.45%;
}

#block-views-contacts-block .view-content .custom-title {
  display: block;
  font-family: "PlumbCondensed", Arial, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  color: black;
  margin-bottom: 13px;
}

#block-views-contacts-block .view-content .email {
  display: block;
  margin-bottom: 22px;
}

#block-views-contacts-block .view-content .email a {
  color: #019966;
  text-decoration: none;
}

#block-views-contacts-block .view-content .email:hover {
  color: #515151;
}

#block-views-contacts-block .view-content .address {
  margin-bottom: 22px;
}

#block-views-contacts-block .view-content .phone {
  display: block;
  font-family: "PlumbCondensed", Arial, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  color: black;
}

#first-time {
  display: none;
}

.selectBox-options-bottom {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  box-shadow: none;
  border: none;
}

.form-item {
  margin: 0;
}

#flexslider-1 {
  border: none;
}

@media only screen and (max-width: 1400px) {
  #flexslider-1 {
    margin: 0;
  }
}

#flexslider-1 .views-field-field-img {
  position: relative;
}

#flexslider-1 .views-field-field-img::before {
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  transform: translate3d(-50%, -50%, 0);
  content: '';
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: rgba(110, 103, 83, 0.15);
}

#flexslider-1 .flex-control-nav, #flexslider-1 .flex-control-paging {
  display: block;
}

#flexslider-1 .flex-prev {
  top: 50%;
  left: 10.4vw;
  display: block;
  width: 1.5em;
  height: 1.8em;
  opacity: 1;
}

@media only screen and (max-width: 900px) {
  #flexslider-1 .flex-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

#flexslider-1 .flex-prev::before {
  content: '';
  font-family: "Font-Awesome", sans-serif;
  font-size: 30.31px;
  font-style: normal;
  font-weight: 400;
  color: #fafafa;
}

@media only screen and (max-width: 930px) {
  #flexslider-1 .flex-prev::before {
    font-size: 26.31px;
  }
}

@media only screen and (max-width: 640px) {
  #flexslider-1 .flex-prev::before {
    font-size: 22.31px;
  }
}

@media only screen and (max-width: 440px) {
  #flexslider-1 .flex-prev::before {
    font-size: 18.31px;
  }
}

@media only screen and (max-width: 930px) {
  #flexslider-1 .flex-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media only screen and (max-width: 641px) {
  #flexslider-1 .flex-prev {
    height: 1.55em;
  }
}

@media only screen and (max-width: 440px) {
  #flexslider-1 .flex-prev {
    height: 1.2em;
  }
}

#flexslider-1 .flex-next {
  top: 50%;
  right: 10.4vw;
  display: block;
  width: 1.5em;
  height: 1.8em;
  opacity: 1;
}

#flexslider-1 .flex-next::before {
  content: '';
  font-family: "Font-Awesome", sans-serif;
  font-size: 30.31px;
  font-style: normal;
  font-weight: 400;
  color: #fafafa;
}

@media only screen and (max-width: 930px) {
  #flexslider-1 .flex-next::before {
    font-size: 26.31px;
  }
}

@media only screen and (max-width: 640px) {
  #flexslider-1 .flex-next::before {
    font-size: 22.31px;
  }
}

@media only screen and (max-width: 440px) {
  #flexslider-1 .flex-next::before {
    font-size: 20.31px;
  }
}

@media only screen and (max-width: 930px) {
  #flexslider-1 .flex-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

@media only screen and (max-width: 641px) {
  #flexslider-1 .flex-next {
    height: 1.55em;
  }
}

@media only screen and (max-width: 440px) {
  #flexslider-1 .flex-next {
    height: 1.2em;
  }
}

#flexslider-1 .flex-control-nav, #flexslider-1 .flex-control-paging {
  display: none;
}

#flexslider-2 {
  border: none;
  margin: 60px 0;
}

#flexslider-2 .views-field-field-img {
  position: relative;
}

#flexslider-2 .views-field-field-img::before {
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  transform: translate3d(-50%, -50%, 0);
  content: '';
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: rgba(110, 103, 83, 0.15);
}

#flexslider-2 .slides li {
  position: relative;
}

#flexslider-2 .flex-control-nav, #flexslider-2 .flex-control-paging {
  display: block;
}

#flexslider-2 .flex-prev {
  display: none;
}

#flexslider-2 .flex-next {
  top: 50%;
  right: 10.4vw;
  display: block;
  width: 1.5em;
  height: 1.8em;
  opacity: 1;
}

@media only screen and (max-width: 1600px) {
  #flexslider-2 .flex-next {
    top: 55%;
    transform: translateX(-55%);
  }
}

@media only screen and (max-width: 1600px) and (max-width: 640px) {
  #flexslider-2 .flex-next {
    height: 1.5em;
  }
}

#flexslider-2 .flex-next::before {
  content: '';
  font-family: "Font-Awesome", sans-serif;
  font-size: 30.31px;
  font-style: normal;
  font-weight: 400;
  color: #fafafa;
  transform: rotate(180deg);
}

@media only screen and (max-width: 930px) {
  #flexslider-2 .flex-next::before {
    font-size: 26.31px;
  }
}

@media only screen and (max-width: 640px) {
  #flexslider-2 .flex-next::before {
    font-size: 22.31px;
  }
}

@media only screen and (max-width: 440px) {
  #flexslider-2 .flex-next::before {
    font-size: 20.31px;
  }
}

#flexslider-2 .flex-control-nav, #flexslider-2 .flex-control-paging {
  display: none;
}

#flexslider-2 .views-field-title {
  max-width: 60%;
  position: absolute;
  top: 26%;
  left: 10.5%;
}

#flexslider-2 .views-field-title span {
  font-family: "PlumbCondensed", Arial, sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.13;
  color: #fff;
  text-transform: uppercase;
}

@media only screen and (max-width: 1720px) {
  #flexslider-2 .views-field-title span {
    font-size: 50px;
  }
}

@media only screen and (max-width: 1500px) {
  #flexslider-2 .views-field-title span {
    font-size: 40px;
  }
}

@media only screen and (max-width: 1320px) {
  #flexslider-2 .views-field-title span {
    font-size: 35px;
  }
}

@media only screen and (max-width: 1225px) {
  #flexslider-2 .views-field-title span {
    font-size: 30px;
  }
}

@media only screen and (max-width: 640px) {
  #flexslider-2 .views-field-title span {
    font-size: 25px;
  }
}

@media only screen and (max-width: 550px) {
  #flexslider-2 .views-field-title span {
    font-size: 20px;
  }
}

@media only screen and (max-width: 460px) {
  #flexslider-2 .views-field-title span {
    font-size: 16px;
  }
}

#flexslider-2 .views-field-body {
  max-width: 40.82%;
  position: absolute;
  top: 52%;
  left: 10.6%;
}

@media only screen and (max-width: 1821px) {
  #flexslider-2 .views-field-body {
    max-width: 50%;
  }
}

#flexslider-2 .views-field-body p {
  color: #fff;
}

@media only screen and (max-width: 1470px) {
  #flexslider-2 .views-field-body p {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1271px) {
  #flexslider-2 .views-field-body p {
    display: none;
  }
}

#flexslider-2 .views-field-field-link {
  position: absolute;
  top: 73.2%;
  left: 10.8%;
}

#flexslider-2 .views-field-field-link a {
  padding: 1.2em 2.5em;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 15px;
  color: #2e2e2e;
  background: #ffcc00;
  text-decoration: none;
  text-transform: uppercase;
}

@media only screen and (max-width: 1500px) {
  #flexslider-2 .views-field-field-link a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1225px) {
  #flexslider-2 .views-field-field-link a {
    font-size: 13px;
  }
}

@media only screen and (max-width: 600px) {
  #flexslider-2 .views-field-field-link a {
    padding: 0.7em 1em;
    font-size: 12px;
  }
}

@media only screen and (max-width: 440px) {
  #flexslider-2 .views-field-field-link a {
    font-size: 10px;
  }
}

#flexslider-2 .views-field-field-link a:hover {
  color: #ffcc00;
  background: transparent;
  border: 2px solid #ffcc00;
}

.price-wrapper {
  display: flex;
  margin-bottom: 22px;
  padding-left: 0;
}

.price-wrapper .custom-value-wrapper {
  display: block;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  color: #515151;
}

@media only screen and (max-width: 1610px) {
  .price-wrapper .custom-value-wrapper {
    font-size: 28px;
  }
}

@media only screen and (max-width: 1440px) {
  .price-wrapper .custom-value-wrapper {
    font-size: 22px;
  }
}

@media only screen and (max-width: 1200px) {
  .price-wrapper .custom-value-wrapper {
    font-size: 20px;
  }
}

@media only screen and (max-width: 900px) {
  .price-wrapper .custom-value-wrapper {
    font-size: 18px;
  }
}

@media only screen and (max-width: 600px) {
  .price-wrapper .custom-value-wrapper {
    font-size: 16px;
  }
}

@media only screen and (max-width: 400px) {
  .price-wrapper .custom-value-wrapper {
    font-size: 14px;
  }
}

.price-wrapper .custom-suffix-wrapper sup {
  font-family: "ALSRubl", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #515151;
  top: 0.54em;
}

@media only screen and (max-width: 1440px) {
  .price-wrapper .custom-suffix-wrapper sup {
    top: auto;
  }
}

@media only screen and (max-width: 1200px) {
  .price-wrapper .custom-suffix-wrapper sup {
    font-size: 13px;
  }
}

@media only screen and (max-width: 900px) {
  .price-wrapper .custom-suffix-wrapper sup {
    font-size: 12px;
  }
}

@media only screen and (max-width: 600px) {
  .price-wrapper .custom-suffix-wrapper sup {
    font-size: 10px;
    vertical-align: super;
  }
}

@media only screen and (max-width: 400px) {
  .price-wrapper .custom-suffix-wrapper sup {
    font-size: 9px;
  }
}

.price-wrapper .custom-suffix-wrapper sub {
  bottom: auto;
  top: 0.3em;
  font-family: "Myriad-Pro", Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #515151;
  vertical-align: bottom;
}

@media only screen and (max-width: 1440px) {
  .price-wrapper .custom-suffix-wrapper sub {
    top: auto;
  }
}

@media only screen and (max-width: 1200px) {
  .price-wrapper .custom-suffix-wrapper sub {
    font-size: 15px;
  }
}

@media only screen and (max-width: 900px) {
  .price-wrapper .custom-suffix-wrapper sub {
    font-size: 14px;
  }
}

@media only screen and (max-width: 600px) {
  .price-wrapper .custom-suffix-wrapper sub {
    font-size: 13px;
    vertical-align: baseline;
  }
}

@media only screen and (max-width: 400px) {
  .price-wrapper .custom-suffix-wrapper sub {
    font-size: 12px;
  }
}

.lermontov-navigation {
  display: inline-block;
  width: 51px;
  height: 51px;
  background: #019966;
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 10;
  cursor: pointer;
}

.lermontov-navigation::before {
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  transform: translate3d(-50%, -50%, 0);
  content: '';
  font-family: "Font-Awesome", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  color: #fffefe;
}

@media only screen and (max-width: 1200px) {
  .lermontov-navigation::before {
    font-size: 18px;
  }
}

@media only screen and (max-width: 900px) {
  .lermontov-navigation::before {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1200px) {
  .lermontov-navigation {
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 900px) {
  .lermontov-navigation {
    right: 30px;
    bottom: 30px;
    width: 35px;
    height: 35px;
  }
}

.class-view-contacts {
  padding: 1.8% 10.065%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

@media only screen and (max-width: 1200px) {
  .class-view-contacts {
    flex-flow: row wrap;
  }
}

@media only screen and (max-width: 640px) {
  .class-view-contacts {
    max-width: 100%;
    padding: 1.8% 15px;
    margin: 0 auto;
  }
}

.class-view-contacts .block__title {
  color: black;
}

.class-view-contacts .view-header {
  flex: 0 1 60%;
  height: 382px !important;
}

@media only screen and (max-width: 1200px) {
  .class-view-contacts .view-header {
    flex: 0 1 100%;
  }
}

.class-view-contacts .view-content {
  flex: 0 1 32%;
}

@media only screen and (max-width: 1195px) {
  .class-view-contacts .view-content {
    padding-top: 25px;
  }
}

@media only screen and (max-width: 1200px) {
  .class-view-contacts .view-content {
    flex: 0 1 100%;
    text-align: center;
  }
}

.class-view-contacts .view-content .views-field-title {
  font-family: "PlumbCondensed", Arial, sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  color: black;
  text-transform: uppercase;
  margin-bottom: 13px;
}

@media only screen and (max-width: 1610px) {
  .class-view-contacts .view-content .views-field-title {
    font-size: 56px;
  }
}

@media only screen and (max-width: 1440px) {
  .class-view-contacts .view-content .views-field-title {
    font-size: 50px;
  }
}

@media only screen and (max-width: 1195px) {
  .class-view-contacts .view-content .views-field-title {
    display: none;
  }
}

.class-view-contacts .view-content .views-field-body {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  color: #515151;
}

.class-view-contacts .view-content .views-field-body p:first-child {
  margin-bottom: 1em;
}

.class-view-contacts .view-content .custom-title {
  display: block;
  font-family: "PlumbCondensed", Arial, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  color: black;
  margin-bottom: 13px;
}

.class-view-contacts .view-content .email {
  display: block;
  margin-bottom: 22px;
}

.class-view-contacts .view-content .email a {
  color: #019966;
  text-decoration: none;
}

.class-view-contacts .view-content .email:hover {
  color: #515151;
}

.class-view-contacts .view-content .address {
  margin-bottom: 22px;
}

.class-view-contacts .view-content .phone {
  display: block;
  font-family: "PlumbCondensed", Arial, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  color: black;
}

.section-booking .some-content-class {
  background-color: #fafafa;
}

.region-highlighted {
  max-width: 1032px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: content-box;
  background-color: #fafafa;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-flow: row wrap;
}

@media only screen and (max-width: 1062px) {
  .region-highlighted {
    box-sizing: border-box;
  }
}

.region-highlighted .field-slideshow-controls {
  display: none;
}

#block-webform-client-block-20 {
  background-color: #fff;
  flex: 0 1 61.14%;
  margin-bottom: 49px;
}

@media only screen and (max-width: 1032px) {
  #block-webform-client-block-20 {
    flex: 1 1 100%;
    padding: 0 15px;
  }
}

#block-webform-client-block-20 .webform-client-form {
  max-width: 790px;
  margin: 0 auto;
  padding: 44px 41px 65px 44px;
  background-color: #fff;
}

@media only screen and (max-width: 1032px) {
  #block-webform-client-block-20 .webform-client-form {
    padding: 10px 0;
  }
}

#block-webform-client-block-20 .webform-client-form div:after {
  content: "";
  display: table;
  clear: both;
}

#block-webform-client-block-20 .webform-component {
  margin-bottom: 14px;
}

#block-webform-client-block-20 label {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  color: #515151;
}

#block-webform-client-block-20 label span {
  font-size: 17px;
  color: #515151;
}

#block-webform-client-block-20 input, #block-webform-client-block-20 textarea {
  width: 100%;
  padding-left: 22px;
  height: 49px;
  border: 2px solid #e6e2e3;
}

@media only screen and (max-width: 569px) {
  #block-webform-client-block-20 input, #block-webform-client-block-20 textarea {
    height: 40px;
  }
}

#block-webform-client-block-20 input::placeholder, #block-webform-client-block-20 textarea::placeholder {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: #aaaaaa;
}

@media only screen and (max-width: 569px) {
  #block-webform-client-block-20 input::placeholder, #block-webform-client-block-20 textarea::placeholder {
    font-size: 14px;
  }
}

#block-webform-client-block-20 .phone input {
  padding-left: 68px;
}

#block-webform-client-block-20 textarea {
  height: auto;
}

#block-webform-client-block-20 .type-room {
  width: 100% !important;
  min-width: 0;
  height: 56px;
  padding-left: 22px;
  background: #fff;
  border: 2px solid #e6e2e3;
}

#block-webform-client-block-20 .type-room .selectBox-label {
  width: 100% !important;
  padding: 1% 0 0 0;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 2.7em;
  color: #aaaaaa;
  background: #fff;
  border: none;
}

#block-webform-client-block-20 .type-room .selectBox-arrow {
  width: 10%;
  top: 0;
  right: 0;
  background: url("../img/down_2.png") no-repeat 50% 50% #fff;
  border-left: none;
}

#block-webform-client-block-20 .webform-component-email, #block-webform-client-block-20 .phone, #block-webform-client-block-20 .fullname, #block-webform-client-block-20 .webform-component--notes {
  float: left;
  width: 100%;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: #aaaaaa;
}

@media only screen and (max-width: 569px) {
  #block-webform-client-block-20 .webform-component-email, #block-webform-client-block-20 .phone, #block-webform-client-block-20 .fullname, #block-webform-client-block-20 .webform-component--notes {
    font-size: 14px;
  }
}

#block-webform-client-block-20 .phone .intl-tel-input {
  display: block;
}

#block-webform-client-block-20 .webform-component--arrival-time, #block-webform-client-block-20 .webform-component--time-of-departure {
  float: left;
  width: 48%;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: #aaaaaa;
}

@media only screen and (max-width: 569px) {
  #block-webform-client-block-20 .webform-component--arrival-time, #block-webform-client-block-20 .webform-component--time-of-departure {
    font-size: 14px;
    width: 100%;
  }
}

#block-webform-client-block-20 .webform-component--arrival-time {
  margin-right: 4%;
}

@media only screen and (max-width: 569px) {
  #block-webform-client-block-20 .webform-component--arrival-time {
    margin-right: 0;
  }
}

#block-webform-client-block-20 .webform-component--arrival-time .webform-calendar {
  width: 100%;
}

#block-webform-client-block-20 .arraival-time .webform-container-inline, #block-webform-client-block-20 .departure-time .webform-container-inline {
  display: flex;
  align-items: center;
  width: auto !important;
  height: 56px;
  padding-left: 22px;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: #aaaaaa;
  background: #fff;
  border: 2px solid #e6e2e3;
}

@media only screen and (max-width: 569px) {
  #block-webform-client-block-20 .arraival-time .webform-container-inline, #block-webform-client-block-20 .departure-time .webform-container-inline {
    height: 50px;
    font-size: 14px;
  }
}

#block-webform-client-block-20 .arraival-time .selectBox-arrow, #block-webform-client-block-20 .departure-time .selectBox-arrow {
  display: none;
}

#block-webform-client-block-20 .hour, #block-webform-client-block-20 .minute {
  width: 100% !important;
  min-width: 0;
  padding: 0;
  line-height: 4.2em;
  border: none;
  background: transparent;
  box-shadow: none;
}

#block-webform-client-block-20 .hour .selectBox-label, #block-webform-client-block-20 .minute .selectBox-label {
  width: auto !important;
  display: block;
}

#block-webform-client-block-20 .form-actions {
  float: left;
  width: 100%;
  text-align: right;
}

#block-webform-client-block-20 .webform-submit {
  width: 100%;
  max-width: 255px;
  padding: 1em 4em;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  color: #fafafa;
  background: #019966;
  border: none;
  outline: none;
}

@media only screen and (max-width: 840px) {
  #block-webform-client-block-20 .webform-submit {
    max-width: 50%;
  }
}

@media only screen and (max-width: 600px) {
  #block-webform-client-block-20 .webform-submit {
    max-width: none;
  }
}

@media only screen and (max-width: 569px) {
  #block-webform-client-block-20 .webform-submit {
    font-size: 14px;
    height: auto;
  }
}

#block-webform-client-block-20 .webform-submit:hover {
  background-color: #515151;
  color: #fafafa;
}

#block-webform-client-block-20 .form-item-submitted-arrival-time-hour, #block-webform-client-block-20 .form-item-submitted-arrival-time-minute,
#block-webform-client-block-20 .form-item-submitted-time-of-departure-hour, #block-webform-client-block-20 .form-item-submitted-time-of-departure-minute {
  display: inline-block;
}

#block-webform-client-block-20 .form-item-submitted-arrival-date-month, #block-webform-client-block-20 .form-item-submitted-arrival-date-day, #block-webform-client-block-20 .form-item-submitted-arrival-date-year,
#block-webform-client-block-20 .form-item-submitted-date-of-departure-month, #block-webform-client-block-20 .form-item-submitted-date-of-departure-day,
#block-webform-client-block-20 .form-item-submitted-date-of-departure-year {
  display: none;
}

.section-booking .block-title-wrapper {
  padding: 46px 0 22px;
  text-align: center;
  background: #fff;
}

.section-booking .block-views .block-title-wrapper {
  margin: 0;
}

.section-booking .block-webform .block-title-wrapper {
  text-align: center;
  padding: 2.2% 0 1.65%;
  margin: 0;
  display: none;
}

@media only screen and (max-width: 840px) {
  .section-booking .block-webform .block-title-wrapper {
    padding: 2.2% 0 1.65%;
  }
}

@media only screen and (max-width: 400px) {
  .section-booking .block-webform .block-title-wrapper {
    padding: 3.2% 0 3.65%;
  }
}

.section-booking .block-webform .block-title-wrapper h2 {
  margin-bottom: 1.44%;
}

@media only screen and (max-width: 600px) {
  .section-booking .block-webform .block-title-wrapper h2 {
    margin-bottom: 3.44%;
  }
}

.section-booking .block-webform h4 {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 0;
  color: #515151;
  margin: 0;
}

@media only screen and (max-width: 1240px) {
  .section-booking .block-webform h4 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 640px) {
  .section-booking .block-webform h4 {
    font-size: 14px;
  }
}

.section-booking .price-wrapper {
  margin: 0;
  padding-top: 25px;
  margin-right: 60px;
}

@media only screen and (max-width: 1700px) {
  .section-booking .price-wrapper {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 1367px) {
  .section-booking .price-wrapper {
    padding-top: 15px;
    margin-right: 15px;
    margin-bottom: 10px;
  }
}

.section-booking .price-wrapper .custom-value-wrapper {
  font-size: 40px;
}

@media only screen and (max-width: 1600px) {
  .section-booking .price-wrapper .custom-value-wrapper {
    font-size: 35px;
  }
}

@media only screen and (max-width: 1440px) {
  .section-booking .price-wrapper .custom-value-wrapper {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .section-booking .price-wrapper .custom-value-wrapper {
    font-size: 25px;
  }
}

@media only screen and (max-width: 900px) {
  .section-booking .price-wrapper .custom-value-wrapper {
    font-size: 20px;
  }
}

.section-booking .price-wrapper .custom-prefix-wrapper {
  margin-right: 8.75%;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  color: #515151;
}

.section-booking .price-wrapper .custom-suffix-wrapper sub {
  top: 1em;
  white-space: nowrap;
}

@media only screen and (max-width: 1440px) {
  .section-booking .price-wrapper .custom-suffix-wrapper sub {
    top: 0.45em;
  }
}

@media only screen and (max-width: 1200px) {
  .section-booking .price-wrapper .custom-suffix-wrapper sub {
    top: 0.2em;
  }
}

@media only screen and (max-width: 900px) {
  .section-booking .price-wrapper .custom-suffix-wrapper sub {
    top: 0;
  }
}

.section-booking .price-wrapper .custom-suffix-wrapper sup {
  font-size: 20px;
}

@media only screen and (max-width: 1200px) {
  .section-booking .price-wrapper .custom-suffix-wrapper sup {
    font-size: 16px;
  }
}

@media only screen and (max-width: 900px) {
  .section-booking .price-wrapper .custom-suffix-wrapper sup {
    font-size: 14px;
  }
}

@media only screen and (max-width: 500px) {
  .section-booking .price-wrapper {
    float: none;
    margin-bottom: 40px;
  }
}

.section-booking .booking-details {
  padding: 0 40px 44px;
}

@media only screen and (max-width: 1032px) {
  .section-booking .booking-details {
    padding: 10px 0;
  }
}

.section-booking .booking-details .views-row {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}

.section-booking .booking-details .views-field-field-photo {
  flex: 1 1 100%;
  width: 100%;
  max-width: 100%;
}

@media only screen and (max-width: 660px) {
  .section-booking .booking-details .views-field-field-photo {
    flex: 1 1 100%;
    margin-bottom: 30px;
  }
}

.section-booking .booking-details .views-field-field-photo ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.section-booking .booking-details .views-field-field-photo .field-slideshow {
  width: 100% !important;
}

.section-booking .booking-details .views-field-body {
  flex: 0 1 100%;
}

@media only screen and (max-width: 660px) {
  .section-booking .booking-details .views-field-body {
    flex: 0 1 100%;
  }
}

.section-booking .booking-details .views-field-body .title {
  display: none;
  margin-bottom: 6.207%;
  font-family: "PlumbCondensed", Arial, sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  color: #2e2e2e;
}

@media only screen and (max-width: 1610px) {
  .section-booking .booking-details .views-field-body .title {
    font-size: 56px;
  }
}

@media only screen and (max-width: 1440px) {
  .section-booking .booking-details .views-field-body .title {
    font-size: 45px;
  }
}

@media only screen and (max-width: 1200px) {
  .section-booking .booking-details .views-field-body .title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 900px) {
  .section-booking .booking-details .views-field-body .title {
    font-size: 36px;
  }
}

@media only screen and (max-width: 600px) {
  .section-booking .booking-details .views-field-body .title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 400px) {
  .section-booking .booking-details .views-field-body .title {
    font-size: 25px;
  }
}

.section-booking .booking-details .views-field-body ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
}

.section-booking .booking-details .views-field-body li {
  flex: 0 1 46%;
  margin-right: 3.01%;
  margin-bottom: 2.257%;
}

.section-booking .booking-details .views-field-body li:nth-last-child(2) {
  margin-bottom: 0;
}

@media only screen and (max-width: 600px) {
  .section-booking .booking-details .views-field-body li:nth-last-child(2) {
    margin-bottom: 2.257%;
  }
}

.section-booking .booking-details .views-field-body li.last {
  margin-bottom: 0;
}

@media only screen and (max-width: 1600px) {
  .section-booking .booking-details .views-field-body li:nth-child(odd) {
    padding-right: 15px;
  }
}

@media only screen and (max-width: 1030px) {
  .section-booking .booking-details .views-field-body li {
    flex: 0 1 33.3%;
    margin-right: 0;
    margin-bottom: 2.095%;
  }
}

@media only screen and (max-width: 925px) {
  .section-booking .booking-details .views-field-body li {
    flex: 0 1 50%;
  }
}

@media only screen and (max-width: 600px) {
  .section-booking .booking-details .views-field-body li {
    flex: 0 1 100%;
  }
}

.section-booking .booking-details .views-field-body li p:last-of-type {
  display: none;
  opacity: 0;
}

.section-booking .block-lermontov-hotel {
  background: #fff;
  flex: 0 1 38.85%;
  width: 38.85%;
  max-width: 38.85%;
  margin-bottom: 49px;
}

@media only screen and (max-width: 1032px) {
  .section-booking .block-lermontov-hotel {
    flex: 1 1 100%;
    max-width: 100%;
    padding: 0 15px;
  }
}

.section-booking .block-lermontov-hotel .order-date {
  padding: 44px 40px 6px;
  color: #515151;
  font-size: 17px;
  font-weight: 400;
}

@media only screen and (max-width: 1032px) {
  .section-booking .block-lermontov-hotel .order-date {
    padding: 40px 0 6px;
  }
}

#block-block-11 {
  flex: 1 1 100%;
  margin-bottom: 36px;
}

#block-block-11 .block-title-wrapper {
  text-align: center;
  padding: 50px 0 28px;
  margin: 0;
}

@media only screen and (max-width: 840px) {
  #block-block-11 .block-title-wrapper {
    padding: 50px 0 28px;
  }
}

@media only screen and (max-width: 400px) {
  #block-block-11 .block-title-wrapper {
    padding: 50px 0 28px;
  }
}

#block-block-11 .block-title-wrapper h2 {
  margin-bottom: 1.44%;
}

@media only screen and (max-width: 600px) {
  #block-block-11 .block-title-wrapper h2 {
    margin-bottom: 3.44%;
  }
}

#block-block-11 h4 {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
  color: #515151;
  margin: 0;
}

@media only screen and (max-width: 1240px) {
  #block-block-11 h4 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 640px) {
  #block-block-11 h4 {
    font-size: 14px;
  }
}

.booking-room-carousel {
  position: relative;
}

.booking-room-carousel .owl-stage-outer {
  width: 100%;
  max-width: 100%;
}

.booking-room-carousel .owl-nav {
  position: absolute;
  right: 0;
  bottom: 0;
}

.booking-room-carousel .owl-prev, .booking-room-carousel .owl-next {
  float: left;
  position: relative;
  display: block;
  width: 45px;
  height: 45px;
  background-color: #fafafa;
  font-size: 0;
}

.booking-room-carousel .owl-prev::before, .booking-room-carousel .owl-next::before {
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  transform: translate3d(-50%, -50%, 0);
  font-size: 26px;
  content: '';
  font-family: "Font-Awesome", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #aaaaaa;
}

@media only screen and (max-width: 1660px) {
  .booking-room-carousel .owl-prev::before, .booking-room-carousel .owl-next::before {
    font-size: 22px;
  }
}

@media only screen and (max-width: 1440px) {
  .booking-room-carousel .owl-prev::before, .booking-room-carousel .owl-next::before {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1200px) {
  .booking-room-carousel .owl-prev::before, .booking-room-carousel .owl-next::before {
    font-size: 14px;
  }
}

.booking-room-carousel .owl-prev:hover, .booking-room-carousel .owl-prev:active, .booking-room-carousel .owl-next:hover, .booking-room-carousel .owl-next:active {
  background-color: #019966;
}

.booking-room-carousel .owl-prev:hover::before, .booking-room-carousel .owl-prev:active::before, .booking-room-carousel .owl-next:hover::before, .booking-room-carousel .owl-next:active::before {
  color: #fafafa;
}

@media only screen and (max-width: 1440px) {
  .booking-room-carousel .owl-prev, .booking-room-carousel .owl-next {
    width: 50px;
    height: 50px;
  }
}

@media only screen and (max-width: 1200px) {
  .booking-room-carousel .owl-prev, .booking-room-carousel .owl-next {
    width: 30px;
    height: 30px;
  }
}

.booking-room-carousel .owl-next::before {
  content: '';
}

.section-contacts #block-views-contacts-block {
  padding: 0;
}

.section-contacts #block-views-contacts-block .view-content .views-field-title {
  display: none;
}

.section-contacts #block-views {
  padding: 1.8% 0 0;
}

.section-contacts .block-title-wrapper {
  margin: 0;
  padding: 4% 0 1.75%;
  text-align: center;
  background: #fff;
}

.section-contacts .view-header {
  flex: 0 1 64%;
}

.section-contacts .view-content {
  flex: 0 1 26.5%;
}

@media only screen and (max-width: 1200px) {
  .section-contacts .view-content {
    margin-top: 4%;
  }
}

@media only screen and (max-width: 1200px) {
  .section-contacts .view-content p:first-child {
    text-align: center;
  }
}

.section-contacts .view-content .view-row {
  margin-top: 44%;
}

.section-contacts .view-content .custom-title {
  margin-bottom: 3%;
}

.section-contacts .view-content .address {
  display: block;
  margin-bottom: 6%;
}

.section-contacts .view-content .email {
  margin-bottom: 7%;
}

.section-contacts .view-contacts {
  padding: 5% 12.5% 6.2%;
  background: #fafafa;
}

.section-resteraunt #flexslider-1 {
  margin: 0;
}

.section-resteraunt .view-main-slider .view-header {
  display: none;
}

.section-resteraunt .taxonomy-term:after {
  content: "";
  display: table;
  clear: both;
}

.section-resteraunt .taxonomy-term h2 {
  font-family: "Open Sans", Arial, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: normal;
  color: #515151;
  margin: 0;
}

@media only screen and (max-width: 1620px) {
  .section-resteraunt .taxonomy-term h2 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1440px) {
  .section-resteraunt .taxonomy-term h2 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1200px) {
  .section-resteraunt .taxonomy-term h2 {
    font-size: 14px;
  }
}

.section-resteraunt .taxonomy-term .taxonomy-term-description {
  margin: 0;
  color: #aaaaaa;
}

.section-resteraunt .taxonomy-term .taxonomy-term-description:after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (max-width: 600px) {
  .section-resteraunt .taxonomy-term .taxonomy-term-description {
    font-size: 13px;
  }
}

.section-resteraunt .custom-suffix-wrapper sup {
  font-family: "ALSRubl", sans-serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  color: #515151;
}

@media only screen and (max-width: 1200px) {
  .section-resteraunt .custom-suffix-wrapper sup {
    font-size: 13px;
  }
}

@media only screen and (max-width: 900px) {
  .section-resteraunt .custom-suffix-wrapper sup {
    font-size: 12px;
  }
}

@media only screen and (max-width: 600px) {
  .section-resteraunt .custom-suffix-wrapper sup {
    font-size: 10px;
  }
}

@media only screen and (max-width: 400px) {
  .section-resteraunt .custom-suffix-wrapper sup {
    font-size: 9px;
  }
}

.block-title-wrapper {
  margin: 82px 0 17px;
  padding: 0 10.469%;
}

@media only screen and (max-width: 1200px) {
  .block-title-wrapper {
    margin: 40px 0 30px;
  }
}

.block-title-wrapper #block-views-main-slider-block-1 #flexslider-1 {
  position: relative;
  margin: 0;
}

.block-title-wrapper #block-views-main-slider-block-1 #flexslider-1 .flex-prev, .block-title-wrapper #block-views-main-slider-block-1 #flexslider-1 .flex-next {
  top: 50%;
}

.block-title-wrapper #block-views-main-slider-block-1 .views-row {
  padding: 0 10.469%;
  margin: 0 auto 44px;
  column-count: 2;
  column-gap: 45px;
}

.block-title-wrapper #block-views-main-slider-block-1 .views-row:after {
  content: "";
  display: table;
  clear: both;
}

.view-restaurant .title {
  font-family: "PlumbCondensed", Arial, sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  color: black;
}

@media only screen and (max-width: 1610px) {
  .view-restaurant .title {
    font-size: 56px;
  }
}

@media only screen and (max-width: 1440px) {
  .view-restaurant .title {
    font-size: 45px;
  }
}

@media only screen and (max-width: 1200px) {
  .view-restaurant .title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 900px) {
  .view-restaurant .title {
    font-size: 36px;
  }
}

@media only screen and (max-width: 600px) {
  .view-restaurant .title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 400px) {
  .view-restaurant .title {
    font-size: 25px;
  }
}

.view-restaurant .item-list {
  float: left;
}

.view-restaurant .item-list:nth-child(even) {
  float: right;
}

.view-restaurant .item-list li {
  margin-bottom: 3.5%;
}

.view-restaurant .item-list li:first-child .taxonomy-term-description {
  padding-bottom: 7%;
}

.view-restaurant .item-list li:last-child {
  margin-bottom: 0;
}

.view-restaurant .item-list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.view-restaurant .content {
  border-bottom: 2px solid #e6e2e3;
}

.view-restaurant .content:after {
  content: "";
  display: table;
  clear: both;
}

.view-restaurant .content .wrapper {
  float: left;
  max-width: 60%;
}

@media only screen and (max-width: 900px) {
  .view-restaurant .content .wrapper {
    max-width: 70%;
  }
}

@media only screen and (max-width: 540px) {
  .view-restaurant .content .wrapper {
    max-width: none;
  }
}

.view-restaurant .field-name-field-price {
  float: right;
  margin-right: 2.5%;
}

.view-restaurant .field-name-field-price .field-item {
  font-family: "Open Sans", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  color: #2e2e2e;
}

@media only screen and (max-width: 1620px) {
  .view-restaurant .field-name-field-price .field-item {
    font-size: 35px;
  }
}

@media only screen and (max-width: 1440px) {
  .view-restaurant .field-name-field-price .field-item {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .view-restaurant .field-name-field-price .field-item {
    font-size: 25px;
  }
}

@media only screen and (max-width: 900px) {
  .view-restaurant .field-name-field-price .field-item {
    font-size: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .view-restaurant .field-name-field-price .field-item {
    font-size: 18px;
  }
}

.view-restaurant .view-footer {
  padding: 7.65% 0 4.1%;
}

.view-restaurant .view-footer p {
  text-align: center;
}

.view-restaurant .view-footer a {
  position: relative;
  font-family: "PlumbCondensed", Arial, sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  color: black;
  text-transform: uppercase;
  text-decoration: none;
}

@media only screen and (max-width: 1610px) {
  .view-restaurant .view-footer a {
    font-size: 56px;
  }
}

@media only screen and (max-width: 1440px) {
  .view-restaurant .view-footer a {
    font-size: 45px;
  }
}

@media only screen and (max-width: 1200px) {
  .view-restaurant .view-footer a {
    font-size: 40px;
  }
}

@media only screen and (max-width: 900px) {
  .view-restaurant .view-footer a {
    font-size: 36px;
  }
}

@media only screen and (max-width: 600px) {
  .view-restaurant .view-footer a {
    font-size: 30px;
  }
}

@media only screen and (max-width: 400px) {
  .view-restaurant .view-footer a {
    font-size: 25px;
  }
}

.view-restaurant .view-footer a:hover {
  color: #019966;
}

.view-restaurant .view-footer a::before {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -1em;
  content: '';
  font-family: "Font-Awesome", sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  color: #019966;
}

@media only screen and (max-width: 1610px) {
  .view-restaurant .view-footer a::before {
    font-size: 56px;
  }
}

@media only screen and (max-width: 1440px) {
  .view-restaurant .view-footer a::before {
    font-size: 45px;
  }
}

@media only screen and (max-width: 1200px) {
  .view-restaurant .view-footer a::before {
    font-size: 40px;
  }
}

@media only screen and (max-width: 900px) {
  .view-restaurant .view-footer a::before {
    font-size: 36px;
  }
}

@media only screen and (max-width: 600px) {
  .view-restaurant .view-footer a::before {
    font-size: 30px;
  }
}

@media only screen and (max-width: 400px) {
  .view-restaurant .view-footer a::before {
    font-size: 25px;
  }
}

.view-restaurant .view-content:after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (max-width: 1200px) {
  .view-restaurant .view-content {
    padding-top: 0;
  }
}

.view-restaurant .view-content .views-row {
  padding: 4.167% 10.469% 4.688%;
  background: #fafafa;
}

.view-restaurant .view-content .views-row:after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (max-width: 1200px) {
  .view-restaurant .view-content .views-row {
    display: flex;
    flex-flow: column-reverse wrap;
    align-items: center;
  }
}

.view-restaurant .view-content .views-row:nth-child(even) {
  background: #fff;
}

.view-restaurant .view-content .views-row:nth-child(even) .views-field-nothing {
  float: right;
  margin-right: 0;
  margin-left: 30px;
}

.view-restaurant .view-content .views-row:nth-child(even) .views-field-nothing:after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (max-width: 540px) {
  .view-restaurant .view-content .views-row:nth-child(even) .views-field-nothing {
    margin-left: 0;
  }
}

.view-restaurant .view-content .views-row:nth-child(even) .views-field-field-image {
  float: left;
}

@media only screen and (max-width: 1200px) {
  .view-restaurant .view-content .views-row:nth-child(even) .views-field-field-image {
    float: none;
    max-width: none;
    margin-bottom: 5%;
  }
}

.view-restaurant .view-content .views-row:nth-child(even) .views-field-field-image img {
  width: 100%;
}

.view-restaurant .view-content .views-row .views-field-nothing {
  float: left;
  margin-right: 30px;
  max-width: 42.602%;
}

.view-restaurant .view-content .views-row .views-field-nothing:after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (max-width: 1200px) {
  .view-restaurant .view-content .views-row .views-field-nothing {
    float: none;
    max-width: none;
    margin-right: 0;
    margin-left: 30px;
  }
}

@media only screen and (max-width: 540px) {
  .view-restaurant .view-content .views-row .views-field-nothing {
    margin-left: 0;
  }
}

.view-restaurant .view-content .views-row .views-field-nothing .item-list {
  float: left;
}

.view-restaurant .view-content .views-row .views-field-field-image {
  max-width: 50%;
  float: right;
}

@media only screen and (max-width: 1200px) {
  .view-restaurant .view-content .views-row .views-field-field-image {
    max-width: none;
    margin-bottom: 5%;
  }
}

.view-restaurant .view-content .views-row .title {
  float: none;
  margin-bottom: 4.5%;
}

.section-rooms #flexslider-1 {
  position: relative;
  margin: 0;
}

.section-rooms .flexslider .slides img {
  height: 610px;
}

@media only screen and (max-width: 750px) {
  .section-rooms .flexslider .slides img {
    height: 60vh;
  }
}

@media only screen and (max-width: 490px) {
  .section-rooms .flexslider .slides img {
    height: 50vh;
  }
}

.section-rooms .view-rooms .views-row {
  display: flex;
  flex-flow: row wrap;
  padding: 0 0 0 10.417%;
  background: #fafafa;
}

.section-rooms .view-rooms .views-row:after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (max-width: 1180px) {
  .section-rooms .view-rooms .views-row {
    flex-flow: column-reverse wrap;
    padding: 0 10.2% 15%;
  }
}

.section-rooms .view-rooms .views-row:nth-child(even) {
  flex-flow: row-reverse wrap;
  padding: 0 10.417% 0 0;
  background: #fff;
}

@media only screen and (max-width: 1180px) {
  .section-rooms .view-rooms .views-row:nth-child(even) {
    padding: 0 10.2% 15%;
    background: #fafafa;
  }
}

.section-rooms .view-rooms .views-row:nth-child(even) .views-field-body {
  padding: 4.292% 0 0 7.384%;
}

@media only screen and (max-width: 1367px) {
  .section-rooms .view-rooms .views-row:nth-child(even) .views-field-body {
    padding: 2.292% 0 0 5.384%;
  }
}

@media only screen and (max-width: 1180px) {
  .section-rooms .view-rooms .views-row:nth-child(even) .views-field-body {
    padding: 4.45% 4.234% 0;
  }
}

@media only screen and (max-width: 1180px) {
  .section-rooms .view-rooms .views-row:nth-child(even) {
    flex-flow: column-reverse wrap;
  }
}

.section-rooms .view-rooms .views-row .views-field {
  flex: 0 1 50%;
}

.section-rooms .view-rooms .views-row .views-field-field-photo {
  flex: 0 1 61.338%;
}

.section-rooms .view-rooms .views-row .views-field-field-photo ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.section-rooms .view-rooms .views-row .views-field-field-photo .field-slideshow {
  width: 100% !important;
}

.section-rooms .view-rooms .views-row .views-field-field-photo .field-slideshow img {
  height: 100% !important;
  object-fit: cover;
  vertical-align: bottom;
}

.section-rooms .view-rooms .views-row .views-field-field-photo .field-slideshow-slide, .section-rooms .view-rooms .views-row .views-field-field-photo .field-slideshow-slide a, .section-rooms .view-rooms .views-row .views-field-field-photo .field-slideshow-slide img {
  width: 100% !important;
}

.section-rooms .view-rooms .views-row .views-field-field-photo .colorbox::before {
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  transform: translate3d(-50%, -50%, 0);
  z-index: 3;
  content: '';
  width: 100%;
  height: 100%;
  font-family: "Font-Awesome", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  background: url("../img/search.png") no-repeat 50% 50% rgba(1, 1, 7, 0.5);
  opacity: 0;
}

.section-rooms .view-rooms .views-row .views-field-field-photo .colorbox:hover::before {
  opacity: 1;
  transition: 0.5s all;
}

.section-rooms .view-rooms .views-row .views-field-body {
  flex: 0 1 38.373%;
  padding: 4.292% 7.384% 0 0;
}

@media only screen and (max-width: 1367px) {
  .section-rooms .view-rooms .views-row .views-field-body {
    padding: 2.292% 5.384% 0 0;
  }
}

@media only screen and (max-width: 1180px) {
  .section-rooms .view-rooms .views-row .views-field-body {
    padding: 4.45% 4.234% 0;
  }
}

.section-rooms .view-rooms .views-row .views-field-body .field-content:after {
  content: "";
  display: table;
  clear: both;
}

.section-rooms .view-rooms .views-row .views-field-body .field-content .item-list ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
}

.section-rooms .view-rooms .views-row .views-field-body .field-content .item-list ul li {
  flex: 0 1 46%;
  margin-right: 3.01%;
  margin-bottom: 2.257%;
}

.section-rooms .view-rooms .views-row .views-field-body .field-content .item-list ul li:nth-last-child(2) {
  margin-bottom: 0;
}

@media only screen and (max-width: 600px) {
  .section-rooms .view-rooms .views-row .views-field-body .field-content .item-list ul li:nth-last-child(2) {
    margin-bottom: 2.257%;
  }
}

.section-rooms .view-rooms .views-row .views-field-body .field-content .item-list ul li.last {
  margin-bottom: 0;
}

@media only screen and (max-width: 1600px) {
  .section-rooms .view-rooms .views-row .views-field-body .field-content .item-list ul li:nth-child(odd) {
    padding-right: 15px;
  }
}

@media only screen and (max-width: 1180px) {
  .section-rooms .view-rooms .views-row .views-field-body .field-content .item-list ul li {
    flex: 0 1 33.3%;
    margin-right: 0;
    margin-bottom: 2.095%;
  }
}

@media only screen and (max-width: 925px) {
  .section-rooms .view-rooms .views-row .views-field-body .field-content .item-list ul li {
    flex: 0 1 50%;
  }
}

@media only screen and (max-width: 600px) {
  .section-rooms .view-rooms .views-row .views-field-body .field-content .item-list ul li {
    flex: 0 1 100%;
  }
}

.section-rooms .view-rooms .views-row .views-field-body .field-content .item-list ul li p:last-of-type {
  display: none;
  opacity: 0;
}

.section-rooms .title {
  display: block;
  margin-bottom: 6.207%;
  font-family: "PlumbCondensed", Arial, sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  color: #2e2e2e;
}

@media only screen and (max-width: 1610px) {
  .section-rooms .title {
    font-size: 56px;
  }
}

@media only screen and (max-width: 1440px) {
  .section-rooms .title {
    font-size: 45px;
  }
}

@media only screen and (max-width: 1200px) {
  .section-rooms .title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 900px) {
  .section-rooms .title {
    font-size: 36px;
  }
}

@media only screen and (max-width: 600px) {
  .section-rooms .title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 400px) {
  .section-rooms .title {
    font-size: 25px;
  }
}

.section-rooms .body {
  display: block;
  margin-bottom: 5.629%;
}

.section-rooms .field-slideshow-controls .prev, .section-rooms .field-slideshow-controls .next {
  width: 85px;
  height: 85px;
}

.section-rooms .field-slideshow-controls .prev::before, .section-rooms .field-slideshow-controls .next::before {
  font-size: 26px;
}

@media only screen and (max-width: 1660px) {
  .section-rooms .field-slideshow-controls .prev::before, .section-rooms .field-slideshow-controls .next::before {
    font-size: 22px;
  }
}

@media only screen and (max-width: 1440px) {
  .section-rooms .field-slideshow-controls .prev::before, .section-rooms .field-slideshow-controls .next::before {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1200px) {
  .section-rooms .field-slideshow-controls .prev::before, .section-rooms .field-slideshow-controls .next::before {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1660px) {
  .section-rooms .field-slideshow-controls .prev, .section-rooms .field-slideshow-controls .next {
    width: 70px;
    height: 70px;
  }
}

@media only screen and (max-width: 1440px) {
  .section-rooms .field-slideshow-controls .prev, .section-rooms .field-slideshow-controls .next {
    width: 50px;
    height: 50px;
  }
}

@media only screen and (max-width: 1200px) {
  .section-rooms .field-slideshow-controls .prev, .section-rooms .field-slideshow-controls .next {
    width: 30px;
    height: 30px;
  }
}

.section-rooms .price-wrapper {
  margin: 0;
  padding-top: 25px;
  margin-right: 60px;
}

@media only screen and (max-width: 1700px) {
  .section-rooms .price-wrapper {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 1367px) {
  .section-rooms .price-wrapper {
    padding-top: 15px;
    margin-right: 15px;
    margin-bottom: 10px;
  }
}

.section-rooms .price-wrapper .custom-value-wrapper {
  font-size: 40px;
}

@media only screen and (max-width: 1600px) {
  .section-rooms .price-wrapper .custom-value-wrapper {
    font-size: 35px;
  }
}

@media only screen and (max-width: 1440px) {
  .section-rooms .price-wrapper .custom-value-wrapper {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .section-rooms .price-wrapper .custom-value-wrapper {
    font-size: 25px;
  }
}

@media only screen and (max-width: 900px) {
  .section-rooms .price-wrapper .custom-value-wrapper {
    font-size: 20px;
  }
}

.section-rooms .price-wrapper .custom-prefix-wrapper {
  margin-right: 8.75%;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  color: #515151;
}

.section-rooms .price-wrapper .custom-suffix-wrapper sub {
  top: 1em;
  white-space: nowrap;
}

@media only screen and (max-width: 1440px) {
  .section-rooms .price-wrapper .custom-suffix-wrapper sub {
    top: 0.45em;
  }
}

@media only screen and (max-width: 1200px) {
  .section-rooms .price-wrapper .custom-suffix-wrapper sub {
    top: 0.2em;
  }
}

@media only screen and (max-width: 900px) {
  .section-rooms .price-wrapper .custom-suffix-wrapper sub {
    top: 0;
  }
}

.section-rooms .price-wrapper .custom-suffix-wrapper sup {
  font-size: 20px;
}

@media only screen and (max-width: 1200px) {
  .section-rooms .price-wrapper .custom-suffix-wrapper sup {
    font-size: 16px;
  }
}

@media only screen and (max-width: 900px) {
  .section-rooms .price-wrapper .custom-suffix-wrapper sup {
    font-size: 14px;
  }
}

@media only screen and (max-width: 500px) {
  .section-rooms .price-wrapper {
    float: none;
    margin-bottom: 40px;
  }
}

.view-slider-header .views-row {
  padding: 0 10.469%;
  margin: 0 auto 44px;
}

.view-slider-header .views-row:after {
  content: "";
  display: table;
  clear: both;
}

.view-slider-header .field-content {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 17px;
  font-weight: 600;
  font-style: normal;
  color: #2e2e2e;
}

@media only screen and (max-width: 1620px) {
  .view-slider-header .field-content {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1440px) {
  .view-slider-header .field-content {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1200px) {
  .view-slider-header .field-content {
    font-size: 14px;
  }
}

.view-slider-header .field-content table {
  width: 100% !important;
  margin: 0;
  border: none;
  border-collapse: separate;
}

.view-slider-header .field-content table tr:after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (max-width: 1170px) {
  .view-slider-header .field-content table tr {
    display: flex;
    flex-flow: column wrap;
  }
}

.view-slider-header .field-content table td {
  float: left;
  display: block;
  border: none;
  vertical-align: text-bottom;
}

@media only screen and (max-width: 1170px) {
  .view-slider-header .field-content table td {
    flex: 0 1 100%;
    margin-bottom: 15px;
  }
}

.view-slider-header .field-content ul {
  list-style-image: url("../img/check.png");
  margin: 0;
}

.view-header td {
  max-width: 47.9%;
}

@media only screen and (max-width: 1170px) {
  .view-header td {
    flex: 0 1 100%;
    max-width: none;
    margin-right: 0;
    margin-bottom: 5%;
  }
}

.view-header td:nth-child(odd) {
  margin-right: 3.7%;
}

@media only screen and (max-width: 1170px) {
  .view-header td:nth-child(odd) {
    margin-right: 0;
  }
}

.view-header p:last-of-type {
  display: none;
}

.view-footer {
  padding: 46px 0 18px;
}

.view-footer td {
  max-width: 47.3%;
}

@media only screen and (max-width: 1170px) {
  .view-footer td {
    flex: 0 1 100%;
    max-width: none;
    margin-right: 0;
    margin-bottom: 5%;
  }
}

.view-footer td:nth-child(odd) {
  max-width: 45%;
  margin-right: 7.7%;
}

@media only screen and (max-width: 1170px) {
  .view-footer td:nth-child(odd) {
    flex: 0 1 100%;
    max-width: none;
    margin-right: 0;
  }
}

.view-footer .view-content {
  margin-bottom: 0;
}

.view-footer .views-row {
  margin-bottom: 0;
}

.booking-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;
}

@media only screen and (max-width: 1700px) {
  .booking-wrapper {
    flex-flow: column wrap;
    align-items: flex-start;
  }
}

.book {
  float: right;
  display: block;
  text-align: right;
  padding-bottom: 2.254%;
}

@media only screen and (max-width: 500px) {
  .book {
    float: none;
    text-align: center;
  }
}

.book a {
  display: inline-block;
  padding: 1.2em 4em;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
  color: #fafafa;
  background: #019966;
}

@media only screen and (max-width: 1440px) {
  .book a {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1200px) {
  .book a {
    padding: 1em 3.8em;
  }
}

@media only screen and (max-width: 900px) {
  .book a {
    padding: 1em 3.8em;
    font-size: 13px;
  }
}

.book a:hover {
  background-color: #515151;
  color: #fafafa;
}

.vocabulary-room-options {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.vocabulary-room-options:after {
  content: "";
  display: table;
  clear: both;
}

.vocabulary-room-options .content {
  margin-right: 6px;
  order: -1;
}

.vocabulary-room-options h2 {
  display: inline;
  margin: 0;
  padding-bottom: 3px;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  color: #aaaaaa;
}

@media only screen and (max-width: 1440px) {
  .vocabulary-room-options h2 {
    font-size: 14px;
  }
}

.vocabulary-room-options a {
  display: inline;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  color: #aaaaaa;
  text-decoration: none;
}

@media only screen and (max-width: 1440px) {
  .vocabulary-room-options a {
    font-size: 14px;
  }
}

.category {
  display: block;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  color: #515151;
  margin-bottom: 5.155%;
}

.section-sauna #flexslider-1 {
  margin: 0;
}

.section-sauna .region-slider .view-footer {
  display: none;
}

.section-sauna .view-footer {
  padding: 7.65% 0 4.1%;
}

.section-sauna .view-footer p {
  text-align: center;
}

.section-sauna .view-footer a {
  position: relative;
  font-family: "PlumbCondensed", Arial, sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  color: black;
  text-transform: uppercase;
  text-decoration: none;
}

@media only screen and (max-width: 1610px) {
  .section-sauna .view-footer a {
    font-size: 56px;
  }
}

@media only screen and (max-width: 1440px) {
  .section-sauna .view-footer a {
    font-size: 45px;
  }
}

@media only screen and (max-width: 1200px) {
  .section-sauna .view-footer a {
    font-size: 40px;
  }
}

@media only screen and (max-width: 900px) {
  .section-sauna .view-footer a {
    font-size: 36px;
  }
}

@media only screen and (max-width: 600px) {
  .section-sauna .view-footer a {
    font-size: 30px;
  }
}

@media only screen and (max-width: 400px) {
  .section-sauna .view-footer a {
    font-size: 25px;
  }
}

.section-sauna .view-footer a:hover {
  color: #019966;
}

.section-sauna .view-footer a::before {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -1em;
  content: '';
  font-family: "Font-Awesome", sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  color: #019966;
}

@media only screen and (max-width: 1610px) {
  .section-sauna .view-footer a::before {
    font-size: 56px;
  }
}

@media only screen and (max-width: 1440px) {
  .section-sauna .view-footer a::before {
    font-size: 45px;
  }
}

@media only screen and (max-width: 1200px) {
  .section-sauna .view-footer a::before {
    font-size: 40px;
  }
}

@media only screen and (max-width: 900px) {
  .section-sauna .view-footer a::before {
    font-size: 36px;
  }
}

@media only screen and (max-width: 600px) {
  .section-sauna .view-footer a::before {
    font-size: 30px;
  }
}

@media only screen and (max-width: 400px) {
  .section-sauna .view-footer a::before {
    font-size: 25px;
  }
}

.view-sauna .view-header {
  display: none;
}

.view-sauna .views-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 4.219% 10.469% 4.74%;
  background: #fafafa;
}

.view-sauna .views-row:after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (max-width: 900px) {
  .view-sauna .views-row {
    flex-flow: column-reverse wrap;
  }
}

@media only screen and (max-width: 900px) {
  .view-sauna .views-row:first-child {
    padding-top: 10%;
  }
}

.view-sauna .views-row:nth-child(even) {
  flex-flow: row-reverse wrap;
  background: #fff;
}

@media only screen and (max-width: 900px) {
  .view-sauna .views-row:nth-child(even) {
    flex-flow: column-reverse wrap;
  }
}

.view-sauna .views-row:nth-child(even) .views-field-body {
  padding: 0 0 0 7.15%;
}

.view-sauna .views-row .views-field-body {
  flex: 0 1 51%;
  padding: 0 7.15% 0 0;
}

@media only screen and (max-width: 900px) {
  .view-sauna .views-row .views-field-body {
    padding: 0 0 0 7.25%;
  }
}

.view-sauna .views-row .views-field-body .field-content:after {
  content: "";
  display: table;
  clear: both;
}

.view-sauna .views-row .views-field-body .field-content .item-list ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
}

.view-sauna .views-row .views-field-body .field-content .item-list ul li {
  flex: 0 1 100%;
  margin-bottom: 55px;
}

.view-sauna .views-row .views-field-body .field-content .item-list ul li:last-child {
  margin-bottom: 0;
}

.view-sauna .views-row .vocabulary-sauna-options {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
}

.view-sauna .views-row .vocabulary-sauna-options:after {
  content: "";
  display: table;
  clear: both;
}

.view-sauna .views-row .vocabulary-sauna-options .content {
  margin-right: 6px;
  order: -1;
}

.view-sauna .views-row .vocabulary-sauna-options h2 {
  margin: 0 0 2.5px 0;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  color: #aaaaaa;
}

@media only screen and (max-width: 1440px) {
  .view-sauna .views-row .vocabulary-sauna-options h2 {
    font-size: 14px;
  }
}

.view-sauna .views-row .vocabulary-sauna-options h2 a {
  display: inline;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  color: #aaaaaa;
  text-decoration: none;
}

@media only screen and (max-width: 1440px) {
  .view-sauna .views-row .vocabulary-sauna-options h2 a {
    font-size: 14px;
  }
}

.view-sauna .views-field {
  flex: 0 1 50%;
}

.view-sauna .views-field-field-photo {
  flex: 0 1 49%;
}

@media only screen and (max-width: 900px) {
  .view-sauna .views-field-field-photo {
    margin-bottom: 5%;
  }
}

.view-sauna .views-field-field-photo ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.view-sauna .views-field-field-photo .field-slideshow {
  width: 100% !important;
}

.view-sauna .views-field-field-photo .field-slideshow img {
  height: auto !important;
  object-fit: cover;
  vertical-align: bottom;
}

.view-sauna .views-field-field-photo .field-slideshow-slide, .view-sauna .views-field-field-photo .field-slideshow-slide a, .view-sauna .views-field-field-photo .field-slideshow-slide img {
  width: 100% !important;
}

.view-sauna .views-field-field-photo .colorbox::before {
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  transform: translate3d(-50%, -50%, 0);
  z-index: 3;
  content: '';
  width: 100%;
  height: 100%;
  font-family: "Font-Awesome", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  background: url("../img/search.png") no-repeat 50% 50% rgba(1, 1, 7, 0.5);
  opacity: 0;
}

.view-sauna .views-field-field-photo .colorbox:hover::before {
  opacity: 1;
  transition: 0.5s all;
}

.view-sauna .views-field-body {
  flex: 0 1 50.7%;
  padding: 77px 7.15% 0 0px;
}

.view-sauna .views-field-body .field-content:after {
  content: "";
  display: table;
  clear: both;
}

.view-sauna .views-field-body .field-content .item-list ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
}

.view-sauna .views-field-body .field-content .item-list ul li {
  flex: 0 1 50%;
  margin-bottom: 55px;
}

.view-sauna .views-field-body .field-content .item-list ul li:last-child {
  margin-bottom: 0;
}

.view-sauna .title {
  display: block;
  margin-bottom: 31px;
  font-family: "PlumbCondensed", Arial, sans-serif;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  color: #2e2e2e;
}

@media only screen and (max-width: 1610px) {
  .view-sauna .title {
    font-size: 56px;
  }
}

@media only screen and (max-width: 1440px) {
  .view-sauna .title {
    font-size: 45px;
  }
}

@media only screen and (max-width: 1200px) {
  .view-sauna .title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 900px) {
  .view-sauna .title {
    font-size: 36px;
  }
}

@media only screen and (max-width: 600px) {
  .view-sauna .title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 400px) {
  .view-sauna .title {
    font-size: 25px;
  }
}

.view-sauna .description {
  display: block;
  margin-bottom: 8.113%;
}

.view-sauna .description p {
  font-size: 17px;
  color: #515151;
}

.view-sauna .body {
  display: block;
  margin-bottom: 7.07%;
}

.view-sauna .custom-value-wrapper, .view-sauna .custom-prefix-wrapper {
  float: left;
  display: block;
  font-family: "Open Sans", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #515151;
}

@media only screen and (max-width: 1610px) {
  .view-sauna .custom-value-wrapper, .view-sauna .custom-prefix-wrapper {
    font-size: 28px;
  }
}

@media only screen and (max-width: 1440px) {
  .view-sauna .custom-value-wrapper, .view-sauna .custom-prefix-wrapper {
    font-size: 22px;
  }
}

@media only screen and (max-width: 1200px) {
  .view-sauna .custom-value-wrapper, .view-sauna .custom-prefix-wrapper {
    font-size: 20px;
  }
}

@media only screen and (max-width: 900px) {
  .view-sauna .custom-value-wrapper, .view-sauna .custom-prefix-wrapper {
    font-size: 18px;
  }
}

@media only screen and (max-width: 600px) {
  .view-sauna .custom-value-wrapper, .view-sauna .custom-prefix-wrapper {
    font-size: 16px;
  }
}

@media only screen and (max-width: 400px) {
  .view-sauna .custom-value-wrapper, .view-sauna .custom-prefix-wrapper {
    font-size: 14px;
  }
}

.view-sauna .custom-value-wrapper {
  font-size: 40px;
}

@media only screen and (max-width: 1600px) {
  .view-sauna .custom-value-wrapper {
    font-size: 35px;
  }
}

@media only screen and (max-width: 1440px) {
  .view-sauna .custom-value-wrapper {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .view-sauna .custom-value-wrapper {
    font-size: 25px;
  }
}

@media only screen and (max-width: 900px) {
  .view-sauna .custom-value-wrapper {
    font-size: 20px;
  }
}

.view-sauna .custom-prefix-wrapper {
  margin-right: 2.75%;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  color: #515151;
  text-transform: lowercase;
}

@media only screen and (max-width: 1600px) {
  .view-sauna .custom-prefix-wrapper {
    font-size: 25px;
  }
}

@media only screen and (max-width: 1440px) {
  .view-sauna .custom-prefix-wrapper {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1200px) {
  .view-sauna .custom-prefix-wrapper {
    font-size: 18px;
  }
}

@media only screen and (max-width: 900px) {
  .view-sauna .custom-prefix-wrapper {
    font-size: 16px;
  }
}

.view-sauna .custom-suffix-wrapper {
  float: left;
  align-self: center;
  margin-bottom: 0.5em;
}

.view-sauna .custom-suffix-wrapper sup {
  font-family: "ALSRubl", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  color: #515151;
  top: 0;
}

@media only screen and (max-width: 1200px) {
  .view-sauna .custom-suffix-wrapper sup {
    font-size: 13px;
  }
}

@media only screen and (max-width: 900px) {
  .view-sauna .custom-suffix-wrapper sup {
    font-size: 12px;
  }
}

@media only screen and (max-width: 600px) {
  .view-sauna .custom-suffix-wrapper sup {
    font-size: 10px;
  }
}

@media only screen and (max-width: 400px) {
  .view-sauna .custom-suffix-wrapper sup {
    font-size: 9px;
  }
}

.view-sauna .custom-suffix-wrapper sub {
  font-family: "Myriad-Pro", Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  color: #515151;
  bottom: auto;
  vertical-align: bottom;
}

@media only screen and (max-width: 1200px) {
  .view-sauna .custom-suffix-wrapper sub {
    font-size: 15px;
  }
}

@media only screen and (max-width: 900px) {
  .view-sauna .custom-suffix-wrapper sub {
    font-size: 14px;
  }
}

@media only screen and (max-width: 600px) {
  .view-sauna .custom-suffix-wrapper sub {
    font-size: 13px;
  }
}

@media only screen and (max-width: 400px) {
  .view-sauna .custom-suffix-wrapper sub {
    font-size: 12px;
  }
}

.view-sauna .price-wrapper {
  align-items: baseline;
  padding-left: 0;
}

.field-slideshow-wrapper {
  position: relative;
}

.field-slideshow-controls {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;
}

.field-slideshow-controls:after {
  content: "";
  display: table;
  clear: both;
}

.field-slideshow-controls .prev, .field-slideshow-controls .next {
  float: left;
  position: relative;
  display: block;
  width: 45px;
  height: 45px;
  background-color: #fafafa;
  font-size: 0;
}

.field-slideshow-controls .prev:hover, .field-slideshow-controls .prev:active, .field-slideshow-controls .next:hover, .field-slideshow-controls .next:active {
  background-color: #019966;
}

.field-slideshow-controls .prev:hover::before, .field-slideshow-controls .prev:active::before, .field-slideshow-controls .next:hover::before, .field-slideshow-controls .next:active::before {
  color: #fafafa;
}

.field-slideshow-controls .prev::before, .field-slideshow-controls .next::before {
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  transform: translate3d(-50%, -50%, 0);
  content: '';
  font-family: "Font-Awesome", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: #aaaaaa;
}

.field-slideshow-controls .next::before {
  content: '';
}

.section-documents .breadcrumb {
  display: none;
}

.section-documents .block-title-wrapper {
  margin: 0;
  padding: 2.65% 10.469% 2%;
  text-align: center;
}

.section-documents .view-content {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  padding: 2.3% 10.365%;
  background: #fafafa;
}

.section-documents .views-row {
  flex: 0 1 22.5%;
  margin-bottom: 25px;
  margin-right: 2.494%;
}

.section-documents .views-row:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 1020px) {
  .section-documents .views-row {
    flex: 0 1 25%;
  }
}

@media only screen and (max-width: 840px) {
  .section-documents .views-row {
    flex: 0 1 40%;
  }
}

@media only screen and (max-width: 600px) {
  .section-documents .views-row {
    flex: 0 1 100%;
  }
}

.section-documents .views-field-field-image {
  margin-bottom: 1.8%;
}

.section-documents .views-field-field-image:after {
  content: "";
  display: table;
  clear: both;
}

.section-documents .views-field-field-image .colorbox {
  position: relative;
  float: left;
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
}

.section-documents .views-field-field-image .colorbox::before {
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  transform: translate3d(-50%, -50%, 0);
  z-index: 3;
  content: '';
  width: 100%;
  height: 100%;
  font-family: "Font-Awesome", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  background: url("../img/search.png") no-repeat 50% 50% rgba(1, 1, 7, 0.5);
  opacity: 0;
}

.section-documents .views-field-field-image:hover .colorbox::before {
  opacity: 1;
  transition: 0.5s all;
}

.section-documents .views-field-title {
  width: 100%;
  text-align: center;
  float: left;
}

.section-documents .views-field-title a {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  color: #515151;
  text-decoration: none;
}

#bottom-region {
  padding: 5.3% 0 4% 0;
  text-align: center;
  background: #2e2e2e;
}

#bottom-region .block-block {
  display: inline-block;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  color: #fafafa;
  text-decoration: none;
}

#bottom-region .block-block a {
  display: block;
  margin-bottom: 20px;
  color: #fff;
  text-decoration: none;
}

#bottom-region .block-block a:hover {
  color: #019966;
}

@media only screen and (max-width: 600px) {
  #bottom-region .block-block a {
    font-size: 14px;
  }
}

#bottom-region .block-block b {
  display: block;
  margin-bottom: 25px;
  font-size: 17px;
}

@media only screen and (max-width: 600px) {
  #bottom-region .block-block b {
    font-size: 16px;
  }
}

#bottom-region .block-block p {
  text-align: center;
}

#bottom-region .menu {
  margin: 0 0 52px;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

@media only screen and (max-width: 810px) {
  #bottom-region .menu {
    flex-flow: row wrap;
    margin-bottom: 22px;
  }
}

#bottom-region .menu__item {
  list-style: none;
  padding: 0 30px;
}

@media only screen and (max-width: 810px) {
  #bottom-region .menu__item {
    flex: 1 1 100%;
    margin-bottom: 15px;
  }
  #bottom-region .menu__item:last-child {
    margin-bottom: 0;
  }
}

#bottom-region .menu__link {
  color: #aaaaaa;
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
}
