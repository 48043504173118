* {
  line-height: normal;
}

p {
  margin: 0;
  font-family: $open-sans;
  font-style: normal;
  font-size: 15px;
  font-weight: 600;
  color: $very-gray;

  @include mq(1440px, max) {
    font-size: 14px;
  }

  @include mq(1200px, max) {
    font-size: 13px;
  }

  @include mq(600px, max) {
    font-size: 12px;
  }
}

h2 {
  line-height: normal;
}

.front {
  #block-views-contacts-block {
    .block-title-wrapper {
      display: none;
    }
  }

  #flexslider-1 {
    .views-field-field-slder-text-1 {
      @include absolute-horizontal-center;
      z-index: 10;
      top: 28%;
      font-family: $pr-serif;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.035em;
      text-transform: uppercase;
      color: $white;

      &::before {
        @include absolute-horizontal-center;
        content: '';
        left: 50%;
        bottom: -1.6vh;
        width: 3.9em;
        height: 2px;
        background-color: $white;

        @include mq(1089px, max) {
          display: none;
        }
      }

      @include mq(1610px, max) {
        font-size: 22px;
      }

      @include mq(1400px, max) {
        top: 39%;
        font-size: 20px;
      }

      @include mq(924px, max) {
        top: 32%;
      }

      @include mq(761px, max) {
        font-size: 18px;
      }

      @include mq(635px, max) {
        font-size: 16px;
      }

      @include mq(538px, max) {
        font-size: 14px;
      }

      @include mq(398px, max) {
        font-size: 12px;
      }

      @include mq(600px, max) {
        display: none;
      }
    }

    .views-field-field-slider-text-2 {
      @include absolute-horizontal-center;
      left: 49.8%;
      top: 36%;
      z-index: 10;
      font-family: $plumb;
      font-size: 90px;
      font-style: normal;
      font-weight: 400;
      text-transform: uppercase;
      color: $white;

      @include mq(1610px, max) {
        font-size: 88px;
      }

      @include mq(1510px, max) {
        top: 37.5%;
      }

      @include mq(1400px, max) {
        top: 48.5%;
        font-size: 84px;
      }

      @include mq(1179px, max) {
        font-size: 70px;
      }

      @include mq(984px, max) {
        font-size: 64px;
      }

      @include mq(901px, max) {
        font-size: 58px;
      }

      @include mq(817px, max) {
        font-size: 54px;
      }

      @include mq(761px, max) {
        font-size: 45px;
      }

      @include mq(635px, max) {
        font-size: 38px;
      }

      @include mq(538px, max) {
        font-size: 32px;
      }

      @include mq(454px, max) {
        font-size: 28px;
      }

      @include mq(398px, max) {
        font-size: 24px;
      }

      @include mq(342px, max) {
        font-size: 22px;
      }

      @include mq(600px, max) {
        display: none;
      }
    }

    .flex-prev, .flex-next {
      top: 42.4%;

      @include mq(1400px, max) {
        @include absolute-vertical-center;
      }

      @include mq(500px,max) {
        top: 60%;
        transform: translateY(-60%);
      }
    }
  }
}

.block__title {
  font-family: $plumb;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
  color: $mega-gray;
  margin: 0;

  @include mq(1610px, max) {
    font-size: 56px;
  }

  @include mq(1440px, max) {
    font-size: 45px;
  }

  @include mq(1200px, max) {
    font-size: 40px;
  }

  @include mq(900px, max) {
    font-size: 36px;
  }

  @include mq(600px, max) {
    font-size: 30px;
  }

  @include mq(400px, max) {
    font-size: 25px;
  }
}

.region-slider {
  position: relative;

  .flex-control-nav {
    display: none;
  }

  .block-webform {
    position: absolute;
    bottom: 0;
    z-index: 10;
    width: 100%;
    padding: 1.8% 0;
    background: transparentize($green, 0.25);

    @include mq(1400px, max) {
      position: static;
      background: $green;
    }

    @include mq(900px, max) {
      padding: 1.8% 0 4%;
    }

    #webform-client-form-8 > div {
      max-width: 1146px;
      margin: 0 auto;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: flex-end;

      @include mq(1200px, max) {
        max-width: 640px;
        flex-flow: column wrap;
        align-items: center;
      }
    }

    .webform-component {
      white-space: nowrap;

      @include mq(1200px, max) {
        flex: 0 1 100%;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    label {
      font-family: $open-sans;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      color: $low-gray;
      margin-bottom: 4px;
    }

    input {
      height: 47px;
      font-family: $open-sans;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      color: $very-gray;
      border: 1px solid $low-gray;
      background: $white;

      @include mq(1200px, max) {
        width: 100%;
      }
    }

    .selectBox-dropdown {
      display: block !important;
      min-width: auto;
      width: 181px !important;
      height: 47px;
      border: none;
      border-radius: 0;
      box-shadow: none;

      @include mq(1200px, max) {
        width: 100% !important;
      }

      .selectBox-label {
        width: 100% !important;
        font-family: $open-sans;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 2.7em;
        color: $very-gray;
        background: $white;
        border: 1px solid $low-gray;
        box-shadow: none;
      }

      .selectBox-arrow {
        min-width: auto;
        background: url("../img/down.png") no-repeat 50% 50% $white;
        border: 1px solid $low-gray;
        border-left: none;
      }
    }

    .form-required {
      color: $white;
    }

    .webform-datepicker {
      @include mq(1200px, max) {
        max-width: none;
        width: 100%;
        margin: 0 auto;
      }
    }

    .form-item-submitted-arrival-date-day, .form-item-submitted-arrival-date-month,
    .form-item-submitted-arrival-date-year, .form-item-submitted-date-of-departure-day,
    .form-item-submitted-date-of-departure-month, .form-item-submitted-date-of-departure-year {
      display: none;
    }

    .webform-component {
      margin-left: 0;
      margin-right: 7px;

      &:last-child {
        margin-right: 20px;
      }

      @include mq(1200px, max) {
        margin-right: 0;
      }
    }

    .webform-component-date, .webform-calendar {
      position: relative;

      input {
        padding-left: 40px;
        padding-right: 5px;
      }

      @include mq(1200px, max) {
        width: 80%;
      }

      &::before {
        content: '';
        @include absolute-vertical-center;
        top: 66%;
        left: 13%;
        z-index: 2;
        font-family: $fa;
        font-size: 15px;
        font-weight: 400;
        font-style: normal;
        color: $mega-gray;

        @include mq(1200px, max) {
          left: 3%;
        }
      }
    }

    .webform-component-date {
      width: 125px;

      @include mq(1200px, max) {
        width: 80%;
      }
    }

    .webform-calendar {
      width: 127px;

      @include mq(1200px, max) {
        width: 100%;
      }
    }

    #edit-submitted-your-name, #edit-submitted-e-mail, #edit-submitted-phone {
      padding-left: 10px;
    }

    .room-wrapper {
      width: 181px;

      select {
        height: 47px;

        @include mq(1200px, max) {
          width: 100%;
        }
      }

      @include mq(1200px, max) {
        width: 100%;
      }

      .selectBox-label {
        display: flex;
        align-items: center;
        height: 100% !important;
      }

      @include mq(1200px, max) {
        width: 80%;
      }

      .selectBox-dropdown-menu:nth-of-type(2) {
        .selectBox-options {
          li {
            a {
              font-family: $open-sans;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              color: $green;
              background: $white;
            }

            &.selectBox-hover a {
              color: $white;
              background-color: $green;
            }
          }
        }
      }
    }

    .name-wrapper, .email-wrapper {
      width: 172px;

      @include mq(1200px, max) {
        width: 80%;
      }
    }

    .webform-component--phone {
      width: 139px;
      margin-right: 20px;

      @include mq(1200px, max) {
        width: 80%;
        margin-right: 0;
      }
    }

    .webform-submit {
      height: auto;
      padding: 1.466em 1.4em;
      margin-top: 0.82em;
      font-family: $open-sans;
      font-size: 15px;
      font-weight: 600;
      font-style: normal;
      text-transform: uppercase;
      color: $mega-gray;
      background-color: $yellow;
      border: none;
      box-shadow: none;

      &:hover {
        color: $white;
        background-color: $very-gray;
      }
    }
  }
}

.view-id-history {
  .view-content {
    .views-row {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      padding: 1% 10.365%;

      @include mq(900px, max) {
        flex-flow: column nowrap;
        padding: 8% 10.365% 1%;
      }

      @include mq(640px, max) {
        max-width: 100%;
        margin: 0 auto;
      }

      .views-field-field-image {
        flex: 0 1 41.8%;

        @include mq(740px, max) {
          flex: 0 1 100%;
        }
      }

      .views-field-body {
        flex: 0 1 50%;

        @include mq(740px, max) {
          flex: 0 1 100%;
        }
      }

      .views-field-field-image {
        img {
          width: 100%;
        }
      }

      .views-field-body {
        .field-content {
          position: relative;
          margin-top: 13.69%;
          font-family: $plumb;
          font-size: 60px;
          font-style: normal;
          font-weight: 400;
          text-transform: uppercase;
          color: $black;

          @include mq(1610px, max) {
            font-size: 56px;
          }

          @include mq(1440px, max) {
            font-size: 50px;
          }

          @include mq(1200px, max) {
            font-size: 45px;
          }

          @include mq(900px, max) {
            font-size: 40px;
          }

          @include mq(600px, max) {
            font-size: 36px;
          }

          @include mq(550px, max) {
            margin-top: 20.69%;
          }

          @include mq(400px, max) {
            font-size: 30px;
          }

          &::before {
            content: "  ";
            position: absolute;
            top: -36px;
            left: 0;
            font-family: $fa;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0.2em;
            color: $middle-gray;

            @include mq(550px, max) {
              font-size: 13px;
            }
          }

          p {
            margin: 8% 0 0 0;
            text-transform: none;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 90px;
              width: 8.2em;
              height: 2px;
              background-color: $middle-gray;

              @include mq (1600, max) {
                top: 85px;
              }

              @include mq(1440px, max) {
                top: 75px;
              }

              @include mq(1200px, max) {
                top: 60px;
              }

              @include mq(900px, max) {
                top: 65px;
              }

              @include mq(600px, max) {
                top: 60px;
                width: 6em;
              }

              @include mq(520px, max) {
                top: 55px;
              }

              @include mq(420px, max) {
                top: 50px;
              }

              @include mq(400px, max) {
                top: 43px;
              }
            }
          }
        }
      }
    }
  }
}

#block-views-rooms-block {
  padding: 0 10.365% 19px;

  @include mq (640px, max) {
    max-width: 100%;
    padding: 1.8% 15px;
    margin: 0 auto;
  }

  .block-title-wrapper {
    margin: 73px 0 11px;
    padding: 0;

    @include mq(640px, max) {
      margin: 0 0 11px;
    }
  }

  .block__title {
    text-align: left;
  }

  .field-content {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    align-items: flex-start;

    @include mq(950px, max) {
      align-items: center;
    }
  }

  .view-header {
    margin-bottom: 30px;

    .views-field-body {
      max-width: 740px;
    }

    .view-content {
      .views-row {
        margin-bottom: 0;
      }
    }
  }

  .view-content {
    @include clearfix;

    .views-row {
      @include clearfix;
      float: left;
      width:  49%;
      margin-bottom: 63px;

      @include mq(1300px, max) {
        width: 100%;
      }

      &:nth-child(odd) {
        margin-right: 1.446%;
      }

      .field-slideshow-slide {
        position: relative;
      }

      .colorbox {
        &::before {
          @include absolute-center;
          z-index: 3;
          content: '';
          width: 100%;
          height: 100%;
          font-family: $fa;
          font-size: 30px;
          font-style: normal;
          font-weight: 400;
          background: url('../img/search.png') no-repeat 50% 50% rgba(1, 1, 7, 0.5);
          opacity: 0;
          transition: 0.5s all;
        }

        &:hover::before {
          opacity: 1;
          transition: 0.3s opacity;
        }
      }

      .views-field-field-photo {
        float: left;
        width: 487px;
        margin-right: 5.965%;

        @include mq(1885px, max) {
          width: 100%;
          margin-bottom: 20px;
        }

        @include mq(1300px, max) {
          width: 487px;
        }

        @include mq(950px, max) {
          width: 490px;
          float: none;
          margin: 0 auto 30px;
        }

        @include mq(540px, max) {
          max-width: 100%;
        }

        img {
          vertical-align: bottom;
        }
      }

      .views-field-body {
        float: left;
        width: calc(100% - 487px - 5.965%);
        padding: 6px 0;

        @include mq(1885px, max) {
          width: 487px;
        }

        @include mq(1300px, max) {
          width: calc(100% - 550px);
        }

        @include mq(950px, max) {
          width: 100%;
        }
      }
    }

    @include mq(1162px, max) {
      flex-flow: column wrap;
      justify-content: center;
    }
  }

  .title {
    display: block;
    margin-bottom: 21px;
    font-family: $plumb;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    color: $very-gray;
  }

  .body {
    max-width: 205px;
    margin-bottom: 20px;
    margin-left: 2.5%;

    @include mq(1885px, max) {
      max-width: 470px;
      margin-left: 0;
    }
  }

  .link a {
    display: block;
    padding: 1.2em 4em;
    font-family: $open-sans;
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
    text-transform: lowercase;
    text-decoration: none;
    color: $black;
    border: 2px solid $mega-gray;
    text-align: center;

    &:hover {
      color: $green;
      border-color: $green;
    }
  }

  .item-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .field-slideshow-wrapper {
    position: relative;
  }

  .field-slideshow-controls {
    @include clearfix;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 10;

    .prev, .next {
      float: left;
      position: relative;
      display: block;
      width: 45px;
      height: 45px;
      background-color: $white;
      font-size: 0;

      &:hover, &:active {
        background-color: $green;

        &::before {
          color: $white;
        }
      }

      &::before {
        @include absolute-center;
        content: '';
        font-family: $fa;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        color: $middle-gray;
      }
    }

    .next::before {
      content: '';
    }
  }

  .field-slideshow {
    padding: 0;

    @include mq(640px, max) {
     width: 490px !important;
      height: 335px !important;
    }

    @include mq(540px, max) {
      width: 490px !important;
      height: 335px !important;
    }

    @include mq(520px, max) {
      width: 470px !important;
      height: 321px !important;
    }

    @include mq(500px, max) {
      width: 450px !important;
      height: 308px !important;
    }

    @include mq(460px, max) {
      width: 400px !important;
      height: 273px !important;
    }

    @include mq(430px, max) {
      width: 350px !important;
      height: 239px !important;
    }

    @include mq(380px, max) {
      width: 320px !important;
      height: 219px !important;
    }

    @include mq(350px, max) {
      width: 280px !important;
      height: 191px !important;
    }
  }
}

#block-views-additional-services-block {
  padding: 1.8% 10.365% 2%;
  text-align: center;
  background-color: $green;

  @include mq(640px, max) {
    padding: 5% 10.365%;
  }

  .block-title-wrapper {
    margin: 0 0 1.7% 0;

    @include mq(640px, max) {
      margin-bottom: 5.7%;
    }
  }

  .block__title {
    margin: 0;
    color: $white;
  }

  .view-additional-services {
    .view-content {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: baseline;

      .views-row {
        flex: 0 1 15%;

        @include mq(1240px, max) {
          flex: 0 1 20%;
        }

        @include mq(900px, max) {
          flex: 0 1 25%;
        }

        @include mq(640px, max) {
          flex: 0 1 33.3%;

          &:last-child {
            margin-top: 20px;
          }
        }

        @include mq(540px, max) {
          flex: 0 1 50%;
          padding: 0 10px;

          &:nth-child(3n) {
            margin-top: 20px;
          }
        }
      }

      .views-field-title {
        font-family: $open-sans;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        color: $white;

        @include mq(1240px, max) {
          font-size: 15px;
        }

        @include mq(640px, max) {
          font-size: 14px;
        }
      }
    }
  }

  .views-field-field-icon {
    margin-bottom: 3%;
  }
}

#block-views-testimonials-block {
  padding: 3.8% 9.065%;
  text-align: center;

  @include mq(640px, max) {
    max-width: 100%;
    padding: 10% 15px;
    margin: 0 auto;
  }

  .block__title {
    color: $black;
  }

  .block-title-wrapper {
    margin: 0 0 59px 0;

    @include mq(640px, max) {
      margin: 0 0 39px 0;
    }
  }

  .view-testimonials {

    .views-field-body {
      margin-bottom: 38px;

      @include mq(900px, max) {
        margin-bottom: 28px;
      }
    }

    .view-content {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      @include mq(820px, max) {
        flex-flow: column nowrap;
      }

      .views-row {
        @include clearfix;
        flex: 0 1 33.3%;

        @include mq(820px, max) {
          flex: 0 1 100%;

            &:nth-child(even) {
              margin: 50px 0 50px;
            }
          }
        }
      }

    .views-field-field-who {
        .field-content {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;
        }
      }

    .field-content {
      padding: 0 18%;

      @include mq(900px, max) {
        padding: 0 5%;
      }
    }

    .image-wrapper {
      margin-right: 7%;
    }

    .text-wrapper {
      font-family: $plumb;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      text-transform: uppercase;
      text-align: left;
      color: #000000;

      span {
        display: block;
        font-family: $open-sans;
        font-size: 15px;
        font-weight: 600;
        text-transform: none;
        color: $very-gray;
      }
    }
  }
}

#block-views-contacts-block {
  padding: 1.8% 10.065%;

  @include mq(640px, max) {
    max-width: 100%;
    padding: 1.8% 15px;
    margin: 0 auto;
  }

  .block__title {
    color: $black;
  }

  .view-contacts {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    @include mq(1200px, max) {
      flex-flow: row wrap;
    }
  }

  .view-header {
    flex: 0 1 60%;
    height: 382px !important;

    @include mq(1200px, max) {
      flex: 0 1 100%;
    }
  }

  .view-content {
    flex: 0 1 32%;

    @include mq(1195px, max) {
      padding-top: 25px;
    }

    @include mq(1200px, max) {
      flex: 0 1 100%;
      text-align: center;
    }

    .views-field-title {
      font-family: $plumb;
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      color: $black;
      text-transform: uppercase;
      margin-bottom: 13px;

      @include mq(1610px, max) {
        font-size: 56px;
      }

      @include mq(1440px, max) {
        font-size: 50px;
      }

      @include mq(1195px, max) {
        display: none;
      }
    }

    .views-field-body {
      font-family: $open-sans;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      color: $very-gray;

      p:first-child {
        margin-bottom: 13.45%;
      }
    }

    .custom-title {
      display: block;
      font-family: $plumb;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      color: $black;
      margin-bottom: 13px;
    }

    .email {
      display: block;
      margin-bottom: 22px;

      a {
        color: $green;
        text-decoration: none;
      }

      &:hover {
        color: $very-gray
      }
    }

    .address {
      margin-bottom: 22px;
    }

    .phone {
      display: block;
      font-family: $plumb;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      color: $black;
    }
  }
}

#first-time {
  display: none;
}


.selectBox-options-bottom {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  box-shadow: none;
  border: none;
}

.form-item {
  margin: 0;
}

#flexslider-1 {
  border: none;

  @include mq(1400px, max) {
    margin: 0;
  }

  .views-field-field-img {
    position: relative;

    &::before {
      @include absolute-center;
      content: '';
      z-index: 3;
      width: 100%;
      height: 100%;
      background-color: rgba(110,103,83, 0.15);
    }
  }

  .flex-control-nav, .flex-control-paging {
    display: block;
  }

  .flex-prev {
    top: 50%;
    left: 10.4vw;
    display: block;
    width: 1.5em;
    height: 1.8em;
    opacity: 1;

    @include mq(900px, max) {
      @include absolute-vertical-center;
    }

    &::before {
      content: '';
      font-family: $fa;
      font-size: 30.31px;
      font-style: normal;
      font-weight: 400;
      color: $white;

      @include mq(930px, max) {
        font-size: 26.31px;
      }

      @include mq(640px, max) {
        font-size: 22.31px;
      }

      @include mq(440px, max) {
        font-size: 18.31px;
      }
    }

    @include mq(930px, max) {
      @include absolute-vertical-center;
    }

    @include mq(641px, max) {
      height: 1.55em;
    }

    @include mq(440px, max) {
      height: 1.2em;
    }
  }

  .flex-next {
    top: 50%;
    right: 10.4vw;
    display: block;
    width: 1.5em;
    height: 1.8em;
    opacity: 1;

    &::before {
      content: '';
      font-family: $fa;
      font-size: 30.31px;
      font-style: normal;
      font-weight: 400;
      color: $white;

      @include mq(930px, max) {
        font-size: 26.31px;
      }

      @include mq(640px, max) {
        font-size: 22.31px;
      }

      @include mq(440px, max) {
        font-size: 20.31px;
      }
    }

    @include mq(930px, max) {
      @include absolute-vertical-center;
    }

    @include mq(641px, max) {
      height: 1.55em;
    }

    @include mq(440px, max) {
      height: 1.2em;
    }
  }

  .flex-control-nav, .flex-control-paging {
    display: none;
  }
}

#flexslider-2 {
  border: none;
  margin: 60px 0;

  .views-field-field-img {
    position: relative;

    &::before {
      @include absolute-center;
      content: '';
      z-index: 3;
      width: 100%;
      height: 100%;
      background-color: rgba(110,103,83, 0.15);
    }
  }

  .slides {
    li {
      position: relative;
    }
  }

  .flex-control-nav, .flex-control-paging {
    display: block;
  }

  .flex-prev {
    display: none;
  }

  .flex-next {
    top: 50%;
    right: 10.4vw;
    display: block;
    width: 1.5em;
    height: 1.8em;
    opacity: 1;

    @include mq(1600px, max) {
      top: 55%;
      transform: translateX(-55%);

      @include mq(640px, max) {
        height: 1.5em;
      }
    }

    &::before {
      content: '';
      font-family: $fa;
      font-size: 30.31px;
      font-style: normal;
      font-weight: 400;
      color: $white;
      transform: rotate(180deg);

      @include mq(930px, max) {
        font-size: 26.31px;
      }

      @include mq(640px, max) {
        font-size: 22.31px;
      }

      @include mq(440px, max) {
        font-size: 20.31px;
      }
    }
  }

  .flex-control-nav, .flex-control-paging {
    display: none;
  }

  .views-field-title {
    max-width: 60%;
    position: absolute;
    top: 26%;
    left: 10.5%;

    span {
      font-family: $plumb;
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.13;
      color: $very-white;
      text-transform: uppercase;

      @include mq(1720px, max) {
        font-size: 50px;
      }

      @include mq(1500px, max) {
        font-size: 40px;
      }

      @include mq(1320px, max) {
        font-size: 35px;
      }

      @include mq(1225px, max) {
        font-size: 30px;
      }

      @include mq(640px, max) {
        font-size: 25px;
      }

      @include mq(550px, max) {
        font-size: 20px;
      }

      @include mq(460px, max) {
        font-size: 16px;
      }
    }
  }

  .views-field-body {
    max-width: 40.82%;
    position: absolute;
    top: 52%;
    left: 10.6%;

    @include mq(1821px, max) {
      max-width: 50%;
    }

    p {
      color: $very-white;

      @include mq(1470px, max) {
        font-size: 13px;
      }

      @include mq(1271px, max) {
        display: none;
      }
    }
  }

  .views-field-field-link {
    position: absolute;
    top: 73.2%;
    left: 10.8%;

    a {
      padding: 1.2em 2.5em;
      font-family: $open-sans;
      font-weight: 600;
      font-style: normal;
      font-size: 15px;
      color: $mega-gray;
      background: $yellow;
      text-decoration: none;
      text-transform: uppercase;

      @include mq(1500px, max) {
        font-size: 14px;
      }

      @include mq(1225px, max) {
        font-size: 13px;
      }

      @include mq(600px, max) {
        padding: 0.7em 1em;
        font-size: 12px;
      }

      @include mq(440px, max) {
        font-size: 10px;
      }

      &:hover {
        color: $yellow;
        background: transparent;
        border: 2px solid $yellow;
      }
    }
  }
}

.price-wrapper {
  display: flex;
  margin-bottom: 22px;
  padding-left: 0;

  .custom-value-wrapper {
    display: block;
    font-family: $open-sans;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    color: $very-gray;

    @include mq(1610px, max) {
      font-size: 28px;
    }

    @include mq(1440px, max) {
      font-size: 22px;
    }

    @include mq(1200px, max) {
      font-size: 20px;
    }

    @include mq(900px, max) {
      font-size: 18px;
    }

    @include mq(600px, max) {
      font-size: 16px;
    }

    @include mq(400px, max) {
      font-size: 14px;
    }
  }

  .custom-suffix-wrapper {
    sup {
      font-family: $alsrubl;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      color: $very-gray;
      top: 0.54em;

      @include mq(1440px, max) {
        top: auto;
      }

      @include mq(1200px, max) {
        font-size: 13px;
      }

      @include mq(900px, max) {
        font-size: 12px;
      }

      @include mq(600px, max) {
        font-size: 10px;
        vertical-align: super;
      }

      @include mq(400px, max) {
        font-size: 9px;
      }
    }

    sub {
      bottom: auto;
      top: 0.3em;
      font-family: $myriad;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      color: $very-gray;
      vertical-align: bottom;

      @include mq(1440px, max) {
        top: auto;
      }

      @include mq(1200px, max) {
        font-size: 15px;
      }

      @include mq(900px, max) {
        font-size: 14px;
      }

      @include mq(600px, max) {
        font-size: 13px;
        vertical-align: baseline;
      }

      @include mq(400px, max) {
        font-size: 12px;
      }
    }
  }
}

.lermontov-navigation {
  display: inline-block;
  width: 51px;
  height: 51px;
  background: $green;
  position: fixed;
  bottom: 50px;
  right: 50px;
  z-index: 10;
  cursor: pointer;

  &::before {
    @include absolute-center;
    content: '';
    font-family: $fa;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    color: #fffefe;

    @include mq(1200px, max) {
      font-size: 18px;
    }

    @include mq(900px, max) {
      font-size: 16px;
    }
  }

  @include mq(1200px, max) {
    width: 40px;
    height: 40px;
  }

  @include mq(900px, max) {
    right: 30px;
    bottom: 30px;
    width: 35px;
    height: 35px;
  }
}

.class-view-contacts {
  padding: 1.8% 10.065%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;

  @include mq(1200px, max) {
    flex-flow: row wrap;
  }

  @include mq(640px, max) {
    max-width: 100%;
    padding: 1.8% 15px;
    margin: 0 auto;
  }

  .block__title {
    color: $black;
  }

  .view-header {
    flex: 0 1 60%;
    height: 382px !important;

    @include mq(1200px, max) {
      flex: 0 1 100%;
    }
  }

  .view-content {
    flex: 0 1 32%;

    @include mq(1195px, max) {
      padding-top: 25px;
    }

    @include mq(1200px, max) {
      flex: 0 1 100%;
      text-align: center;
    }

    .views-field-title {
      font-family: $plumb;
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      color: $black;
      text-transform: uppercase;
      margin-bottom: 13px;

      @include mq(1610px, max) {
        font-size: 56px;
      }

      @include mq(1440px, max) {
        font-size: 50px;
      }

      @include mq(1195px, max) {
        display: none;
      }
    }

    .views-field-body {
      font-family: $open-sans;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      color: $very-gray;

      p:first-child {
        margin-bottom: 1em;
      }
    }

    .custom-title {
      display: block;
      font-family: $plumb;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      color: $black;
      margin-bottom: 13px;
    }

    .email {
      display: block;
      margin-bottom: 22px;

      a {
        color: $green;
        text-decoration: none;
      }

      &:hover {
        color: $very-gray
      }
    }

    .address {
      margin-bottom: 22px;
    }

    .phone {
      display: block;
      font-family: $plumb;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      color: $black;
    }
  }
}
