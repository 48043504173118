// Dependencies.
@import 'base/headings/headings';
@import 'components/clearfix/clearfix';

// Branding header
//
// Markup: header.twig
//
// Style guide: components.header
//
//.header,
//%header {
//  @extend %clearfix;
//
//  // Wrapping link for logo.
//  &__logo {
//    float: left;
//    margin: 0 10px 0 0;
//    padding: 0;
//
//    @include rtl() {
//      float: right;
//      margin: 0 0 0 10px;
//    }
//  }
//
//  // Logo image.
//  &__logo-image {
//    vertical-align: bottom;
//  }
//
//  // Wrapper for website name and slogan.
//  &__name-and-slogan {
//    float: left;
//  }
//
//  // The name of the website.
//  &__site-name {
//    @extend %h1;
//    margin: 0;
//  }
//
//  // The link around the name of the website.
//  &__site-link {
//    &:link,
//    &:visited {
//      color: color(text);
//      text-decoration: none;
//    }
//
//    &:hover,
//    &:focus {
//      text-decoration: underline;
//    }
//  }
//
//  // The slogan (or tagline) of a website.
//  &__site-slogan {
//    margin: 0;
//  }
//
//  // The secondary menu (login, etc.)
//  &__secondary-menu {
//    float: right;
//
//    @include rtl() {
//      float: left;
//    }
//  }
//
//  // Wrapper for any blocks placed in the header region.
//  &__region {
//    // Clear the logo.
//    clear: both;
//  }
//}

#headline-region {
  display: flex;
  padding: 28px 10.365% 6px;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: center;
  background: $mega-gray;

  @include mq(440px, max) {
    flex-flow: column wrap;
    align-items: center;
  }

  #block-block-3 {
    margin: 0.59% 0 0 1.7%;

    @include mq(440px, max) {
      align-self: stretch;
    }
  }

  .address, .phone {
    font-family: $open-sans;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none;
    color: $white;

    @include mq(840px, max) {
      display: block;
      font-size: 16px;
      margin-right: 0;
    }

    @include mq(600px, max) {
      font-size: 16px;
    }
  }

  .address {
    position: relative;
    margin-right: 38px;

    @include mq(840px, max) {
      margin-right: 0;
    }

    &::before {
      position: absolute;
      left: -2em;
      top: 0.5em;
      content: '';
      font-family: $fa;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      color: $green;
    }
  }

  .phone {
    position: relative;

      &::before {
        position: absolute;
        left: -1.5em;
        top: 0.5em;
        content: '';
        transform: rotate(-20deg);
        font-family: $fa;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        color: $green;

        @include mq(440px, max) {
          left: -1.88em;
        }
    }
  }

  p {
    margin: 0;

    br {
      display: none;
    }
  }

  select {
    width: 100% !important;
  }

  .selectBox-dropdown {
    position: relative;
    display: block !important;
    width: 90px !important;
    min-width: auto;
    padding: 0;
    height: auto;
    line-height: normal;
    background: $mega-gray;
    border: none;
    border-radius: 0;
    outline: none;
    box-shadow: none;

    .selectBox-label {
      width: 100%;
      padding: 1% 0 0 3.25%;
      font-family: $open-sans;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 2.7em;
      color: $middle-gray;
      background: $mega-gray;
      border: none;

      @include mq(600px, max) {
        font-size: 14px;
      }
    }

    .selectBox-arrow {
      width: 10%;
      top: 2px;
      right: 4px;
      background: url("../img/down_2.png") no-repeat 50% 50% $mega-gray;
      border-left: none;

      @include mq(440px, max) {
        top: 0;
      }
    }
  }
}

.header__region {
  padding-top: 1.55%;

  .block-block {
    max-width: 342px;
    margin: 11px auto 0;

    img {
      width: 100%;
    }
  }

  .menu {
    margin: 2em 0 1.2em;
    padding: 0;
    list-style: none;
    text-align: center;
  }

  .menu__item {
    display: inline-block;
    margin-right: 3em;

    &:last-child {
      margin-right: 0;
    }

    @include mq(900px, max) {
      margin-right: 1.8em;
    }

    @include mq(740px, max) {
      display: block;
      margin-right: 0;
    }
  }

  .menu__link {
    font-family: $open-sans;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    color: $very-gray;
    text-decoration: none;

    &:hover {
      color: $green;
    }

    @include mq(900px, max) {
      font-size: 16px;
    }
  }

  .selectBox-dropdown-menu {
    width: 92px;
    top: 76px !important;
    left: 1631px;
    right: 10.9%;
    border: none !important;

    .selectBox-options {
      li {
        a {
          color: $white;
          background: $green;
        }
      }
    }
  }
}

input:focus {
  border: none;
  outline: none;
}

.block-menu {
  @include mq(740px, max) {
    margin: 15px 0 10px;
    text-align: center;
  }
}

.burger {
  display: none;
  margin: 0;

  @include mq(740px, max) {
    display: inline-block;
    font-size: 0;
    width: 30px;
    height: 25px;
    position: relative;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
  }

  span {
    display: block;
    position: absolute;
    height: 5px;
    width: 50%;
    background: $mega-gray;
    opacity: 1;
    transform: rotate(0deg);
    transition: .25s ease-in-out;

    &:nth-child(even) {
      left: 50%;
      border-radius: 0 5px 5px 0;
    }

    &:nth-child(odd) {
      left:0;
      border-radius: 5px 0 0 5px;
    }

    &:nth-child(1), &:nth-child(2) {
      top: 0;
    }

    &:nth-child(3), &:nth-child(4) {
      top: 10px;
    }

    &:nth-child(5), &:nth-child(6) {
      top: 20px;
    }
  }

  &.open span:nth-child(1), &.open span:nth-child(6) {
    transform: rotate(45deg);
  }

  &.open span:nth-child(2), &.open span:nth-child(5) {
    transform: rotate(-45deg);
  }

  &.open span:nth-child(1) {
    left: 3px;
    top: 6px;
  }

  &.open span:nth-child(2) {
    left: calc(50% - 3px);
    top: 6px;
  }

  &.open span:nth-child(3) {
    left: 0;
    opacity: 0;
  }

  &.open span:nth-child(4) {
    left: 50%;
    opacity: 0;
  }

  &.open span:nth-child(5) {
    left: 3px;
    top: 15px;
  }

  &.open span:nth-child(6) {
    left: calc(50% - 3px);
    top: 15px;
  }
}

.menu {
  @include mq (740px, max) {
    display: none;
    opacity: 0;

    &.show {
      display: flex;
      flex-flow: column wrap;
      justify-content: space-around;
      opacity: 1;
      transition: 600ms all;

      @include mq(400px, max) {
        border-radius: 0;
      }
    }
  }
}

.selectBox-options {
  li {
    a {
      font-family: $open-sans;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: $green;
      background: $white;
    }

    &.selectBox-hover a {
      color: $white;
      background-color: $green;
    }
  }
}

.lang-dropdown-select-element-selectBox-dropdown-menu {
  top: 76px !important;
  width: 100px !important;
  height: 31px !important;

  @include mq(840px, max) {
    top: 81px !important;
  }

  @include mq(440px, max) {
    top: 120px !important;
  }

  li {
    a {
      font-family: $open-sans;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: $white;
      background: $green;
      padding: 0.383em 1em;
    }

    &.selectBox-hover a {
      color: $white;
      background-color: $green;
      padding: 0.383em 1em;
    }
  }
}

.selectBox-options-bottom {
  box-shadow: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  li.selectBox-selected {
    display: none;
  }
}

