.section-rooms {

  #flexslider-1 {
    position: relative;
    margin: 0;
  }

  .flexslider .slides img {
    height: 610px;

    @include mq(750px, max) {
      height: 60vh;
    }

    @include mq(490px, max) {
      height: 50vh;
    }
  }

  .view-rooms {
    .views-row {
      @include clearfix;
      display: flex;
      flex-flow: row wrap;
      padding: 0 0 0 10.417%;
      background: $white;

      @include mq(1180px, max) {
        flex-flow: column-reverse wrap;
        padding: 0 10.2% 15%;
      }

      &:nth-child(even) {
        flex-flow: row-reverse wrap;
        padding: 0 10.417% 0 0;
        background: $very-white;

        @include mq(1180px, max) {
          padding: 0 10.2% 15%;
          background: $white;
        }

        .views-field-body {
          padding: 4.292% 0 0 7.384%;

          @include mq(1367px, max) {
            padding: 2.292% 0 0 5.384%;
          }

          @include mq(1180px, max) {
            padding: 4.45% 4.234% 0;
          }
        }

        @include mq(1180px, max) {
          flex-flow: column-reverse wrap;
        }
      }

      .views-field {
        flex: 0 1 50%;
      }

      .views-field-field-photo {
        flex: 0 1 61.338%;

        ul {
          list-style: none;
          padding: 0;
          margin: 0;
        }

        .field-slideshow {
          width: 100% !important;

          img {
            height: 100% !important;
            object-fit: cover;
            vertical-align: bottom;
          }
        }

        .field-slideshow-slide, .field-slideshow-slide a, .field-slideshow-slide img {
          width: 100% !important;
        }

        .colorbox {
          &::before {
            @include absolute-center;
            z-index: 3;
            content: '';
            width: 100%;
            height: 100%;
            font-family: $fa;
            font-size: 30px;
            font-style: normal;
            font-weight: 400;
            background: url('../img/search.png') no-repeat 50% 50% rgba(1, 1, 7, 0.5);
            opacity: 0;
          }

          &:hover::before {
            opacity: 1;
            transition: 0.5s all;
          }
        }
      }

      .views-field-body {
        flex: 0 1 38.373%;
        padding: 4.292% 7.384% 0 0;

        @include mq(1367px, max) {
          padding: 2.292% 5.384% 0 0;
        }

        @include mq(1180px, max) {
          padding: 4.45% 4.234% 0;
        }

        .field-content {
          @include clearfix;

          .item-list {
            ul {
              display: flex;
              flex-flow: row wrap;
              justify-content: space-between;
              margin: 0;
              padding: 0;
              list-style: none;

              li {
                flex: 0 1 46%;
                margin-right: 3.01%;
                margin-bottom: 2.257%;

                &:nth-last-child(2) {
                  margin-bottom: 0;

                  @include mq(600px, max) {
                    margin-bottom: 2.257%;
                  }
                }

                &.last {
                  margin-bottom: 0;
                }

                @include mq(1600px, max) {
                  &:nth-child(odd) {
                    padding-right: 15px;
                  }
                }

                @include mq(1180px, max) {
                  flex: 0 1 33.3%;
                  margin-right: 0;
                  margin-bottom: 2.095%;
                }

                @include mq(925px, max) {
                  flex: 0 1 50%;
                }

                @include mq(600px, max) {
                  flex: 0 1 100%;
                }

                p:last-of-type {
                  display: none;
                  opacity: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .title {
    display: block;
    margin-bottom: 6.207%;
    font-family: $plumb;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    color: $mega-gray;

    @include mq(1610px, max) {
      font-size: 56px;
    }

    @include mq(1440px, max) {
      font-size: 45px;
    }

    @include mq(1200px, max) {
      font-size: 40px;
    }

    @include mq(900px, max) {
      font-size: 36px;
    }

    @include mq(600px, max) {
      font-size: 30px;
    }

    @include mq(400px, max) {
      font-size: 25px;
    }
  }

  .body {
    display: block;
    margin-bottom: 5.629%;
  }

  .field-slideshow-controls {
    .prev, .next {
      width: 85px;
      height: 85px;

      &::before {
        font-size: 26px;

        @include mq(1660px, max) {
          font-size: 22px;
        }

        @include mq(1440px, max) {
          font-size: 18px;
        }

        @include mq(1200px, max) {
          font-size: 14px;
        }
      }

      @include mq(1660px, max) {
        width: 70px;
        height: 70px;
      }

      @include mq(1440px, max) {
        width: 50px;
        height: 50px;
      }

      @include mq(1200px, max) {
        width: 30px;
        height: 30px;
      }
    }
  }

  .price-wrapper {
    margin: 0;
    padding-top: 25px;
    margin-right: 60px;

    @include mq(1700px, max) {
      margin-bottom: 30px;
    }

    @include mq(1367px, max) {
      padding-top: 15px;
      margin-right: 15px;
      margin-bottom: 10px;
    }

    .custom-value-wrapper {
      font-size: 40px;

      @include mq(1600px, max) {
        font-size: 35px;
      }

      @include mq(1440px, max) {
        font-size: 30px;
      }

      @include mq(1200px, max) {
        font-size: 25px;
      }

      @include mq(900px, max) {
        font-size: 20px;
      }
    }

    .custom-prefix-wrapper {
      margin-right: 8.75%;
      font-family: $open-sans;
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      color: $very-gray;
    }

    .custom-suffix-wrapper {
      sub {
        top: 1em;
        white-space: nowrap;

        @include mq(1440px, max) {
          top: 0.45em;
        }

        @include mq(1200px, max) {
          top: 0.2em;
        }

        @include mq(900px, max) {
          top: 0;
        }
      }

      sup {
        font-size: 20px;

        @include mq(1200px, max) {
          font-size: 16px;
        }

        @include mq(900px, max) {
          font-size: 14px;
        }
      }
    }

    @include mq(500px, max) {
      float: none;
      margin-bottom: 40px;
    }
  }
}

.view-slider-header {
  .views-row {
    @include clearfix;
    padding: 0 10.469%;
    margin: 0 auto 44px;
  }

  .field-content {
    font-family: $open-sans;
    font-size: 17px;
    font-weight: 600;
    font-style: normal;
    color: $mega-gray;

    @include mq(1620px, max) {
      font-size: 16px;
    }

    @include mq(1440px, max) {
      font-size: 15px;
    }

    @include mq(1200px, max) {
      font-size: 14px;
    }

    table {
      width: 100% !important;
      margin: 0;
      border: none;
      border-collapse: separate;

      tr {
        @include clearfix;

        @include mq(1170px, max) {
          display: flex;
          flex-flow: column wrap;
        }
      }

      td {
        float: left;
        display: block;
        border: none;
        vertical-align: text-bottom;

        @include mq(1170px, max) {
          flex: 0 1 100%;
          margin-bottom: 15px;
        }
      }
    }

    ul {
      list-style-image: url('../img/check.png');
      margin: 0;
    }
  }
}

.view-header {
  td {
    max-width: 47.9%;

    @include mq(1170px, max) {
      flex: 0 1 100%;
      max-width: none;
      margin-right: 0;
      margin-bottom: 5%;
    }
  }

  td:nth-child(odd) {
    margin-right: 3.7%;

    @include mq(1170px, max) {
      margin-right: 0;
    }
  }

  p:last-of-type {
    display: none;
  }
}

.view-footer {
  padding: 46px 0 18px;

  td {
    max-width: 47.3%;

    @include mq(1170px, max) {
      flex: 0 1 100%;
      max-width: none;
      margin-right: 0;
      margin-bottom: 5%;
    }
  }

  td:nth-child(odd) {
    max-width: 45%;
    margin-right: 7.7%;

    @include mq(1170px, max) {
      flex: 0 1 100%;
      max-width: none;
      margin-right: 0;
    }
  }

  .view-content {
    margin-bottom: 0;
  }

  .views-row {
    margin-bottom: 0;
  }
}

.booking-wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;

  @include mq(1700px, max) {
    flex-flow: column wrap;
    align-items: flex-start;
  }
}

.book {
  float: right;
  display: block;
  text-align: right;
  padding-bottom: 2.254%;

  @include mq(500px, max) {
    float: none;
    text-align: center;
  }

  a {
    display: inline-block;
    padding: 1.2em 4em;
    font-family: $open-sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    color: $white;
    background: $green;

    @include mq(1440px, max) {
      font-size: 14px;
    }

    @include mq(1200px, max) {
      padding: 1em 3.8em;
    }

    @include mq(900px, max) {
      padding: 1em 3.8em;
      font-size: 13px;
    }

    &:hover {
      background-color: $very-gray;
      color: $white;
    }
  }
}

.vocabulary-room-options {
  @include clearfix;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .content {
    margin-right: 6px;
    order: -1;
  }

  h2 {
    display: inline;
    margin: 0;
    padding-bottom: 3px;
    font-family: $open-sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    color: $middle-gray;

    @include mq(1440px, max) {
      font-size: 14px;
    }
  }

  a {
    display: inline;
    font-family: $open-sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    color: $middle-gray;
    text-decoration: none;

    @include mq(1440px, max) {
      font-size: 14px;
    }
  }
}

.category {
  display: block;
  font-family: $open-sans;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  color: $very-gray;
  margin-bottom: 5.155%;
}
