@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

@mixin absolute-center {
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  transform: translate3d(-50%, -50%,0);
}

@mixin absolute-vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin absolute-horizontal-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin wrapper($width) {
  max-width: $width - 30px;
  margin: 0 auto;
  padding: 0 15px;
  box-sizing: content-box;

  @include mq($width, max) {
    box-sizing: border-box;
  }
}

@mixin mq($width, $type: min) {
  @media only screen and (#{$type}-width: $width) {
    @content;
  }
}
