.section-sauna {

  #flexslider-1 {
    margin: 0;
  }

  .region-slider {
    .view-footer {
      display: none;
    }
  }

  .view-footer {
    padding: 7.65% 0 4.1%;

    p {
      text-align: center;
    }

    a {
      position: relative;
      font-family: $plumb;
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      color: $black;
      text-transform: uppercase;
      text-decoration: none;

      @include mq(1610px, max) {
        font-size: 56px;
      }

      @include mq(1440px, max) {
        font-size: 45px;
      }

      @include mq(1200px, max) {
        font-size: 40px;
      }

      @include mq(900px, max) {
        font-size: 36px;
      }

      @include mq(600px, max) {
        font-size: 30px;
      }

      @include mq(400px, max) {
        font-size: 25px;
      }

      &:hover {
        color: $green;
      }

      &::before {
        @include absolute-horizontal-center;
        top: -1em;
        content: '';
        font-family: $fa;
        font-size: 60px;
        font-style: normal;
        font-weight: 400;
        color: $green;

        @include mq(1610px, max) {
          font-size: 56px;
        }

        @include mq(1440px, max) {
          font-size: 45px;
        }

        @include mq(1200px, max) {
          font-size: 40px;
        }

        @include mq(900px, max) {
          font-size: 36px;
        }

        @include mq(600px, max) {
          font-size: 30px;
        }

        @include mq(400px, max) {
          font-size: 25px;
        }
      }
    }
  }

  //.view-main-slider {
  //  .view-footer {
  //    display: none;
  //  }
  //}

  //.view-sauna {
  //  .view-header {
  //    display: none;
  //  }
  //}
}

.view-sauna {
  .view-header {
    display: none;
  }

  .views-row {
    @include clearfix;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 4.219% 10.469% 4.74%;
    background: $white;

    @include mq(900px, max) {
      flex-flow: column-reverse wrap;
    }

    &:first-child {
      @include mq(900px, max) {
        padding-top: 10%;
      }
    }

    &:nth-child(even) {
      flex-flow: row-reverse wrap;
      background: $very-white;

      @include mq(900px, max) {
        flex-flow: column-reverse wrap;
      }

      .views-field-body {
        padding: 0 0 0 7.15%;
      }
    }

    .views-field-body {
      flex: 0 1 51%;
      padding: 0 7.15% 0 0;

      @include mq(900px, max) {
        padding: 0 0 0 7.25%;
      }

      .field-content {
        @include clearfix;

        .item-list {
          ul {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            margin: 0;
            padding: 0;
            list-style: none;

            li {
              flex: 0 1 100%;
              margin-bottom: 55px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    .vocabulary-sauna-options {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-end;
      @include clearfix;

      .content {
        margin-right: 6px;
        order: -1;
      }

      h2 {
        margin: 0 0 2.5px 0;
        font-family: $open-sans;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        color: $middle-gray;

        @include mq(1440px, max) {
          font-size: 14px;
        }

        a {
          display: inline;
          font-family: $open-sans;
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          color: $middle-gray;
          text-decoration: none;

          @include mq(1440px, max) {
            font-size: 14px;
          }
        }
      }
    }
  }

  .views-field {
    flex: 0 1 50%;
  }

  .views-field-field-photo {
    flex: 0 1 49%;

    @include mq(900px, max) {
      margin-bottom: 5%;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .field-slideshow {
      width: 100% !important;

      img {
        height: auto !important;
        object-fit: cover;
        vertical-align: bottom;
      }
    }

    .field-slideshow-slide, .field-slideshow-slide a, .field-slideshow-slide img {
      width: 100% !important;
    }

    .colorbox {
      &::before {
        @include absolute-center;
        z-index: 3;
        content: '';
        width: 100%;
        height: 100%;
        font-family: $fa;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        background: url('../img/search.png') no-repeat 50% 50% rgba(1, 1, 7, 0.5);
        opacity: 0;
      }

      &:hover::before {
        opacity: 1;
        transition: 0.5s all;
      }
    }
  }

  .views-field-body {
    flex: 0 1 50.7%;
    padding: 77px 7.15% 0 0px;

    .field-content {
      @include clearfix;

      .item-list {
        ul {
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            flex: 0 1 50%;
            margin-bottom: 55px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .title {
    display: block;
    margin-bottom: 31px;
    font-family: $plumb;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    color: $mega-gray;

    @include mq(1610px, max) {
      font-size: 56px;
    }

    @include mq(1440px, max) {
      font-size: 45px;
    }

    @include mq(1200px, max) {
      font-size: 40px;
    }

    @include mq(900px, max) {
      font-size: 36px;
    }

    @include mq(600px, max) {
      font-size: 30px;
    }

    @include mq(400px, max) {
      font-size: 25px;
    }
  }

  .description {
    display: block;
    margin-bottom: 8.113%;

    p {
      font-size: 17px;
      color: $very-gray;
    }
  }

  .body {
    display: block;
    margin-bottom: 7.07%;
  }

  .custom-value-wrapper, .custom-prefix-wrapper {
    float: left;
    display: block;
    font-family: $open-sans;
    font-style: normal;
    font-weight: 400;
    color: $very-gray;

    @include mq(1610px, max) {
      font-size: 28px;
    }

    @include mq(1440px, max) {
      font-size: 22px;
    }

    @include mq(1200px, max) {
      font-size: 20px;
    }

    @include mq(900px, max) {
      font-size: 18px;
    }

    @include mq(600px, max) {
      font-size: 16px;
    }

    @include mq(400px, max) {
      font-size: 14px;
    }
  }

  .custom-value-wrapper {
    font-size: 40px;

    @include mq(1600px, max) {
      font-size: 35px;
    }

    @include mq(1440px, max) {
      font-size: 30px;
    }

    @include mq(1200px, max) {
      font-size: 25px;
    }

    @include mq(900px, max) {
      font-size: 20px;
    }
  }

  .custom-prefix-wrapper {
    margin-right: 2.75%;
    font-family: $open-sans;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    color: $very-gray;
    text-transform: lowercase;

    @include mq(1600px, max) {
      font-size: 25px;
    }

    @include mq(1440px, max) {
      font-size: 20px;
    }

    @include mq(1200px, max) {
      font-size: 18px;
    }

    @include mq(900px, max) {
      font-size: 16px;
    }
  }

  .custom-suffix-wrapper {
    float: left;
    align-self: center;
    margin-bottom: 0.5em;

    sup {
      font-family: $alsrubl;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      color: $very-gray;
      top: 0;

      @include mq(1200px, max) {
        font-size: 13px;
      }

      @include mq(900px, max) {
        font-size: 12px;
      }

      @include mq(600px, max) {
        font-size: 10px;
      }

      @include mq(400px, max) {
        font-size: 9px;
      }
    }

    sub {
      font-family: $myriad;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      color: $very-gray;
      bottom: auto;
      vertical-align: bottom;

      @include mq(1200px, max) {
        font-size: 15px;
      }

      @include mq(900px, max) {
        font-size: 14px;
      }

      @include mq(600px, max) {
        font-size: 13px;
      }

      @include mq(400px, max) {
        font-size: 12px;
      }
    }
  }

  .price-wrapper {
    align-items: baseline;
    padding-left: 0;
  }
}

.field-slideshow-wrapper {
  position: relative;
}

.field-slideshow-controls {
  @include clearfix;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;

  .prev, .next {
    float: left;
    position: relative;
    display: block;
    width: 45px;
    height: 45px;
    background-color: $white;
    font-size: 0;

    &:hover, &:active {
      background-color: $green;

      &::before {
        color: $white;
      }
    }

    &::before {
      @include absolute-center;
      content: '';
      font-family: $fa;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      color: $middle-gray;
    }
  }

  .next::before {
    content: '';
  }
}
