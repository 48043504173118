.section-resteraunt {
  #flexslider-1 {
    margin: 0;
  }

  .view-main-slider {
    .view-header {
      display: none;
    }
  }

  .taxonomy-term {
    @include clearfix;

    h2 {
      font-family: $open-sans;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: normal;
      color: $very-gray;
      margin: 0;

      @include mq(1620px, max) {
        font-size: 16px;
      }

      @include mq(1440px, max) {
        font-size: 15px;
      }

      @include mq(1200px, max) {
        font-size: 14px;
      }
    }

    .taxonomy-term-description {
      @include clearfix;
      margin: 0;
      color: $middle-gray;

      @include mq(600px, max) {
        font-size: 13px;
      }
    }
  }

  .custom-suffix-wrapper {
    sup {
      font-family: $alsrubl;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      color: $very-gray;

      @include mq(1200px, max) {
        font-size: 13px;
      }

      @include mq(900px, max) {
        font-size: 12px;
      }

      @include mq(600px, max) {
        font-size: 10px;
      }

      @include mq(400px, max) {
        font-size: 9px;
      }
    }
  }
}

.block-title-wrapper {
  margin: 82px 0 17px;
  padding: 0 10.469%;

  @include mq(1200px, max) {
    margin: 40px 0 30px;
  }

  #block-views-main-slider-block-1 {

    #flexslider-1 {
      position: relative;
      margin: 0;

      .flex-prev, .flex-next {
        top: 50%;
      }
    }

    .views-row {
      @include clearfix;
      padding: 0 10.469%;
      margin: 0 auto 44px;
      column-count: 2;
      column-gap: 45px;
    }
  }

}

.view-restaurant {
  .title {
    font-family: $plumb;
    font-size: 60px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    color: $black;

    @include mq(1610px, max) {
      font-size: 56px;
    }

    @include mq(1440px, max) {
      font-size: 45px;
    }

    @include mq(1200px, max) {
      font-size: 40px;
    }

    @include mq(900px, max) {
      font-size: 36px;
    }

    @include mq(600px, max) {
      font-size: 30px;
    }

    @include mq(400px, max) {
      font-size: 25px;
    }
  }

  .item-list {
    float: left;

    &:nth-child(even) {
      float: right;
    }

    li {
      margin-bottom: 3.5%;

      &:first-child {
        .taxonomy-term-description {
          padding-bottom: 7%;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  .content {
    @include clearfix;
    border-bottom: 2px solid $low-gray;

    .wrapper {
      float: left;
      max-width: 60%;

      @include mq(900px, max) {
        max-width: 70%;
      }

      @include mq(540px, max) {
        max-width: none;
      }
    }
  }

  .field-name-field-price {
    float: right;
    margin-right: 2.5%;

    .field-item {
      font-family: $open-sans;
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      color: $mega-gray;

      @include mq(1620px, max) {
        font-size: 35px;
      }

      @include mq(1440px, max) {
        font-size: 30px;
      }

      @include mq(1200px, max) {
        font-size: 25px;
      }

      @include mq(900px,max) {
        font-size: 20px;
      }

      @include mq(600px, max) {
        font-size: 18px;
      }
    }
  }

  .view-footer {
    padding: 7.65% 0 4.1%;

    p {
      text-align: center;
    }

    a {
      position: relative;
      font-family: $plumb;
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      color: $black;
      text-transform: uppercase;
      text-decoration: none;

      @include mq(1610px, max) {
        font-size: 56px;
      }

      @include mq(1440px, max) {
        font-size: 45px;
      }

      @include mq(1200px, max) {
        font-size: 40px;
      }

      @include mq(900px, max) {
        font-size: 36px;
      }

      @include mq(600px, max) {
        font-size: 30px;
      }

      @include mq(400px, max) {
        font-size: 25px;
      }

      &:hover {
        color: $green;
      }

      &::before {
        @include absolute-horizontal-center;
        top: -1em;
        content: '';
        font-family: $fa;
        font-size: 60px;
        font-style: normal;
        font-weight: 400;
        color: $green;

        @include mq(1610px, max) {
          font-size: 56px;
        }

        @include mq(1440px, max) {
          font-size: 45px;
        }

        @include mq(1200px, max) {
          font-size: 40px;
        }

        @include mq(900px, max) {
          font-size: 36px;
        }

        @include mq(600px, max) {
          font-size: 30px;
        }

        @include mq(400px, max) {
          font-size: 25px;
        }
      }
    }
  }

  .view-content {
    @include clearfix;

    @include mq(1200px, max) {
      padding-top: 0;
    }

    .views-row {
      @include clearfix;
      padding: 4.167% 10.469% 4.688%;
      background: $white;

      @include mq(1200px, max) {
        display: flex;
        flex-flow: column-reverse wrap;
        align-items: center;
      }

      &:nth-child(even) {
        background: $very-white;

        .views-field-nothing {
          @include clearfix;
          float: right;
          margin-right: 0;
          margin-left: 30px;

          @include mq(540px, max) {
            margin-left: 0;
          }
        }

        .views-field-field-image {
          float: left;

          @include mq(1200px, max) {
            float: none;
            max-width: none;
            margin-bottom: 5%;
          }

          img {
            width: 100%;
          }
        }
      }

      .views-field-nothing {
        @include clearfix;
        float: left;
        margin-right: 30px;
        max-width: 42.602%;

        @include mq(1200px, max) {
          float: none;
          max-width: none;
          margin-right: 0;
          margin-left: 30px;
        }

        @include mq(540px, max) {
          margin-left: 0;
        }

        .item-list {
          float: left;
        }
      }

      .views-field-field-image {
        max-width: 50%;
        float: right;

        @include mq(1200px, max) {
          max-width: none;
          margin-bottom: 5%;
        }
      }

      .title {
        float: none;
        margin-bottom: 4.5%;
      }
    }
  }
}

