.section-documents {

  .breadcrumb {
    display: none;
  }

  .block-title-wrapper {
    margin: 0;
    padding: 2.65% 10.469% 2%;
    text-align: center;
  }

  .view-content {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 2.3% 10.365%;
    background: $white;
  }

  .views-row {
    flex: 0 1 22.5%;
    margin-bottom: 25px;
    margin-right: 2.494%;

    &:last-child {
      margin-right: 0;
    }

    @include mq(1020px, max) {
      flex: 0 1 25%;
    }

    @include mq(840px, max) {
      flex: 0 1 40%;
    }

    @include mq(600px, max) {
      flex: 0 1 100%;
    }
  }

  .views-field-field-image {
    @include clearfix;
    margin-bottom: 1.8%;

    .colorbox {
      position: relative;
      float: left;
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;
    }

    .colorbox::before {
        @include absolute-center;
        z-index: 3;
        content: '';
        width: 100%;
        height: 100%;
        font-family: $fa;
        font-size: 30px;
        font-style: normal;
        font-weight: 400;
        background: url('../img/search.png') no-repeat 50% 50% rgba(1, 1, 7, 0.5);
        opacity: 0;
      }

    &:hover {
      .colorbox::before {
        opacity: 1;
        transition: 0.5s all;
      }
    }
  }

  .views-field-title {
    width: 100%;
    text-align: center;
    float: left;
    //padding-left: 7.5%;

    a {
      font-family: $open-sans;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      color: $very-gray;
      text-decoration: none;
    }
  }
}
