.section-contacts {
  #block-views-contacts-block {
    padding: 0;

    .view-content {
      .views-field-title {
        display: none;
      }
    }
  }

  #block-views {
    padding: 1.8% 0 0;
  }

  .block-title-wrapper {
    margin: 0;
    padding: 4% 0 1.75%;
    text-align: center;
    background: $very-white;
  }

  .view-header {
    flex: 0 1 64%;
  }

  .view-content {
    flex: 0 1 26.5%;

    @include mq(1200px, max) {
      margin-top: 4%;
    }

    p:first-child {
      @include mq(1200px, max) {
        text-align: center;
      }
    }

    .view-row {
      margin-top: 44%;
    }

    .custom-title {
      margin-bottom: 3%;
    }

    .address {
      display: block;
      margin-bottom: 6%;
    }

    .email {
      margin-bottom: 7%;
    }
  }

  .view-contacts {
    padding: 5% 12.5% 6.2%;
    background: $white;
  }
}


