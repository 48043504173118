.ui-datepicker {
  .ui-datepicker-month {
    font-weight: 500;
    font-family: $open-sans;
  }

  .ui-datepicker-year {
    font-weight: 500;
    font-family: $open-sans;
  }
}